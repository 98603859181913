import React, { FunctionComponent } from 'react';

type PositionType = 'top' | 'right' | 'bottom' | 'left';

interface TooltipProps {
  children: React.ReactNode;
  content: string | JSX.Element;
  position?: PositionType;
  className?: string;
  truncateChildren?: boolean;
}

export const Tooltip: FunctionComponent<TooltipProps> = ({
  children,
  content,
  position = 'top',
  className = '',
  truncateChildren = false
}) => {
  const positionClasses = {
    top: 'tooltip-top',
    right: 'tooltip-right',
    bottom: 'tooltip-bottom',
    left: 'tooltip-left'
  };

  return (
    <div className={`tooltip ${positionClasses[position]} ${className}`} data-tip={content}>
      <div className={truncateChildren ? 'overflow-hidden text-ellipsis whitespace-nowrap max-w-[20ch]' : ''}>
        {children}
      </div>
    </div>
  );
};
