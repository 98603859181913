import { IconName } from '@/components';
import { ChangeEventHandler, FunctionComponent, useCallback } from 'react';
import { DragDropContext, Draggable, DraggableProvided, DragUpdate, Droppable } from '@hello-pangea/dnd';
import styled from 'styled-components';
import { mediaList } from '../../../../data';
import { ButtonSocialMedia } from '../ButtonSocialMedia';
import { InputMedia } from '../InputMedia';
import { MediaI } from '@/containers/StoreFrontBuilder/context/storefront';
import { toast } from 'react-toastify';

interface SectionSocialMediaPropsI {
  selectMediaHandler: (media: MediaI) => void;
  mediaSelected: MediaI[] | undefined;
  changeValueMediaList: ChangeEventHandler<HTMLInputElement>;
  handlerMedias: (medias: MediaI[]) => void;
  handlerEditMedia: (index: number | null) => void;
  currentEditIndex: number | null;
}

const reorder = (list: MediaI[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const SectionSocialMedia: FunctionComponent<SectionSocialMediaPropsI> = ({
  selectMediaHandler,
  mediaSelected,
  changeValueMediaList,
  handlerMedias,
  handlerEditMedia,
  currentEditIndex
}) => {
  const isSelected = (id: number) => (mediaSelected ? !!mediaSelected.find((item) => item.id === id) : false);

  const onDragEnd = useCallback(
    (result: DragUpdate) => {
      if (!result.destination) {
        return;
      }

      const items = reorder(mediaSelected || [], result.source.index, result.destination.index);

      handlerMedias(items);
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [mediaSelected, handlerMedias]
  );

  return (
    <>
      <TextSection id="social-media">Redes sociales</TextSection>
      <GridSocialMedia>
        {mediaList.map((media) => (
          <ButtonSocialMedia
            key={media.id}
            icon={media.icon as IconName}
            colorIcon={media.color}
            originalColor={media.original_color}
            selected={isSelected(media.id)}
            onClick={() => {
              if (currentEditIndex !== null) {
                if (currentEditIndex !== media.id) {
                  toast.error('Debes guardar los cambios antes de seleccionar otra red social');
                } else {
                  handlerEditMedia(null);
                  selectMediaHandler(media);
                }
              } else selectMediaHandler(media);
            }}
          />
        ))}
      </GridSocialMedia>

      {mediaSelected && mediaSelected.length > 0 && (
        <TextSection>Ingresa tu usuario o pega el link</TextSection>
      )}
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {mediaSelected &&
                  mediaSelected.map((media, index) => (
                    <Draggable
                      key={index.toString()}
                      draggableId={index.toString()}
                      index={index}
                      isDragDisabled={false}
                    >
                      {(provided: DraggableProvided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            userSelect: 'none',
                            flex: 1,
                            ...provided.draggableProps.style
                          }}
                        >
                          <InputMedia
                            changeEdit={() => {
                              if (currentEditIndex === null) {
                                handlerEditMedia(media.id);
                              } else {
                                if (currentEditIndex !== media.id)
                                  toast.error('Debes guardar los cambios antes de editar otra red social');
                                else handlerEditMedia(media.id);
                              }
                            }}
                            isEdit={currentEditIndex === media.id}
                            key={media.id}
                            nameIcon={media.icon as IconName}
                            name={media.icon}
                            color={media.color}
                            originalColor={media.original_color}
                            value={media.value}
                            pattern="[A-Za-z0-9@\-_]+"
                            placeholder={media.placeholder}
                            changeValue={changeValueMediaList}
                            draggable
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};

const TextSection = styled.p<{ marginLeft?: string; marginRight?: string; marginBottom?: string }>`
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.grey[700]};
  margin-bottom: 20px;
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '20px')};
`;
const GridSocialMedia = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 10px;
  width: 100%;
  height: 100px;
  grid-column-gap: 1.8rem;
  grid-row-gap: 0.9rem;
  margin-bottom: 4.5rem;
  ${({ theme }) => theme.breakpoint('lg')} {
    grid-column-gap: 1rem;
  }
`;
