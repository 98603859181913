import React from 'react';

const CouponSkeleton = () => {
  const SkeletonSection = ({ title }: { title: string }) => (
    <div className="border-l-2 border-gray-200 pl-6 mt-10">
      <div className="animate-pulse">
        <div className="h-6 w-48 bg-gray-200 rounded mb-2">{title}</div>
        <div className="h-4 w-96 bg-gray-200 rounded mb-4"></div>
        <div className="h-12 w-full sm:w-[600px] bg-gray-200 rounded"></div>
      </div>
    </div>
  );

  return (
    <div className="my-2">
      <div className="container mx-auto px-4">
        {/* Header skeleton */}
        <div className="mb-4 pb-4 flex justify-between items-center">
          <div className="animate-pulse">
            <div className="h-8 w-64 bg-gray-200 rounded mb-2"></div>
            <div className="h-4 w-96 bg-gray-200 rounded"></div>
          </div>
          <div className="animate-pulse">
            <div className="h-10 w-24 bg-gray-200 rounded"></div>
          </div>
        </div>

        {/* Main content skeleton */}
        <div className="flex justify-center w-full">
          <div className="py-10 bg-gray-50 w-full md:w-4/5 px-6 shadow-md">
            {/* Name section */}
            <SkeletonSection title="Nombre de referencia" />

            {/* Code section */}
            <SkeletonSection title="Código" />

            {/* Discount section */}
            <div className="border-l-2 border-gray-200 pl-6 mt-10">
              <div className="animate-pulse">
                <div className="h-6 w-48 bg-gray-200 rounded mb-2"></div>
                <div className="h-4 w-96 bg-gray-200 rounded mb-4"></div>
                <div className="flex flex-col md:flex-row gap-3">
                  <div className="h-12 w-full sm:w-[300px] bg-gray-200 rounded"></div>
                  <div className="h-12 w-full sm:w-[300px] bg-gray-200 rounded"></div>
                </div>
              </div>
            </div>

            {/* Apply to section */}
            <SkeletonSection title="Aplicable a" />

            {/* Expiration section */}
            <div className="border-l-2 border-gray-200 pl-6 mt-10">
              <div className="animate-pulse flex items-center gap-8">
                <div className="h-6 w-48 bg-gray-200 rounded"></div>
                <div className="h-6 w-12 bg-gray-200 rounded"></div>
              </div>
            </div>

            {/* Quantity limit section */}
            <div className="border-l-2 border-gray-200 pl-6 mt-10">
              <div className="animate-pulse flex items-center gap-8">
                <div className="h-6 w-64 bg-gray-200 rounded"></div>
                <div className="h-6 w-12 bg-gray-200 rounded"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponSkeleton;
