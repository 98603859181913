import { useState } from 'react';
import { ForgotPassword, SignIn } from './components';
import { useIntercom } from '@/hooks/useIntercom';
import usePageTracking from '@/hooks/usePageTracking';
import { signinBanner } from '@/static/images';
import { Logo } from '@/components';
import { Button } from '@/components/Button/ButtonV2';
import { SignUp } from '../SignUp';

export default function Login({ isSignUp = false }: { isSignUp?: boolean }) {
  usePageTracking();
  const [isSignInOpen, setIsSignInOpen] = useState(!isSignUp);
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  useIntercom(false); // Add this line to disable Intercom

  const switchView = () => setIsSignInOpen(!isSignInOpen);
  const switchForgotPasswordView = () => setIsForgotPasswordOpen(!isForgotPasswordOpen);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <div className="grid w-full h-full sm:h-screen lg:grid-cols-2">
      <div className="flex flex-col gap-4 p-4 pt-safe pb-safe pl-safe pr-safe overflow-y-auto sm:bg-white">
        <div className="flex justify-center gap-2 md:justify-start pt-2 sm:pl-2">
          <Logo type="horizontal" width="100px" height="40px" />
        </div>
        <div className="flex items-center justify-center my-auto py-8">
          <div className="w-full max-w-xs">
            {isForgotPasswordOpen ? (
              <ForgotPassword
                navigateToSignIn={() => {
                  setIsForgotPasswordOpen(false);
                  setIsSignInOpen(true);
                }}
              />
            ) : isSignInOpen ? (
              <SignIn navigateToForgotPassword={switchForgotPasswordView} />
            ) : (
              <SignUp />
            )}
          </div>
        </div>
        <div className="text-center text-sm pb-6">
          {isSignInOpen ? '¿No tienes una cuenta?' : '¿Ya tienes cuenta?'}{' '}
          <Button
            variant="ghost"
            onClick={switchView}
            onKeyDown={handleKeyDown}
            className="!p-0 underline underline-offset-4"
          >
            {isSignInOpen ? 'Registrate' : 'Inicia sesion'}
          </Button>
        </div>
      </div>
      <div className="relative hidden bg-muted lg:block h-full py-2 bg-gray-50">
        <img src={signinBanner} alt="Image" className="absolute inset-0 h-full w-full object-contain py-2" />
        <div className="absolute inset-0" />
      </div>
    </div>
  );
}
