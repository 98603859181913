import React, { FunctionComponent, useEffect, useState } from 'react';
import { Icon, Row } from '@/components';
import { theme } from '@/components/App';
import { CollapsibleItem } from './components';
import { faCheck, faCopy, faInfoCircle, faLink, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStoreFront } from '@/containers/StoreFrontBuilder/context';
import { SectionHeadboard } from './SectionHeadboard';
import { SectionBio } from './SectionBio';
import { SectionLinks } from './SectionLinks';
import styled from 'styled-components';
import { InputPhone } from '@/components/Input';
import classNames from 'classnames';
import { useAuth } from '@/contexts';
import { toast } from 'react-toastify';
import { trackGenericEvent } from '@/analytics';

export interface FormTemplate {
  description: string;
  phone: string;
  video_youtube: string;
  banner_link: string;
}

type EditTemplateTabProps = {
  section?: number;
};

export const EditTemplateTab: FunctionComponent<EditTemplateTabProps> = ({ section }) => {
  const {
    removeLink,
    addLink,
    linksTemplate,
    handlerLinks,
    phone,
    removePhone,
    handlerPhone,
    editPhone,
    setEditPhone,
    isShowPhone,
    setIsShowPhone
  } = useStoreFront();
  const { session } = useAuth();
  const [optionSelect, setOptionSelect] = useState(0);
  const [shareUrl, setShareUrl] = useState(false);

  const scrollToSection = (section: number) => {
    const sectionRef = document.getElementById(`section-${section}`);
    if (sectionRef) {
      sectionRef.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (section) {
      setOptionSelect(section);
      scrollToSection(section);
    } else {
      setOptionSelect(0);
    }
  }, [section, setOptionSelect]);

  const handlerOpen = (select: number) => {
    if (select === optionSelect) {
      return setOptionSelect(0);
    }
    return setOptionSelect(select);
  };

  const handleShareUrl = (url: string) => {
    setShareUrl(true);
    navigator.clipboard.writeText(url);
    toast.success('Enlace copiado');
    setTimeout(() => {
      setShareUrl(false);
    }, 3000);
  };

  return (
    <>
      <div className="p-5">
        <Row
          align="center"
          justify="space-between"
          className="bg-[#CCE3FF] text-black p-4 mb-8 rounded-[5px]"
        >
          <Row align="center" gap={10}>
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="bg-black text-[#CCE3FF] border border-black rounded-full"
            />
            <p className="font-medium text-sm sm:text-base">
              Tu página ya está publicada:{' '}
              <a
                className="underline font-normal"
                target="_blank"
                rel="noreferrer"
                href={`https://www.agora.red/${session?.vendor?.username}`}
              >
                www.agora.red/{session?.vendor?.username}
              </a>
            </p>
          </Row>
          <button
            onClick={() => {
              trackGenericEvent('Button Share Storefront Clicked', {
                location: 'banner'
              });
              handleShareUrl(`www.agora.red/${session?.vendor?.username}`);
            }}
            className={classNames(
              `flex items-center justify-center transition-all sm:text-base text-sm gap-2 rounded-full py-2 px-4 font-medium`,
              { 'bg-[#09D5A1] text-white': shareUrl, 'bg-white': !shareUrl }
            )}
          >
            {shareUrl ? (
              <FontAwesomeIcon icon={faCheck} className="text-white" />
            ) : (
              <FontAwesomeIcon icon={faCopy} />
            )}
            {shareUrl ? 'Copiado' : 'Copiar'}
          </button>
        </Row>
        <CollapsibleItem
          id="section-1"
          icon={<Icon name="headboard" width="20px" height="20px" color={theme.colors.black as string} />}
          title="Cabecera"
          description="Información principal de tu página."
          handlerOpen={() => handlerOpen(1)}
          isOpen={optionSelect === 1}
        >
          <SectionHeadboard />
        </CollapsibleItem>

        <CollapsibleItem
          icon={<FontAwesomeIcon icon={faLink} color={theme.colors.black as string} />}
          id="section-3"
          title="Links"
          description="Agregá, modificá o eliminá los links de tu página."
          handlerOpen={() => handlerOpen(3)}
          isOpen={optionSelect === 3}
        >
          <SectionLinks
            linksTemplate={linksTemplate}
            removeLink={removeLink}
            addLink={addLink}
            handlerLinks={handlerLinks}
          />
        </CollapsibleItem>

        <CollapsibleItem
          icon={<Icon name="whatsapp" width="20px" height="20px" color={theme.colors.black as string} />}
          id="section-4"
          title="Contacto"
          description="Completa tu número de celular para te contacten por WhatsApp."
          handlerOpen={() => handlerOpen(4)}
          isOpen={optionSelect === 4}
        >
          <div className="mt-4">
            {isShowPhone ? (
              <Row gap={16} className="items-center">
                <div className="w-3/4 sm:w-1/2">
                  <InputPhone
                    inputPhoneProps={{
                      value: phone,
                      onChange: (value: string) => handlerPhone(value),
                      countryCodeEditable: false,
                      disabled: !editPhone,
                      disableDropdown: !editPhone
                    }}
                  />
                </div>
                <button
                  className={classNames('w-8 h-8 rounded-full bg-[#ECEEF0]')}
                  onClick={() => setEditPhone(!editPhone)}
                >
                  {!editPhone ? (
                    <FontAwesomeIcon icon={faPencil} color="#999" />
                  ) : (
                    <FontAwesomeIcon icon={faCheck} color="#999" />
                  )}
                </button>
                <button
                  className={classNames('w-8 h-8 rounded-full bg-[#ECEEF0]')}
                  onClick={() => removePhone()}
                >
                  <FontAwesomeIcon icon={faTrash} color="#999" />
                </button>
              </Row>
            ) : (
              <TextButton
                onClick={() => {
                  setIsShowPhone(true);
                  setEditPhone(true);
                }}
              >
                Agregar
              </TextButton>
            )}
          </div>
        </CollapsibleItem>

        <CollapsibleItem
          icon={<FontAwesomeIcon icon={faInfoCircle} color={theme.colors.black as string} />}
          id="section-5"
          title="Más info"
          description="Más info sobre vos y tu negocio. Crearemos un link de acceso en tu página principal."
          handlerOpen={() => handlerOpen(5)}
          isOpen={optionSelect === 5}
        >
          <SectionBio />
        </CollapsibleItem>
      </div>
    </>
  );
};
const TextButton = styled.p`
  font-size: ${({ theme }) => theme.font.size.base};
  font-weight: ${({ theme }) => theme.font.weight.normal};
  line-height: 37px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.blue[500]};
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 20px;
`;
