import React from 'react';
import { Column, ResponsiveContainer, Row } from '@/components';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import { Button } from '@/components/Button/ButtonV2';
import { useWindowResize } from '@/hooks';

interface LayoutProps {
  title: string;
  subtitle: string;
  children: React.ReactNode;
  onSave?: () => void;
  disabledButton?: boolean;
  isLoading?: boolean;
  maxWidth?: string;
}

export const Layout: React.FC<LayoutProps> = ({
  title,
  subtitle,
  onSave,
  children,
  disabledButton,
  isLoading
}) => {
  const navigate = useNavigate();
  const { isMobileSize } = useWindowResize();
  return (
    <section>
      <ResponsiveContainer>
        <Row align="center" justify="space-between" className="border-b py-2">
          <Column>
            <Row align="baseline" className="gap-2 sm:gap-4 text-lg sm:text-3xl">
              <p className="cursor-pointer" onClick={() => navigate('/servicios')}>
                Servicios
              </p>
              <FontAwesomeIcon icon={faChevronRight} className="text-sm sm:text-lg" />
              <p className="cursor-pointer" onClick={() => navigate('/servicios/edicion-global')}>
                Edición general
              </p>
              <FontAwesomeIcon icon={faChevronRight} className="text-sm sm:text-lg" />
              <p>{title}</p>
            </Row>
            <p className="text-sm sm:text-base text-[#828282]">{subtitle}</p>
          </Column>
          {!isMobileSize && (
            <Row align="center" gap={16}>
              <Button
                className="w-[180px] px-4 bg-white text-black"
                variant="outline"
                rounded="full"
                onClick={() => navigate('/servicios/edicion-global')}
              >
                Volver
              </Button>
              <Button
                className="w-[180px] px-4"
                rounded="full"
                onClick={onSave}
                disabled={disabledButton}
                isLoading={isLoading}
              >
                Guardar
              </Button>
            </Row>
          )}
        </Row>
      </ResponsiveContainer>
      <ResponsiveContainer>
        <Column
          align="center"
          justify="space-between"
          gap={16}
          className="py-4 h-full min-h-[calc(100vh-200px)]"
        >
          {children}
          <Button onClick={onSave} disabled={disabledButton} className="w-full max-w-[400px]">
            Guardar cambios
          </Button>
        </Column>
      </ResponsiveContainer>
    </section>
  );
};
