import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useRef } from 'react';

const PrimitiveTable = React.forwardRef<HTMLTableElement, React.HTMLAttributes<HTMLTableElement>>(
  ({ className, ...props }, ref) => (
    <div className="relative w-full overflow-auto">
      <table ref={ref} className={classNames('w-full caption-bottom text-sm', className)} {...props} />
    </div>
  )
);
PrimitiveTable.displayName = 'Table';

const TableHeader = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <thead ref={ref} className={classNames('[&_tr]:border-b', className)} {...props} />
  )
);
TableHeader.displayName = 'TableHeader';

const TableBody = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <tbody ref={ref} className={classNames('[&_tr:last-child]:border-0', className)} {...props} />
  )
);
TableBody.displayName = 'TableBody';

const TableFooter = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <tfoot
      ref={ref}
      className={classNames('border-t bg-muted/50 font-medium [&>tr]:last:border-b-0', className)}
      {...props}
    />
  )
);
TableFooter.displayName = 'TableFooter';

const TableRow = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(
  ({ className, ...props }, ref) => (
    <tr
      ref={ref}
      className={classNames(
        'border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted',
        className
      )}
      {...props}
    />
  )
);
TableRow.displayName = 'TableRow';

const TableHead = React.forwardRef<HTMLTableCellElement, React.ThHTMLAttributes<HTMLTableCellElement>>(
  ({ className, ...props }, ref) => (
    <th
      ref={ref}
      className={classNames(
        'h-10 px-2 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]',
        className
      )}
      {...props}
    />
  )
);
TableHead.displayName = 'TableHead';

const TableCell = React.forwardRef<HTMLTableCellElement, React.TdHTMLAttributes<HTMLTableCellElement>>(
  ({ className, ...props }, ref) => (
    <td
      ref={ref}
      className={classNames(
        'p-2 align-middle [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]',
        className
      )}
      {...props}
    />
  )
);
TableCell.displayName = 'TableCell';

const TableCaption = React.forwardRef<HTMLTableCaptionElement, React.HTMLAttributes<HTMLTableCaptionElement>>(
  ({ className, ...props }, ref) => (
    <caption ref={ref} className={classNames('mt-4 text-sm text-muted-foreground', className)} {...props} />
  )
);
TableCaption.displayName = 'TableCaption';

export { PrimitiveTable, TableHeader, TableBody, TableFooter, TableHead, TableRow, TableCell, TableCaption };

type TableProps = {
  body: Array<string | number | null | JSX.Element>[];
  headers: Array<string | JSX.Element>;
  classNameContainer?: string;
  maxHeight?: number;
  paginationEnabled?: boolean;
  onEnd?: () => void;
  isLoading?: boolean;
  caption?: string;
};

export const Table: FunctionComponent<TableProps> = ({
  body,
  headers,
  classNameContainer,
  maxHeight,
  paginationEnabled = false,
  onEnd,
  isLoading = false,
  caption
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (wrapperRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = wrapperRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        // We've reached the bottom
        if (onEnd) onEnd();
      }
    }
  };

  useEffect(() => {
    if (paginationEnabled && wrapperRef.current) {
      wrapperRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (wrapperRef.current) {
        wrapperRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [paginationEnabled]);

  // Función para renderizar el skeleton loader
  const renderSkeletonLoader = () => (
    <TableRow>
      {headers.map((_, index) => (
        <TableCell key={`skeleton-${index}`}>
          <div className="h-4 w-full animate-pulse rounded bg-gray-200 dark:bg-gray-700"></div>
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <div
      ref={wrapperRef}
      className={`relative overflow-auto ${classNameContainer || ''}`}
      style={{ maxHeight: maxHeight ? `${maxHeight}px` : undefined }}
    >
      <PrimitiveTable>
        {caption && <TableCaption>{caption}</TableCaption>}
        <TableHeader>
          <TableRow>
            {headers.map((header, index) => (
              <TableHead key={index}>{header}</TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {body.map((items, rowIndex) => (
            <TableRow key={rowIndex}>
              {items.map((item, cellIndex) => (
                <TableCell key={cellIndex}>{item}</TableCell>
              ))}
            </TableRow>
          ))}
          {isLoading && renderSkeletonLoader()}
        </TableBody>
      </PrimitiveTable>
    </div>
  );
};

export default Table;
