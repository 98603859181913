import React, { FunctionComponent, useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { Auth0Callback, NewUser, InfoTicket, Client, Clients, Coupon, PaymentGateways } from '@/containers';
import { ScrollToTop, Spinner } from '@/components';
import { useAuth } from '@/contexts';
import { PrivateRoute } from './PrivateRoute';
import { useAuth0 } from '@auth0/auth0-react';
import { SessionLoader } from './SessionLoader';
import { AgoraPlanNameE, Auth0RolesE } from '@/types/cyclone/models';
import { deleteCookie } from '@/utils/cookies';
import { useLocalStorage } from '@/hooks';
import { OnboardingProvider } from '@/containers/SelfOnboarding/contexts';
import { SelfOnboarding, Welcome as WelcomeOnBoarding, OnboardingLayout } from '@/containers/SelfOnboarding';
import { ActionReservation } from '@/containers/Agenda/containers/ActionReservation';
import { BankTransfer, Cash, MercadoPago } from '@/containers/PaymentGateways/containers';
import { Logout } from '@/containers/Logout';
import { initAmplitude } from '@/analytics';
import { ActionWorkingHours } from '@/containers/Agenda/containers/ActionWorkingHours';
import { TokenMonitor } from '@/containers/token_monitor';
import dayjs from 'dayjs';
import { START_POINT_DATE } from '../Layout/Layout';
import Login from '@/containers/Login/Login';
import { TestErrorComponent } from '../App/TestError';
import Posts from '@/containers/Posts/Posts';
import { AdjustPolicies } from '@/containers/Services/containers/AdjustmentsServices/containers';
import AdjustDownpayment from '@/containers/Services/containers/AdjustmentsServices/containers/AdjustDownpayment/AdjustDownpayment';
import FeedPreview from '@/containers/Posts/FeedPreview';
import StoryPreview from '@/containers/Posts/StoryPreview';
const Dashboard = lazy(() =>
  import('@/containers/Dashboard').then((module) => ({ default: module.Dashboard }))
);
const Activities = lazy(() =>
  import('@/containers/Activities').then((module) => ({ default: module.Activities }))
);
const Team = lazy(() => import('@/containers/Team').then((module) => ({ default: module.Team })));
const ActionTeam = lazy(() =>
  import('@/containers/ActionTeam').then((module) => ({ default: module.ActionTeam }))
);
const Agenda = lazy(() => import('@/containers/Agenda').then((module) => ({ default: module.Agenda })));
const StoreFrontBuilder = lazy(() =>
  import('@/containers/StoreFrontBuilder').then((module) => ({ default: module.StoreFrontBuilder }))
);
const Promoters = lazy(() =>
  import('@/containers/Promoters').then((module) => ({ default: module.Promoters }))
);
const EventDetails = lazy(() =>
  import('@/containers/EventDetails').then((module) => ({ default: module.EventDetails }))
);
const UniqueEvents = lazy(() =>
  import('@/containers/UniqueEvents').then((module) => ({ default: module.UniqueEvents }))
);
const ActionUniqueEvent = lazy(() =>
  import('@/containers/ActionUniqueEvent').then((module) => ({ default: module.ActionUniqueEvent }))
);
const PublicUniqueEventAnalytics = lazy(() =>
  import('@/containers/PublicUniqueEventAnalytics').then((module) => ({
    default: module.PublicUniqueEventAnalytics
  }))
);
const Services = lazy(() => import('@/containers/Services').then((module) => ({ default: module.Services })));
const OrderingServices = lazy(() =>
  import('@/containers/Services').then((module) => ({ default: module.OrderingServices }))
);
const AdjustmentsServices = lazy(() =>
  import('@/containers/Services').then((module) => ({ default: module.AdjustmentsServices }))
);
const AdjustPrice = lazy(() =>
  import('@/containers/Services/containers').then((module) => ({
    default: module.AdjustPrice
  }))
);

const ActionServiceV2 = lazy(() =>
  import('@/containers/ActionServiceV2').then((module) => ({ default: module.ActionServiceV2 }))
);
const Downloadables = lazy(() =>
  import('@/containers/Downloadables').then((module) => ({ default: module.Downloadables }))
);
const ActionDownloadable = lazy(() =>
  import('@/containers/ActionDownloadable').then((module) => ({ default: module.ActionDownloadable }))
);
const Subscriptions = lazy(() =>
  import('@/containers/Subscriptions').then((module) => ({ default: module.Subscriptions }))
);
const Donations = lazy(() =>
  import('@/containers/Donations').then((module) => ({ default: module.Donations }))
);
const ActionSubscription = lazy(() =>
  import('@/containers/ActionSubscription').then((module) => ({ default: module.ActionSubscription }))
);
const Invitations = lazy(() =>
  import('@/containers/Invitations').then((module) => ({ default: module.Invitations }))
);
const Analytics = lazy(() =>
  import('@/containers/Analytics').then((module) => ({ default: module.Analytics }))
);
const PlanRedirect = lazy(() =>
  import('@/containers/PlanRedirect').then((module) => ({ default: module.PlanRedirect }))
);
const Chat = lazy(() => import('@/containers/Chat').then((module) => ({ default: module.Chat })));
const Coupons = lazy(() => import('@/containers/Coupons').then((module) => ({ default: module.Coupons })));
const Plans = lazy(() => import('@/containers/Plans').then((module) => ({ default: module.Plans })));
const PersonalInfo = lazy(() =>
  import('@/containers/Settings/PersonalInfo').then((module) => ({ default: module.PersonalInfo }))
);
const Notification = lazy(() =>
  import('@/containers/Settings/Notification').then((module) => ({ default: module.Notification }))
);
const Support = lazy(() =>
  import('@/containers/Settings/Support').then((module) => ({ default: module.Support }))
);
const Calendar = lazy(() =>
  import('@/containers/Settings/Calendar').then((module) => ({ default: module.Calendar }))
);
const GodMode = lazy(() =>
  import('@/containers/Settings/GodMode').then((module) => ({ default: module.GodMode }))
);
const Billing = lazy(() => import('@/containers/Billing').then((module) => ({ default: module.Billing })));
const Welcome = lazy(() => import('@/containers/Welcome').then((module) => ({ default: module.Welcome })));
const MercadoPagoCallback = lazy(() =>
  import('@/containers/MercadoPagoCallback').then((module) => ({ default: module.MercadoPagoCallback }))
);
const GoogleCalendarCallback = lazy(() =>
  import('@/containers/GoogleCalendarCallback').then((module) => ({ default: module.GoogleCalendarCallback }))
);

const FLASH_URL = import.meta.env.VITE_FLASH_URL;

export const Router: FunctionComponent = () => {
  const redirectUri = useLocalStorage<string | null>('redirectUri', null);

  const { isLoading, logout, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { session } = useAuth();
  const [isAuth0SessionLoaded, setIsAuth0SessionLoaded] = useState(false);

  initAmplitude(session || null);

  const path = window.location.href.split('?')[0];

  useEffect(() => {
    const code = new URLSearchParams(location.search).get('code');
    const state = new URLSearchParams(location.search).get('state') || undefined;

    const mercadoPagoCbShouldRewrite = path === `${FLASH_URL}/mercado-pago/callback` && state !== undefined;

    // Auth0 and Mercado Pago Callback URLs uses a common query param called 'state', Auth0 has an observer that watch against the app domain URL, if it has a query param
    // called state, it will execute auth actions, this cause issues when receiving MP callback URL, we need to rewrite que query param from MP.
    if (mercadoPagoCbShouldRewrite) {
      window.location.href = `${path}?code=${code}&email=${state}`;
    } else {
      if (!isLoading) checkAuth0Session();
    }
  });

  const checkAuth0Session = async () => {
    try {
      await getAccessTokenSilently();
    } catch (error) {
      const normalizePath = path.split('#')[0];
      if (
        normalizePath !== `${FLASH_URL}/login` &&
        normalizePath !== `${FLASH_URL}/registro` &&
        normalizePath !== `${FLASH_URL}/posts/feed` &&
        normalizePath !== `${FLASH_URL}/posts/story` &&
        normalizePath !== `${FLASH_URL}/callback` &&
        normalizePath !== `${FLASH_URL}/new/user` &&
        !normalizePath.includes('plans/redirect') &&
        !(normalizePath.includes('eventos') && normalizePath.includes('registro')) &&
        !(normalizePath.includes('eventos') && normalizePath.includes('metricas'))
      ) {
        redirectUri.setValue(window.location.pathname);
        window.location.assign('/login');
      } else {
      }
    } finally {
      if (isAuthenticated) {
        if (session && (session.role === Auth0RolesE.CUSTOMER || session.role === Auth0RolesE.ADMIN)) {
          logout({ returnTo: FLASH_URL });
          deleteCookie('authInfo');
        }
      }
      setIsAuth0SessionLoaded(true);
    }
  };

  if (isLoading || !isAuth0SessionLoaded)
    return (
      <div className="w-full h-full">
        <Spinner fullHeight />
      </div>
    );

  const isPaymentConfigured = session?.vendor?.vendor_activity_tracking?.first_payment_method_at !== null;
  const isScheduleConfigured = session?.vendor?.vendor_activity_tracking?.first_availability_at !== null;
  const isServiceCreated = session?.vendor?.vendor_activity_tracking?.first_service_at !== null;
  const isEventPlan = session?.vendor?.plan_name === AgoraPlanNameE.EVENT;
  const isStaff = session?.is_staff;
  const isVenue = session?.is_venue;
  const isFreshStarterUser = dayjs(session?.vendor?.onboarding_finished_at).isAfter(START_POINT_DATE);
  //users creados en versiones anteriores donde no existia la propiedad is_onboarding_finished_at
  const isOldStarterUser =
    session?.vendor?.onboarding_finished_at === null && session.vendor.is_onboarding_finished;
  const isStaffCreated = session?.vendor?.vendor_activity_tracking?.first_staff_created_at !== null;

  const shouldRestrictRoutes =
    isEventPlan || isStaff || !isFreshStarterUser || isOldStarterUser
      ? false
      : !isPaymentConfigured || !isServiceCreated || !isScheduleConfigured || !isStaffCreated;

  const generateRoute = (path: string, Component: any) => {
    return (
      <Route
        path={path}
        element={
          <SessionLoader isOnboardingFinished={session?.vendor?.is_onboarding_finished}>
            <PrivateRoute>
              <Suspense
                fallback={
                  <div className="w-full h-full">
                    <Spinner fullHeight />
                  </div>
                }
              >
                <Component />
              </Suspense>
            </PrivateRoute>
          </SessionLoader>
        }
      />
    );
  };

  // force pushs
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/plans/redirect/:user_id" element={<PlanRedirect />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/registro" element={<Login isSignUp />} />
        <Route path="/callback" element={<Auth0Callback />} />
        <Route path="/new/user" element={<NewUser />} />
        <Route path="/test-error" element={<TestErrorComponent />} />
        <Route path="/eventos/:id/metricas" element={<PublicUniqueEventAnalytics />} />
        <Route path="/eventos/:id/registro" element={<InfoTicket />} />
        <Route path="/posts" element={<Posts />} />
        <Route path="/posts/feed" element={<FeedPreview />} />
        <Route path="/posts/story" element={<StoryPreview />} />

        {!session?.vendor?.is_onboarding_finished ? (
          <>
            <Route
              path="/onboarding"
              element={
                <SessionLoader isOnboardingFinished={session?.vendor?.is_onboarding_finished}>
                  <OnboardingProvider>
                    <Outlet />
                  </OnboardingProvider>
                </SessionLoader>
              }
            >
              <Route path={''} element={<WelcomeOnBoarding />} />
              <Route
                path={'wizard'}
                element={
                  <OnboardingLayout>
                    <SelfOnboarding />
                  </OnboardingLayout>
                }
              />
            </Route>

            <Route
              path="/mercado-pago/callback"
              element={
                <SessionLoader isOnboardingFinished={session?.vendor?.is_onboarding_finished}>
                  <PrivateRoute layout={false}>
                    <MercadoPagoCallback />
                  </PrivateRoute>
                </SessionLoader>
              }
            />
            <Route
              path="/google-calendar/callback"
              element={
                <SessionLoader isOnboardingFinished={session?.vendor?.is_onboarding_finished}>
                  <PrivateRoute layout={false}>
                    <GoogleCalendarCallback />
                  </PrivateRoute>
                </SessionLoader>
              }
            />
            <Route path="/" element={<Navigate replace to="/onboarding" />} />
          </>
        ) : (
          <>
            {generateRoute(
              '/',
              shouldRestrictRoutes
                ? !isPaymentConfigured
                  ? PaymentGateways
                  : isVenue && !isStaffCreated
                  ? ActionTeam
                  : !isScheduleConfigured
                  ? ActionWorkingHours
                  : !isServiceCreated
                  ? ActionServiceV2
                  : StoreFrontBuilder
                : StoreFrontBuilder
            )}
            {generateRoute('/mi-pagina', StoreFrontBuilder)}
            {generateRoute('/dashboard', Dashboard)}
            {generateRoute('/actividad', Activities)}
            {generateRoute('/equipo', Team)}
            {generateRoute('/equipo/agregar', ActionTeam)}
            {generateRoute('/equipo/editar/:id', ActionTeam)}
            {generateRoute('/agenda', Agenda)}
            {generateRoute('/agenda/horarios-de-trabajo', ActionWorkingHours)}
            {generateRoute('/agenda/ingresar-reserva', ActionReservation)}
            {generateRoute('/promotores', Promoters)}
            {generateRoute('/servicio/evento/:id', EventDetails)}
            {generateRoute('/analytics', Analytics)}
            {generateRoute('/eventos', UniqueEvents)}
            {generateRoute('/eventos/crear', ActionUniqueEvent)}
            {generateRoute('/eventos/:id/editar/:tab', ActionUniqueEvent)}
            {generateRoute('/eventos/:id/info/:tab', ActionUniqueEvent)}
            {generateRoute('/servicios', Services)}
            {generateRoute('/servicios/orden', OrderingServices)}
            {generateRoute('/servicios/edicion-global', AdjustmentsServices)}
            {generateRoute('/servicios/edicion-global/precios', AdjustPrice)}
            {generateRoute('/servicios/edicion-global/seña', AdjustDownpayment)}
            {generateRoute('/servicios/edicion-global/politicas', AdjustPolicies)}
            {generateRoute('/servicio/crear', ActionServiceV2)}
            {generateRoute('/servicio/:id/editar', ActionServiceV2)}
            {generateRoute('/descargables', Downloadables)}
            {generateRoute('/descargable/crear', ActionDownloadable)}
            {generateRoute('/descargable/:id/editar', ActionDownloadable)}
            {generateRoute('/planesmensuales', Subscriptions)}
            {generateRoute('/bancar', Donations)}
            {generateRoute('/planesmensuales/crear', ActionSubscription)}
            {generateRoute('/planesmensuales/:id/editar', ActionSubscription)}
            {generateRoute('/referidos', Invitations)}
            {generateRoute('/chat', Chat)}
            {generateRoute('/descuentos', Coupons)}
            {generateRoute('/descuentos/crear', Coupon)}
            {generateRoute('/descuentos/editar/:id', Coupon)}
            {generateRoute('/planes', Plans)}
            {generateRoute('/ajustes', PersonalInfo)}
            {generateRoute('/ajustes/medios-de-pago', PaymentGateways)}
            {generateRoute('/ajustes/medios-de-pago/mercadopago', MercadoPago)}
            {generateRoute('/ajustes/medios-de-pago/efectivo', Cash)}
            {generateRoute('/ajustes/medios-de-pago/transferencia', BankTransfer)}
            {generateRoute('/ajustes/info', PersonalInfo)}
            {generateRoute('/ajustes/seguridad', PersonalInfo)}
            {generateRoute('/ajustes/notificaciones', Notification)}
            {generateRoute('/ajustes/soporte', Support)}
            {generateRoute('/ajustes/calendario', Calendar)}
            {generateRoute('/ajustes/god-mode', GodMode)}
            {generateRoute('/facturacion', Billing)}
            {generateRoute('/bienvenido', Welcome)}
            {generateRoute('/mercado-pago/callback', MercadoPagoCallback)}
            {generateRoute('/google-calendar/callback', GoogleCalendarCallback)}
            {generateRoute('/clientes', Clients)}
            {generateRoute('/clientes/:id', Client)}
            {generateRoute('/token-monitor', TokenMonitor)}
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};
