import classNames from 'classnames';
import React from 'react';

interface GradientTextProps {
  children: React.ReactNode;
  className?: string;
  colors?: string[];
  animationSpeed?: number;
  showBorder?: boolean;
}

const GradientText: React.FC<GradientTextProps> = ({
  children,
  className = '',
  colors = ['#0072FB', '#FF4658', '#0072FB', '#0072FB'],
  animationSpeed = 8,
  showBorder = false
}) => {
  const gradientStyle = {
    backgroundImage: `linear-gradient(to right, ${colors.join(', ')})`,
    animationDuration: `${animationSpeed}s`,
    backgroundSize: '300% 100%'
  };

  return (
    <div
      className={classNames(
        'relative mx-auto flex max-w-fit items-center justify-center rounded-[1.25rem] font-medium backdrop-blur transition-shadow duration-500 overflow-hidden cursor-pointer',
        className
      )}
    >
      {showBorder && (
        <div
          className="absolute inset-0 bg-cover z-0 pointer-events-none animate-gradient"
          style={gradientStyle}
        >
          <div
            className="absolute inset-0 bg-white rounded-[1.25rem] z-[-1]"
            style={{
              width: 'calc(100% - 2px)',
              height: 'calc(100% - 2px)',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          />
        </div>
      )}
      <div
        className="relative z-10 inline-block text-transparent bg-cover animate-gradient"
        style={{
          ...gradientStyle,
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text'
        }}
      >
        {children}
      </div>
    </div>
  );
};

interface StarBorderProps {
  as?: React.ElementType;
  className?: string;
  color?: string;
  speed?: string;
  children: React.ReactNode;
}

const StarBorder: React.FC<StarBorderProps & React.ComponentPropsWithoutRef<any>> = ({
  as: Component = 'button',
  className = '',
  color = 'white',
  speed = '6s',
  children,
  ...rest
}) => {
  return (
    <Component className="relative inline-block py-[1px] overflow-hidden rounded-full" {...rest}>
      {/* Efecto de brillo en la parte inferior */}
      <div
        className="absolute w-[300%] h-[50%] opacity-70 bottom-[-11px] right-[-250%] rounded-full animate-star-movement-bottom z-0"
        style={{
          background: `radial-gradient(circle, ${color}, transparent 10%)`,
          animationDuration: speed
        }}
      />
      {/* Efecto de brillo en la parte superior */}
      <div
        className="absolute w-[300%] h-[50%] opacity-70 top-[-10px] left-[-250%] rounded-full animate-star-movement-top z-0"
        style={{
          background: `radial-gradient(circle, ${color}, transparent 10%)`,
          animationDuration: speed
        }}
      />
      {/* Contenido principal */}
      <div className={classNames('flex relative z-10 bg-white border text-center rounded-full', className)}>
        {children}
      </div>
    </Component>
  );
};

export { GradientText, StarBorder };
