import React, { useState } from 'react';
import * as yup from 'yup';
import { Controller, EmptyObject, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputV2 } from '@/components/Input';
import { Button } from '@/components/Button/ButtonV2';
import { authorize, changePassword } from '@/auth';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { ErrorI } from '@/types/cyclone/requests';
import { useClient } from '@/hooks';

const schema = yup.object().shape({
  email: yup.string().email('Mail inválido').required('Campo requerido')
});

type ForgotPasswordProps = {
  navigateToSignIn: () => void;
};

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({ navigateToSignIn }) => {
  const [authError, setAuthError] = useState<auth0.Auth0Error | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { client } = useClient();

  const {
    handleSubmit,
    formState: { errors, isValid },
    control
  } = useForm({ resolver: yupResolver(schema), mode: 'all' });
  const [loading, setLoading] = useState(false);

  const mutation = useMutation<EmptyObject, ErrorI, { data: { email: string } }>(
    (data) =>
      client(`users/change_password/validate`, 'POST', {
        data: { email: data.data.email }
      }),
    {
      onSuccess: (_d, { data }) => {
        changePassword(data.email, errorHandler, successHandler);
      },
      onError: (error) => {
        setLoading(false);
        if (error.code === 'AUTH0-004') {
          return setError('El email ingresado no se encuentra asociado a ninguna cuenta.');
        } else if (error.code === 'AUTH0-005') {
          return setError('Este email no se encuentra asociado a una cuenta en Ágora.');
        } else if (error.code === 'AUTH0-006') {
          authorize('google-oauth2');
        }
        return setError('Algo anda mal. Por favor, contactar a soporte.');
      }
    }
  );

  const errorHandler = (err: auth0.Auth0Error | null) => setAuthError(err);

  const successHandler = () => {
    toast.success('Se ha solicitado el cambio de contraseña. Por favor, revisá tu correo electrónico.');
    navigateToSignIn();
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    mutation.mutate({ data: { email: data.email } });
    setLoading(true);
  };

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col items-center gap-2 text-center">
        <h1 className="text-2xl font-bold">¿Olvidaste tu contraseña?</h1>
        <p className="text-pretty text-sm text-muted-foreground">
          Ingresa el email asociado a tu cuenta en Ágora para recibir un enlace de recuperación.
        </p>
      </div>

      <div className="grid gap-6">
        <Controller
          name="email"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, ...field } }) => (
            <InputV2
              id="email"
              type="email"
              value={value || ''}
              placeholder="Introduce tu email"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const formattedValue = e.target.value.trim().toLowerCase();
                onChange(formattedValue);
              }}
              error={errors?.email?.message as string}
              {...field}
            />
          )}
        />

        {error && <div className="text-sm text-red-500">{error}</div>}

        {authError !== null && (
          <div className="text-sm text-red-500">
            El email o la contraseña que ingresaste son incorrectos, por favor intentalo nuevamente.
          </div>
        )}

        <Button
          variant="default"
          className="w-full"
          type="submit"
          disabled={!isValid || loading}
          isLoading={loading || mutation.isLoading}
        >
          {loading || mutation.isLoading ? 'Cargando...' : 'Recuperar contraseña'}
        </Button>

        <Button variant="link" onClick={navigateToSignIn} className="text-[#0072FF] underline !font-normal">
          Volver a iniciar sesión
        </Button>
      </div>
    </form>
  );
};
