'use client';

import React from 'react';

import { useState } from 'react';
import { Pencil, Save, Search, ChevronLeft, ChevronRight } from 'lucide-react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/SelectV3';
import classNames from 'classnames';
import { DepositType, PolicyType, ServiceData } from '../../lib/type';
import { Button } from '@/components/Button/ButtonV2';
import { InputV2 } from '@/components/Input';
import {
  PrimitiveTable,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/Table/Table';
import { Column, Tooltip } from '@/components';
import { Dialog, DialogContentHalfBottom, DialogHeader } from '@/components/Dialog';
import { useWindowResize } from '@/hooks';

interface ServiceTableProps {
  services: ServiceData[];
  setServices: React.Dispatch<React.SetStateAction<ServiceData[]>>;
  policyType: PolicyType;
  depositInputType: DepositType;
  setDepositInputType: React.Dispatch<React.SetStateAction<DepositType>>;
  isStripeConnected?: boolean;
}

interface ValidationError {
  depositAmount?: string;
  depositPercentage?: string;
  depositAmountUsd?: string;
}

export function ServiceTable({
  services,
  setServices,
  policyType,
  depositInputType,
  setDepositInputType,
  isStripeConnected
}: ServiceTableProps) {
  const { isMobileSize } = useWindowResize();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [showMobileDialog, setShowMobileDialog] = useState(false);
  const [serviceSelected, setServiceSelected] = useState<ServiceData | undefined>();
  const [tempServiceData, setTempServiceData] = useState<{
    depositAmount: number;
    depositPercentage: number;
    depositAmountUsd: number;
  } | null>(null);
  const [validationErrors, setValidationErrors] = useState<ValidationError>({});
  const [mobileValidationErrors, setMobileValidationErrors] = useState<ValidationError>({});

  // Filter services based on search term
  const filteredServices = services.filter((service) =>
    service.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate pagination
  const totalPages = Math.ceil(filteredServices.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentServices = filteredServices.slice(indexOfFirstItem, indexOfLastItem);

  // Validation function for input values
  const validateInput = (
    service: ServiceData,
    field: 'depositAmount' | 'depositPercentage' | 'depositAmountUsd',
    value: number
  ): string | undefined => {
    if (field === 'depositPercentage') {
      if (value > 100) {
        return 'No puede ser mayor a 100%';
      } else if (value < 0) {
        return 'No puede ser negativo';
      }
    } else if (field === 'depositAmount') {
      if (value > service.totalValue) {
        return 'No puede ser mayor al valor total del servicio';
      } else if (value < 0) {
        return 'No puede ser negativo';
      }
    } else if (field === 'depositAmountUsd') {
      if (value < 0) {
        return 'No puede ser negativo';
      }
    }
    return undefined;
  };

  const handleOpenEdit = (id: string) => {
    const service = services.find((service) => service.id === id);

    if (!service) return;

    if (isMobileSize) {
      // For mobile, set up the dialog
      setServiceSelected(service);
      setMobileValidationErrors({});
      setTempServiceData({
        depositAmount: service.depositAmount,
        depositPercentage: service.depositPercentage,
        depositAmountUsd: service.depositAmountUsd
      });
      setShowMobileDialog(true);
    } else {
      // For desktop, just toggle edit mode
      setServices(
        services.map((serviceItem) =>
          serviceItem.id === id
            ? {
                ...serviceItem,
                isEditing: true,
                hasChanges: false
              }
            : serviceItem
        )
      );
      setValidationErrors({});
    }
  };

  const handleSaveEdit = (id: string) => {
    const service = services.find((service) => service.id === id);
    if (!service) return;

    // Check for validation errors before saving
    const errors: ValidationError = {};

    if (depositInputType === DepositType.PERCENTAGE) {
      const error = validateInput(service, 'depositPercentage', service.depositPercentage);
      if (error) errors.depositPercentage = error;
    } else {
      const error = validateInput(service, 'depositAmount', service.depositAmount);
      if (error) errors.depositAmount = error;

      if (isStripeConnected) {
        const usdError = validateInput(service, 'depositAmountUsd', service.depositAmountUsd);
        if (usdError) errors.depositAmountUsd = usdError;
      }
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setServices(
      services.map((serviceItem) =>
        serviceItem.id === id
          ? {
              ...serviceItem,
              isEditing: false,
              hasChanges: true
            }
          : serviceItem
      )
    );
    setValidationErrors({});
  };

  const toggleEditService = (id: string) => {
    const service = services.find((service) => service.id === id);
    if (!service) return;

    if (service.isEditing) {
      handleSaveEdit(id);
    } else {
      handleOpenEdit(id);
    }
  };

  const updateServiceValue = (
    id: string,
    field: 'depositAmount' | 'depositPercentage' | 'depositAmountUsd',
    value: number
  ) => {
    const service = services.find((s) => s.id === id);
    if (!service) return;

    // Validate input
    const error = validateInput(service, field, value);

    if (isMobileSize && tempServiceData) {
      // Update temp data for dialog and validation errors
      setTempServiceData({
        ...tempServiceData,
        [field]: value
      });

      if (error) {
        setMobileValidationErrors({
          ...mobileValidationErrors,
          [field]: error
        });
      } else {
        const newErrors = { ...mobileValidationErrors };
        delete newErrors[field];
        setMobileValidationErrors(newErrors);
      }
    } else {
      if (error) {
        setValidationErrors({
          ...validationErrors,
          [field]: error
        });
      } else {
        // Clear error if fixed
        const newErrors = { ...validationErrors };
        delete newErrors[field];
        setValidationErrors(newErrors);
      }

      setServices(
        services.map((serviceItem) => {
          if (serviceItem.id === id) {
            return { ...serviceItem, [field]: value, hasChanges: true };
          }
          return serviceItem;
        })
      );
    }
  };

  const handleDialogSave = () => {
    if (!serviceSelected || !tempServiceData) return;

    const errors: ValidationError = {};

    if (depositInputType === DepositType.PERCENTAGE) {
      const error = validateInput(serviceSelected, 'depositPercentage', tempServiceData.depositPercentage);
      if (error) errors.depositPercentage = error;
    } else {
      const error = validateInput(serviceSelected, 'depositAmount', tempServiceData.depositAmount);
      if (error) errors.depositAmount = error;

      if (isStripeConnected) {
        const usdError = validateInput(serviceSelected, 'depositAmountUsd', tempServiceData.depositAmountUsd);
        if (usdError) errors.depositAmountUsd = usdError;
      }
    }

    if (Object.keys(errors).length > 0) {
      setMobileValidationErrors(errors);
      return;
    }

    setServices(
      services.map((service) => {
        if (service.id === serviceSelected.id) {
          return {
            ...service,
            depositAmount: tempServiceData.depositAmount,
            depositPercentage: tempServiceData.depositPercentage,
            depositAmountUsd: tempServiceData.depositAmountUsd,
            isEditing: false,
            hasChanges: true
          };
        }
        return service;
      })
    );

    setShowMobileDialog(false);
    setServiceSelected(undefined);
    setTempServiceData(null);
    setMobileValidationErrors({});
  };

  const handleDialogClose = () => {
    setShowMobileDialog(false);
    setServiceSelected(undefined);
    setTempServiceData(null);
    setMobileValidationErrors({});
  };

  const handlePageChange = (page: number) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  return (
    <>
      <div className="mt-6 space-y-2">
        <div className="flex items-center justify-between">
          <label>Servicios ({filteredServices.length})</label>
          {policyType === PolicyType.DIFFERENT && (
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-1">
                <label htmlFor="deposit-input-type" className="text-xs">
                  Mostrar:
                </label>
                <Select value={depositInputType} onValueChange={(v) => setDepositInputType(v as DepositType)}>
                  <SelectTrigger id="deposit-input-type" className="h-8 text-xs min-w-[130px]">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={DepositType.PERCENTAGE}>Porcentaje (%)</SelectItem>
                    <SelectItem value={DepositType.FIXED}>Monto ($)</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          )}
        </div>

        {/* Search input */}
        <div className="relative mb-2">
          <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
          <InputV2
            type="text"
            id="search-input"
            placeholder="Buscar servicio..."
            value={searchTerm}
            onChange={(e: any) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1);
            }}
          />
        </div>
        <div className="border rounded-md overflow-hidden">
          <PrimitiveTable>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[50%]">Servicio</TableHead>
                <TableHead className="text-right">Valor</TableHead>
                <TableHead className="text-right">
                  Seña {depositInputType === DepositType.PERCENTAGE ? '(%)' : '($)'}
                </TableHead>
                {isStripeConnected && depositInputType === DepositType.FIXED && (
                  <TableHead className="text-right">Seña (US$)</TableHead>
                )}
                {policyType === PolicyType.DIFFERENT && <TableHead className="w-[50px]"></TableHead>}
              </TableRow>
            </TableHeader>
            <TableBody>
              {currentServices.length > 0 ? (
                currentServices.map((service) => (
                  <TableRow
                    key={service.id}
                    className={classNames(
                      service.isEditing && 'bg-primary/5 border-l-2 border-l-primary',
                      service.hasChanges && 'relative'
                    )}
                  >
                    <TableCell className="font-medium">
                      <div className="flex gap-2">
                        <Tooltip truncateChildren position="right" content={service.name}>
                          {service.name}
                        </Tooltip>
                        {service.hasChanges && !service.isEditing && (
                          <div className="bg-primary/10 text-primary text-[10px] px-2 rounded-full">
                            Modificado
                          </div>
                        )}
                      </div>
                    </TableCell>
                    <TableCell className="text-right">${service.totalValue.toLocaleString()}</TableCell>
                    <TableCell className="text-right">
                      {!isMobileSize && service.isEditing && policyType === PolicyType.DIFFERENT ? (
                        <div className="flex flex-col gap-1">
                          <InputV2
                            id={`deposit-input-${service.id}`}
                            type="number"
                            value={
                              depositInputType === DepositType.PERCENTAGE
                                ? service.depositPercentage
                                : service.depositAmount
                            }
                            error={
                              depositInputType === DepositType.PERCENTAGE
                                ? validationErrors.depositPercentage
                                  ? ' '
                                  : ''
                                : validationErrors.depositAmount
                                ? ' '
                                : ''
                            }
                            postfix={depositInputType === DepositType.PERCENTAGE ? '%' : '$'}
                            onChange={(e: any) =>
                              updateServiceValue(
                                service.id,
                                depositInputType === DepositType.PERCENTAGE
                                  ? 'depositPercentage'
                                  : 'depositAmount',
                                Number(e.target.value)
                              )
                            }
                          />
                        </div>
                      ) : depositInputType === DepositType.PERCENTAGE ? (
                        `${service.depositPercentage}%`
                      ) : (
                        `$${service.depositAmount.toLocaleString()}`
                      )}
                    </TableCell>
                    {isStripeConnected && depositInputType === DepositType.FIXED && (
                      <TableCell className="text-right">
                        {!isMobileSize && service.isEditing && policyType === PolicyType.DIFFERENT ? (
                          <div className="flex flex-col gap-1">
                            <InputV2
                              id={`deposit-input-usd-${service.id}`}
                              type="number"
                              value={service.depositAmountUsd}
                              error={validationErrors.depositAmountUsd ? ' ' : ''}
                              postfix="US$"
                              onChange={(e: any) =>
                                updateServiceValue(service.id, 'depositAmountUsd', Number(e.target.value))
                              }
                            />
                          </div>
                        ) : (
                          `US$${service.depositAmountUsd.toLocaleString()}`
                        )}
                      </TableCell>
                    )}
                    {policyType === PolicyType.DIFFERENT && (
                      <TableCell>
                        <button
                          onClick={() => toggleEditService(service.id)}
                          className={classNames(
                            'p-2 rounded-md transition-colors',
                            service.isEditing ? 'bg-primary/10 hover:bg-primary/20' : 'hover:bg-muted'
                          )}
                          aria-label={service.isEditing ? 'Guardar cambios' : 'Editar seña'}
                        >
                          {service.isEditing ? (
                            <Save className="h-4 w-4 text-primary" />
                          ) : (
                            <Pencil className="h-4 w-4 text-muted-foreground" />
                          )}
                        </button>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={
                      policyType === PolicyType.DIFFERENT
                        ? isStripeConnected
                          ? 5
                          : 4
                        : isStripeConnected
                        ? 4
                        : 3
                    }
                    className="text-center py-6 text-muted-foreground"
                  >
                    No se encontraron servicios
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </PrimitiveTable>
        </div>

        {/* Pagination controls */}
        {filteredServices.length > itemsPerPage && (
          <div className="flex items-center justify-between mt-2">
            <div className="text-xs text-muted-foreground">
              Mostrando {indexOfFirstItem + 1}-{Math.min(indexOfLastItem, filteredServices.length)} de{' '}
              {filteredServices.length}
            </div>
            <div className="flex items-center space-x-1">
              <Button
                variant="outline"
                size="icon"
                className="h-8 w-8"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <ChevronLeft className="h-4 w-4" />
                <span className="sr-only">Página anterior</span>
              </Button>
              {Array.from({ length: Math.min(totalPages, 3) }).map((_, i) => {
                let pageNumber: number;

                // Logic to show relevant page numbers
                if (totalPages <= 3) {
                  pageNumber = i + 1;
                } else if (currentPage <= 2) {
                  pageNumber = i + 1;
                } else if (currentPage >= totalPages - 1) {
                  pageNumber = totalPages - 2 + i;
                } else {
                  pageNumber = currentPage - 1 + i;
                }

                return (
                  <Button
                    key={i}
                    variant={currentPage === pageNumber ? 'default' : 'outline'}
                    size="icon"
                    className="h-8 w-8"
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </Button>
                );
              })}
              <Button
                variant="outline"
                size="icon"
                className="h-8 w-8"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <ChevronRight className="h-4 w-4" />
                <span className="sr-only">Página siguiente</span>
              </Button>
            </div>
          </div>
        )}
      </div>

      <Dialog open={showMobileDialog} onOpenChange={handleDialogClose}>
        <DialogContentHalfBottom>
          <DialogHeader className="border-b pb-6">
            <span className="truncate">Seña para {serviceSelected?.name}</span>
          </DialogHeader>
          <Column gap={22} className="pt-6">
            <InputV2
              id="mobile-deposit-input"
              type="number"
              value={
                tempServiceData && depositInputType === DepositType.PERCENTAGE
                  ? tempServiceData.depositPercentage
                  : tempServiceData?.depositAmount
              }
              error={
                depositInputType === DepositType.PERCENTAGE
                  ? mobileValidationErrors.depositPercentage
                  : mobileValidationErrors.depositAmount
              }
              postfix={depositInputType === DepositType.PERCENTAGE ? '%' : '$'}
              onChange={(e: any) =>
                updateServiceValue(
                  serviceSelected?.id as string,
                  depositInputType === DepositType.PERCENTAGE ? 'depositPercentage' : 'depositAmount',
                  Number(e.target.value)
                )
              }
            />
            {isStripeConnected && depositInputType === DepositType.FIXED && (
              <InputV2
                id="mobile-deposit-input-usd"
                type="number"
                value={tempServiceData?.depositAmountUsd}
                error={mobileValidationErrors.depositAmountUsd}
                postfix="US$"
                onChange={(e: any) =>
                  updateServiceValue(
                    serviceSelected?.id as string,
                    'depositAmountUsd',
                    Number(e.target.value)
                  )
                }
              />
            )}
            <Button
              className="w-full bg-primary hover:bg-primary/90 text-white"
              onClick={handleDialogSave}
              disabled={Object.keys(mobileValidationErrors).length > 0}
            >
              Guardar cambios
            </Button>
          </Column>
        </DialogContentHalfBottom>
      </Dialog>
    </>
  );
}
