import React, { FunctionComponent } from 'react';
import { Header, Footer } from '@/components';
import { useAuth } from '@/contexts';
import { identifyUser } from '@/analytics';
import { updateIntercomUser } from '@/services/intercom';
import { useWindowResize } from '@/hooks';
import RestrictedHeader from '../Header/RestrictedHeader';
import { AgoraPlanNameE } from '@/types/cyclone/models';
import dayjs from 'dayjs';

interface LayoutPropsI {
  children?: React.ReactNode;
}

export const START_POINT_DATE = new Date('2025-01-23');

export const Layout: FunctionComponent<LayoutPropsI> = ({ children }) => {
  const { session } = useAuth();
  const { isMobileSize } = useWindowResize();
  const path = window.location.pathname;
  const stepComplete = new URLSearchParams(window.location.search).get('complete') === 'true';

  const isEventPlan = session?.vendor?.plan_name === AgoraPlanNameE.EVENT;
  const isStaff = session?.is_staff;
  const isVenue = session?.is_venue;
  const isFreshStarterUser = dayjs(session?.vendor?.vendor_activity_tracking?.onboarding_finished_at).isAfter(
    START_POINT_DATE
  );
  //users creados en versiones anteriores donde no existia la propiedad is_onboarding_finished_at
  const isOldStarterUser =
    session?.vendor?.vendor_activity_tracking?.onboarding_finished_at === null &&
    session.vendor.is_onboarding_finished;

  const isFirstPMConnected = session?.vendor?.vendor_activity_tracking?.first_payment_method_at !== null;
  const isFirstServiceCreated = session?.vendor?.vendor_activity_tracking?.first_service_at !== null;
  const isFirstWHConfigured = session?.vendor?.vendor_activity_tracking?.first_availability_at !== null;
  const isFirstVenueCreated = isVenue
    ? session?.vendor?.venue?.vendors && session?.vendor?.venue?.vendors.length > 1
    : true;

  const shouldShowRestrictedHeader =
    isEventPlan || isStaff || !isFreshStarterUser || isOldStarterUser
      ? false
      : !isFirstPMConnected || !isFirstServiceCreated || !isFirstWHConfigured || !isFirstVenueCreated;

  const fullScreen =
    path.includes('/agenda/ingresar-reserva') ||
    (path.includes('/agenda/horarios-de-trabajo') && !stepComplete) ||
    path.includes('/bienvenido') ||
    (!shouldShowRestrictedHeader && isMobileSize && path.includes('/ajustes/medios-de-pago/mercadopago')) ||
    (!shouldShowRestrictedHeader && isMobileSize && path.includes('/ajustes/medios-de-pago/efectivo')) ||
    (!shouldShowRestrictedHeader && isMobileSize && path.includes('/ajustes/medios-de-pago/transferencia'));

  if (session) {
    identifyUser(session);
    updateIntercomUser(session, isMobileSize);
  }

  return (
    <>
      {fullScreen ? null : shouldShowRestrictedHeader ? <RestrictedHeader /> : <Header />}
      <main className="notranslate flex-1" id="main">
        <div className="h-full flex flex-col">{children}</div>
      </main>
      {fullScreen ? null : <Footer />}
    </>
  );
};
