import React, { FunctionComponent } from 'react';
import { AlertTriangle, Info, AlertCircle } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogContentHalfBottom,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '../Dialog';
import { Button } from '../Button/ButtonV2';
import { useWindowResize } from '@/hooks';

interface ConfirmationModalProps {
  status?: 'info' | 'warning' | 'danger';
  title?: string | JSX.Element;
  content?: string | JSX.Element;
  message?: string;
  confirmText?: string;
  cancelText?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  status = 'info',
  title,
  content,
  message,
  confirmText = 'Confirmar',
  cancelText = 'Cancelar',
  onClose,
  onConfirm,
  isOpen,
  setIsOpen
}) => {
  const { isMobileSize } = useWindowResize();
  const handleConfirm = () => {
    onConfirm?.();
    setIsOpen(false);
  };

  const handleClose = () => {
    onClose?.();
    setIsOpen(false);
  };

  const handleOpenChange = (value: boolean) => {
    if (!value) {
      handleClose();
    }
    setIsOpen(value);
  };

  const statusConfig = {
    info: {
      icon: <Info className="h-8 w-8 leading-none text-blue" />,
      borderColor: 'border-blue',
      textColor: 'text-blue',
      buttonVariant: 'default' as const,
      backgroundColor: 'bg-blue/10'
    },
    warning: {
      icon: <AlertCircle className="h-8 w-8 leading-none text-yellow-500" />,
      borderColor: 'border-yellow-500',
      textColor: 'text-yellow-500',
      buttonVariant: 'warning' as const,
      backgroundColor: 'bg-yellow-500/10'
    },
    danger: {
      icon: <AlertTriangle className="h-8 w-8 leading-none text-red-500" />,
      borderColor: 'border-red-500',
      textColor: 'text-red-500',
      buttonVariant: 'destructive' as const,
      backgroundColor: 'bg-red-500/10'
    }
  };

  const config = statusConfig[status];

  const dialogContentJSX = (
    <>
      <DialogHeader className="!flex flex-col gap-2">
        <DialogTitle className="w-full">{title}</DialogTitle>
        {content && <DialogDescription className="text-left">{content}</DialogDescription>}
      </DialogHeader>

      {message && (
        <div
          className={`my-6 p-4 border rounded-md ${config.borderColor} ${config.textColor} ${config.backgroundColor} flex gap-2 items-center`}
        >
          {config.icon}
          <div className="flex flex-col">
            <p className="text-sm">{message}</p>
          </div>
        </div>
      )}

      <DialogFooter className="flex !flex-row w-full !justify-end space-x-2 mt-2">
        <Button variant="muted" className="font-medium" onClick={handleClose}>
          {cancelText}
        </Button>
        <Button variant={config.buttonVariant} onClick={handleConfirm}>
          {confirmText}
        </Button>
      </DialogFooter>
    </>
  );

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      {isMobileSize ? (
        <DialogContentHalfBottom>{dialogContentJSX}</DialogContentHalfBottom>
      ) : (
        <DialogContent className="sm:max-w-md">{dialogContentJSX}</DialogContent>
      )}
    </Dialog>
  );
};
