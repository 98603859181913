'use client';

import { useState } from 'react';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';
import classNames from 'classnames';

interface VisualChoiceCardsProps {
  options: {
    value: string;
    label: string;
    description?: string;
  }[];
  defaultValue?: string;
  onChange?: (value: string) => void;
  className?: string;
}

export function ChoiceCards({ options, defaultValue, onChange, className }: VisualChoiceCardsProps) {
  const [selected, setSelected] = useState(defaultValue || options[0]?.value);

  const handleSelect = (value: string) => {
    setSelected(value);
    onChange?.(value);
  };

  return (
    <div
      className={classNames('grid gap-4', options.length === 2 ? 'grid-cols-2' : 'grid-cols-1', className)}
    >
      {options.map((option) => {
        const isActive = selected === option.value;
        return (
          <motion.div
            key={option.value}
            whileHover={{
              boxShadow: '0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)'
            }}
            whileTap={{ scale: 0.98 }}
            onClick={() => handleSelect(option.value)}
            className={classNames(
              'relative overflow-hidden rounded-xl cursor-pointer shadow-sm',
              'border-2 transition-all duration-300',
              isActive
                ? 'border-primary shadow-md shadow-primary/20'
                : 'border-muted hover:border-muted-foreground/20'
            )}
          >
            {/* Visual indicator for selected state */}
            {isActive && (
              <div className="absolute top-1 right-1 w-6 h-6 rounded-full bg-primary flex items-center justify-center shadow-sm">
                <Check className="h-3.5 w-3.5 text-white" />
              </div>
            )}

            {/* Card content */}
            <div className="p-5">
              {/* Text content */}
              <div className="space-y-1">
                <h3
                  className={classNames(
                    'font-medium transition-colors text-base',
                    isActive ? 'text-primary' : 'text-foreground'
                  )}
                >
                  {option.label}
                </h3>
                {option.description && <p className="text-sm text-muted-foreground">{option.description}</p>}
              </div>
            </div>

            {/* Bottom indicator */}
            <div
              className={classNames(
                'h-1.5 w-full transition-colors duration-300',
                isActive ? 'bg-primary' : 'bg-transparent'
              )}
            />
          </motion.div>
        );
      })}
    </div>
  );
}
