import React, { Component } from 'react';
import useLog from '@/hooks/useLog';

interface WithLoggingProps {
  logAndNotify: (message: string, error?: any) => void;
  children: React.ReactNode;
}

const withLogging = <P extends WithLoggingProps>(WrappedComponent: React.ComponentType<P>) => {
  return function WithLoggingComponent(props: Omit<P, keyof WithLoggingProps>) {
    const { logAndNotify } = useLog();

    return <WrappedComponent {...(props as P)} logAndNotify={logAndNotify} />;
  };
};

interface ErrorBoundaryProps {
  children: React.ReactNode;
  logAndNotify: (message: string, error?: any) => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
  secondsLeft: number;
}

class ErrorBoundaryComponent extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  private redirectTimer: NodeJS.Timeout | null = null;
  private countdownTimer: NodeJS.Timeout | null = null;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      secondsLeft: 3
    };
  }

  formatError(error: unknown, errorInfo: React.ErrorInfo): string {
    try {
      let errorMessage: string;

      if (error instanceof Error) {
        errorMessage = error.message;
      } else if (typeof error === 'string') {
        errorMessage = error;
      } else if (error && typeof error === 'object') {
        errorMessage = String(error);
      } else {
        errorMessage = 'An unknown error occurred';
      }

      const componentStack = errorInfo?.componentStack
        ? `\nComponent Stack: ${errorInfo.componentStack}`
        : '';

      return `${errorMessage}${componentStack}`;
    } catch (e) {
      return 'Error while formatting error message';
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      hasError: true,
      error,
      errorInfo
    });

    // Start countdown
    this.countdownTimer = setInterval(() => {
      this.setState((prev) => ({
        secondsLeft: prev.secondsLeft - 1
      }));
    }, 1000);

    // Set redirect timer
    this.redirectTimer = setTimeout(() => {
      window.location.href = '/';
    }, 3000);

    try {
      const formattedError = this.formatError(error, errorInfo);
      this.props.logAndNotify(formattedError);
    } catch (loggingError) {
      console.error('Error while trying to log:', loggingError);
    }
  }

  componentWillUnmount() {
    if (this.redirectTimer) clearTimeout(this.redirectTimer);
    if (this.countdownTimer) clearInterval(this.countdownTimer);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-md text-center">
            <div>
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">¡Ups! Algo salió mal</h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                Hemos sido notificados y estamos trabajando en una solución.
              </p>
            </div>
            <div className="mt-8 space-y-6">
              <div className="rounded-md bg-yellow-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg
                      className="h-5 w-5 text-yellow-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">
                      Redirigiendo a la página de inicio
                    </h3>
                    <div className="mt-2 text-sm text-yellow-700">
                      <p>Serás redirigido en {this.state.secondsLeft} segundos...</p>
                    </div>
                  </div>
                </div>
              </div>
              {import.meta.env.VITE_NODE_ENV === 'development' && (
                <div className="mt-4 text-left">
                  <details className="bg-gray-50 p-4 rounded-lg">
                    <summary className="text-gray-700 cursor-pointer font-medium">Error Details</summary>
                    <pre className="mt-2 text-sm text-gray-600 overflow-auto whitespace-pre-wrap">
                      {this.state.error && this.state.error.toString()}
                      {this.state.errorInfo && (
                        <>
                          <br />
                          <br />
                          Component Stack:
                          <br />
                          {this.state.errorInfo.componentStack}
                        </>
                      )}
                    </pre>
                  </details>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export const ErrorBoundary = withLogging(ErrorBoundaryComponent);
