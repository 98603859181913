import { FunctionComponent, useCallback, useState } from 'react';
import { NavbarItem } from '..';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/DropdownMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from '@/components/Row';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import useScreenSize from '@/hooks/useScreenSize';

type MainMenuProps = {
  items: NavbarItem[];
};

export const MainMenu: FunctionComponent<MainMenuProps> = ({ items }) => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);
  const { isDesktop } = useScreenSize();

  const handleItemClick = (callback: () => void, onClick?: () => void) => {
    callback();
    onClick?.();
  };

  const closeDropdownMenu = useCallback(() => {
    setOpenDropdownIndex(null);
  }, []);

  const toggleDropdown = (index: number) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const renderMenuItem = (item: NavbarItem, index: number) => {
    const {
      label,
      onClick,
      icon,
      isSelected = false,
      disabled = false,
      isDropdown,
      dropdownContent,
      link
    } = item;

    const itemClasses = classNames(
      'flex items-center font-book text-sm relative transition-colors duration-200',
      {
        'text-blue': isSelected,
        'text-gray-700': !isSelected,
        'cursor-default': disabled,
        'hover:text-blue': !disabled
      }
    );

    if (!isDropdown) {
      return (
        <Link to={link}>
          <button className={itemClasses} onClick={onClick} disabled={disabled}>
            <div className="flex items-baseline">
              {icon && isDesktop && <FontAwesomeIcon icon={icon} fixedWidth className="mr-2" size="sm" />}
              <span>{label}</span>
            </div>
          </button>
        </Link>
      );
    } else {
      return (
        <DropdownMenu open={openDropdownIndex === index} onOpenChange={() => toggleDropdown(index)}>
          <DropdownMenuTrigger onClick={() => toggleDropdown(index)}>
            <button className={itemClasses} onClick={onClick} disabled={disabled}>
              <div className="flex items-baseline">
                {icon && isDesktop && <FontAwesomeIcon icon={icon} fixedWidth className="mr-2" size="sm" />}
                <span>{label}</span>
                <FontAwesomeIcon icon={faChevronDown} fixedWidth className="ml-1" size="xs" />
              </div>
            </button>
          </DropdownMenuTrigger>

          <DropdownMenuContent side="bottom" sideOffset={10}>
            {dropdownContent?.map(
              ({ label, onClick, link, icon: dropdownIcon, isSelected, length: assetLength }) => {
                return (
                  <DropdownMenuItem
                    key={label}
                    className={classNames('cursor-pointer group hover:bg-blue hover:!text-white', {
                      'text-blue': isSelected,
                      '!text-[#212121]': !isSelected
                    })}
                    asChild
                  >
                    <Link to={link} key={label} onClick={() => handleItemClick(closeDropdownMenu, onClick)}>
                      <Row align="baseline" justify="space-between" gap={8} className="cursor-pointer w-full">
                        {dropdownIcon && <FontAwesomeIcon icon={dropdownIcon} fixedWidth />}
                        {label}
                        {typeof assetLength === 'number' && assetLength > 0 && (
                          <span className="text-xs badge pb-0.5 text-[#626262] group-hover:text-blue group-hover:bg-[#CCE3FF] group-hover:border-[#CCE3FF]">
                            {assetLength}
                          </span>
                        )}
                      </Row>
                    </Link>
                  </DropdownMenuItem>
                );
              }
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      );
    }
  };

  return (
    <div className="flex items-center gap-6">
      {items.map((item, index) => (
        <div key={index}>{renderMenuItem(item, index)}</div>
      ))}
    </div>
  );
};
