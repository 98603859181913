import { clearCookies } from '@/utils';
import { deleteCookie, readCookie } from '@/utils/cookies';
import auth0 from 'auth0-js';

const CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID || 'INSERT_AUTH0_CLIENT_ID';
const DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN || 'INSERT_AUTH0_DOMAIN';
const AUTH0_REDIRECT_URL = import.meta.env.VITE_AUTH0_REDIRECT_URL || 'http://localhost:3001/callback';
const AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE || 'INSERT_AUTH0_AUDIENCE';

const webAuth = new auth0.WebAuth({
  domain: DOMAIN,
  clientID: CLIENT_ID,
  audience: AUDIENCE,
  responseType: 'token',
  scope: 'openid profile email',
  overrides: {
    __tenant: 'devagora',
    __token_issuer: `https://${DOMAIN}/`
  }
});

// Existing login function
export const logIn = (
  email: string,
  password: string,
  errorHandler: (err: auth0.Auth0Error | null) => void
): void => {
  localStorage.clear();
  clearCookies();

  return webAuth.login(
    {
      realm: 'Username-Password-Authentication',
      username: email,
      password,
      redirectUri: AUTH0_REDIRECT_URL
    },
    errorHandler
  );
};

// New function to start passwordless login
export const startPasswordless = (
  email: string,
  errorHandler: (err: auth0.Auth0Error | null) => void,
  successHandler: () => void
): void => {
  console.log('Starting passwordless flow for:', email); // Debug log

  webAuth.passwordlessStart(
    {
      connection: 'email',
      send: 'code',
      email: email,
      authParams: {
        responseType: 'token',
        scope: 'openid profile email'
      }
    },
    (err, res) => {
      console.log('Passwordless start response:', err || res); // Debug log
      if (err) {
        console.error('Passwordless error:', err); // Debug error
        errorHandler(err);
      } else {
        console.log('Code sent successfully'); // Debug success
        successHandler();
      }
    }
  );
};

export const verifyPasswordlessCode = (
  email: string,
  code: string,
  errorHandler: (err: auth0.Auth0Error | null) => void
): void => {
  console.log('Verifying code for:', email); // Debug log

  webAuth.passwordlessLogin(
    {
      connection: 'email',
      email: email,
      verificationCode: code,
      redirectUri: AUTH0_REDIRECT_URL, // Add this line
      authParams: {
        responseType: 'token id_token', // Match WebAuth configuration
        scope: 'openid profile email'
      }
    },
    (err) => {
      console.log('Verification response:', err || 'Success'); // Debug log
      if (err) {
        console.error('Verification error:', err); // Debug error
      }
      errorHandler(err);
    }
  );
};

export const resendPasswordlessCode = (
  email: string,
  errorHandler: (err: auth0.Auth0Error | null) => void,
  successHandler: () => void
): void => {
  startPasswordless(email, errorHandler, successHandler);
};

export const register = (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  errorHandler: (err: auth0.Auth0Error | null) => void
): void =>
  webAuth.signup(
    {
      username: email,
      password,
      email,
      userMetadata: {
        first_name: firstName,
        last_name: lastName
      },
      connection: 'Username-Password-Authentication'
    },
    (err) => {
      if (!err) {
        logIn(email, password, errorHandler);
      } else {
        errorHandler(err);
      }
    }
  );

export const authorize = (connection: 'facebook' | 'google-oauth2'): void => {
  deleteCookie('authInfo');
  return webAuth.authorize({
    connection,
    redirectUri: AUTH0_REDIRECT_URL
  });
};

export const parseHash = (
  errorHandler: (err: auth0.Auth0Error | null) => void,
  successHandler: (user: auth0.Auth0UserProfile) => void
): void => {
  // const auth = localStorage.getItem('auth');
  // const authParsed = JSON.parse(auth!);
  let parseHashProps;

  const cookieAuth = readCookie('authInfo');

  if (cookieAuth.length === 0) {
    parseHashProps = {
      hash: window.location.hash
    };
  } else {
    const auth = JSON.parse(readCookie('authInfo'));

    parseHashProps = {
      hash: window.location.hash,
      state: auth.state, // localstorage-iframe receives and stores these values
      nonce: auth.nonce
    };
  }

  webAuth.parseHash(
    {
      ...parseHashProps
    },
    (err, authResult) => {
      if (err) errorHandler(err);
      else {
        webAuth.client.userInfo(authResult?.accessToken || '', function (err, user) {
          if (err) {
            deleteCookie('authInfo');
            errorHandler(err);
          }
          successHandler(user);
        });
      }
    }
  );
};

export const changePassword = (
  email: string,
  errorHandler: (err: auth0.Auth0Error | null) => void,
  successHandler: () => void
): void =>
  webAuth.changePassword({ email, connection: 'Username-Password-Authentication' }, (err) => {
    if (err) errorHandler(err);
    else successHandler();
  });
