import { SupportedCountriesE } from '@/types/cyclone/models';

export {
  formatDay,
  getDayWithTime,
  getTime,
  getDatesInterval,
  getTimesInterval,
  getDayWeekName,
  generateIntervals,
  getHoursIntervals,
  validateSchedules,
  parseHour,
  addMinutesToHour,
  getWeekDaysByDate,
  roundToNearestFiveMinutes,
  getFormatDuration
} from './schedule';

export { flatten } from './array';

export const getBase64 = (file: File): Promise<unknown> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getNewId = (): number => Date.now() + Math.random();

export const blobToBase64 = async (blob: Blob): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      resolve(reader.result);
    };
  });
};

export const removeHTMLFromText = (text: string): string => text.replace(/<\/?[^>]+(>|$)/g, '');

export const urlImageToFile = async (url: string) => {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = {
    type: 'image/jpeg'
  };
  const file = new File([data], 'image.jpg', metadata);
  return file;
};

export const omit = (obj: any, ...keys: any) => {
  const keysToRemove = new Set(keys.flat()); // flatten the props, and convert to a Set

  return Object.fromEntries(
    // convert the entries back to object
    Object.entries(obj) // convert the object to entries
      .filter(([k]) => !keysToRemove.has(k)) // remove entries with keys that exist in the Set
  );
};

export const getBase64FromUrl = async (url: string) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const clearCookies = () => {
  const cookies = document.cookie.split(';');
  for (const cookie of cookies) {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
};

export const truncate = (text: string, length: number): string => {
  return text.length > length ? text.substring(0, length) + '...' : text;
};

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isValidEmailDomain = (value: string | undefined): boolean => {
  console.log(value);
  return true;
};

export const getCountryCode = (country: SupportedCountriesE): string => {
  switch (country) {
    case SupportedCountriesE.ARGENTINA:
      return 'ar';
    case SupportedCountriesE.URUGUAY:
      return 'uy';
    case SupportedCountriesE.CHILE:
      return 'cl';
    case SupportedCountriesE.MEXICO:
      return 'mx';
    case SupportedCountriesE.VENEZUELA:
      return 've';
    default:
      return 'ar';
  }
};
