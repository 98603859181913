import { NavbarItem } from '@/components/Navbar';
import { useAuth } from '@/contexts';
import { LINKS } from '@/constants';
import { AgoraPlanNameE } from '@/types/cyclone/models';
import { trackGenericEvent } from '@/analytics';
import {
  faBullhorn,
  faGear,
  faMobileAlt,
  faPeopleGroup,
  faSignOutAlt,
  faStoreAlt,
  faTags,
  faWallet
} from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faChartBar, faCreditCard } from '@fortawesome/free-regular-svg-icons';

const createMenuItem = (
  label: string,
  path: string,
  currentPath: string | undefined,
  icon?: any,
  link?: string,
  dropdownContent?: NavbarItem[]
): NavbarItem => ({
  label,
  onClick: () => trackGenericEvent('Menu Item Clicked', { name: label }),
  isSelected: currentPath === path,
  link: link ?? path,
  ...(icon && { icon }),
  ...(dropdownContent && { isDropdown: true, dropdownContent })
});

const createDropdownItem = (
  label: string,
  path: string,
  currentPath: string | undefined,
  length?: number,
  icon?: any,
  link?: string
): NavbarItem => ({
  label,
  onClick: () => trackGenericEvent('Menu Item Clicked', { name: label }),
  isSelected: currentPath === path,
  link: link ?? path,
  ...(icon && { icon }),
  length
});

export const useNavbarItems = (
  path?: string
): { mainItems: NavbarItem[]; dropdownItems: NavbarItem[]; footerItems: NavbarItem[] } => {
  const { logOut, session } = useAuth();
  const isEventPlan = session?.vendor?.plan_name === AgoraPlanNameE.EVENT;
  const isProfessionalPlan = session?.vendor?.plan_name === AgoraPlanNameE.PROFESSIONAL;

  const length = {
    services: session?.count_active_services,
    events: session?.count_active_events,
    subscriptions: session?.count_active_subscriptions,
    digitalContent: session?.count_active_digital_content
  } as const;

  const getCatalogDropdownContent = (includeSubscriptions = false) => {
    const content = [
      createDropdownItem('Servicios', '/servicios', path, length.services),
      createDropdownItem(isEventPlan ? 'Eventos' : 'Capacitaciones', '/eventos', path, length.events),
      createDropdownItem('Descargables', '/descargables', path, length.digitalContent),
      createDropdownItem('Bancar', '/bancar', path)
    ];

    if (includeSubscriptions) {
      content.splice(
        2,
        0,
        createDropdownItem('Planes mensuales', '/planesmensuales', path, length.subscriptions)
      );
    }

    return content;
  };

  const getBusinessDropdownContent = () => [
    createDropdownItem('Actividad', '/actividad', path),
    createDropdownItem('Clientes', '/clientes', path),
    createDropdownItem('Analítica', '/analytics', path)
  ];

  const mainItems: NavbarItem[] = [];

  if (!session?.is_staff) {
    // Add initial items based on user role
    mainItems.push(createMenuItem('Mi página', '/mi-pagina', path, faMobileAlt));

    // Professional plan specific items
    if (isProfessionalPlan) {
      mainItems.push(
        createMenuItem('Catálogo', '', path, faStoreAlt, '', getCatalogDropdownContent(!session?.is_venue))
      );

      if (session?.is_venue) {
        mainItems.push(createMenuItem('Equipo', '/equipo', path, faPeopleGroup));
      }

      mainItems.push(
        createMenuItem('Agenda', '/agenda', path, faCalendar),
        createMenuItem('Mi negocio', '', path, faChartBar, '', getBusinessDropdownContent()),
        createMenuItem('Descuentos', '/descuentos', path, faTags)
      );
    }

    // Event plan specific items
    if (isEventPlan && !session?.is_staff) {
      mainItems.push(
        createMenuItem('Catálogo', '', path, faStoreAlt, '', [
          createDropdownItem(isEventPlan ? 'Eventos' : 'Capacitaciones', '/eventos', path, length.events),
          createDropdownItem('Descargables', '/descargables', path, length.digitalContent)
        ]),
        createMenuItem('Promotores', '/promotores', path, faBullhorn),
        createMenuItem('Actividad', '/actividad', path, faChartBar),
        createMenuItem('Clientes', '/clientes', path, faPeopleGroup),
        createMenuItem('Descuentos', '/descuentos', path, faTags)
      );
    }
  } else {
    // Staff specific items
    mainItems.push(
      createMenuItem('Servicios', '/servicios', path, faStoreAlt),
      createMenuItem('Agenda', '/agenda', path),
      createMenuItem('Actividad', '/actividad', path)
    );
  }

  // Dropdown items based on user role and plan
  const baseDropdownItems = [
    createMenuItem('Referidos', '/referidos', path, faPeopleGroup),
    createMenuItem('Ajustes', '/ajustes', path, faGear),
    createMenuItem('Medios de pago', '/ajustes/medios-de-pago', path, faWallet)
  ];

  const setupOneDropdownItems = [
    createMenuItem('Medios de pago', '/ajustes/medios-de-pago', path, faWallet),
    createMenuItem('Salir', '', path, faSignOutAlt, '', undefined)
  ];

  const isInitialSetup =
    !session?.is_staff &&
    !session?.vendor?.vendor_activity_tracking?.first_payment_method_at &&
    !session?.vendor?.vendor_activity_tracking?.first_availability_at &&
    !session?.vendor?.vendor_activity_tracking?.first_service_at;

  const dropdownItems: NavbarItem[] = isInitialSetup
    ? setupOneDropdownItems
    : session?.is_staff
    ? [createMenuItem('Salir', '', path, faSignOutAlt, '', undefined)]
    : isProfessionalPlan
    ? [
        ...baseDropdownItems,
        createMenuItem('Mi plan', '/facturacion', path, faCreditCard),
        createMenuItem('Salir', '', path, faSignOutAlt, '', undefined)
      ]
    : [
        createMenuItem('Medios de pago', '/ajustes/medios-de-pago', path, faWallet),
        createMenuItem('Invitaciones', '/referidos', path, faPeopleGroup),
        createMenuItem('Ajustes', '/ajustes', path, faGear),
        createMenuItem('Salir', '', path, faSignOutAlt, '', undefined)
      ];

  // Update logout onClick handler
  const logoutItem = dropdownItems.find((item) => item.label === 'Salir');
  if (logoutItem) {
    logoutItem.onClick = logOut;
  }

  const footerItems: NavbarItem[] = [
    {
      label: 'Soporte Técnico',
      link: `https://api.whatsapp.com/send/?phone=541136535645`,
      onClick: () => trackGenericEvent('Footer Link Clicked', { name: 'Support' })
    },
    {
      label: 'Preguntas frecuentes',
      link: 'https://ayuda.agora.red/es/collections/4030470-preguntas-frecuentes',
      onClick: () => trackGenericEvent('Footer Link Clicked', { name: 'FAQ' })
    },
    {
      label: 'Términos y condiciones',
      link: LINKS.TERMS,
      onClick: () => trackGenericEvent('Footer Link Clicked', { name: 'Terms & Conditions' })
    },
    {
      label: 'Política de privacidad',
      link: LINKS.PRIVACY,
      onClick: () => trackGenericEvent('Footer Link Clicked', { name: 'Privacy Policy' })
    }
  ];

  return { mainItems, dropdownItems, footerItems };
};
