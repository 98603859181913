'use client';

import classNames from 'classnames';
import { Banknote, Wallet } from 'lucide-react';
import { DownpaymentOption } from '@/containers/ActionServiceV2/context/service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMercadoPago } from '@/static/icons/faMercadoPago';

interface PaymentMethodSelectorProps {
  availableMethods?: string[];
  selectedMethods: string[];
  onSelectMethod: (value: string) => void;
}

const paymentMethodsConfig = [
  {
    value: DownpaymentOption.MERCADO_PAGO,
    label: 'Mercado Pago',
    icon: <FontAwesomeIcon icon={faMercadoPago} className="mr-2 h-6 w-6" />
  },
  {
    value: DownpaymentOption.BANK_TRANSFER,
    label: 'Transferencia',
    icon: <Wallet className="mr-2 h-6 w-6" />
  },
  {
    value: DownpaymentOption.CASH,
    label: 'Efectivo',
    icon: <Banknote className="mr-2 h-6 w-6" />
  }
];

export function PaymentMethodSelector({
  availableMethods,
  selectedMethods,
  onSelectMethod
}: PaymentMethodSelectorProps) {
  // Filter payment methods to only show available ones if provided
  const paymentMethods = availableMethods
    ? paymentMethodsConfig.filter((method) => availableMethods.includes(method.value))
    : paymentMethodsConfig;

  return (
    <div className="grid grid-cols-3 gap-2 mt-1">
      {paymentMethods.map((method) => (
        <div
          key={method.value}
          onClick={() => onSelectMethod(method.value)}
          className={classNames(
            'flex items-center justify-center p-3 rounded-md border-2 cursor-pointer transition-all',
            selectedMethods.includes(method.value)
              ? 'border-primary bg-primary/10'
              : 'border-muted bg-background hover:bg-blue/10'
          )}
        >
          <div className="flex flex-col items-center gap-1">
            <div className="text-xl">{method.icon}</div>
            <span className="text-sm font-medium">{method.label}</span>
          </div>
        </div>
      ))}
    </div>
  );
}
