import { LabelValue } from '@/types';
import { getHoursIntervals } from '@/utils';
import { CancellationPoliciesType } from './types';

export const OPTIONS_SELECT_MEET_TYPE = [
  { label: 'Google Meet', value: 'google_meet' },
  { label: 'Zoom', value: 'zoom' }
];

export const OPTIONS_SELECT_MODALITIES: LabelValue[] = [
  { label: 'Presencial', value: 'local' },
  { label: 'Online', value: 'virtual' },
  { label: 'Ambas', value: 'open' }
];

export const OPTIONS_SOCIAL_MEDIA: LabelValue[] = [
  { label: 'Instagram', value: 'instagram', icon: 'instagram' },
  { label: 'Facebook', value: 'facebook', icon: 'facebook' },
  { label: 'Linkedin', value: 'linkedin', icon: 'linked_in' },
  { label: 'Youtube', value: 'youtube', icon: 'youtube' },
  { label: 'Web', value: 'web', icon: 'web' }
];

export const OPTIONS_HOURS: LabelValue[] = getHoursIntervals([
  {
    start_time: '07:00:00',
    end_time: '24:00:00'
  }
]);

export const MINUTES_OPTIONS: LabelValue[] = [
  { label: '15 minutos', value: '15' },
  { label: '30 minutos', value: '30' },
  { label: '1 hora', value: '60' },
  { label: '4 horas', value: '240' },
  { label: '6 horas', value: '360' },
  { label: '12 horas', value: '720' },
  { label: '24 horas', value: '1440' },
  { label: '48 horas', value: '2880' },
  { label: '7 dias', value: '10080' }
];

export const CANCELLATION_OPTIONS: LabelValue[] = [
  { label: 'antes del inicio', value: 'before' },
  { label: 'despues del inicio', value: 'after' }
];

export const INIT_CANCELLATION_POLICY: CancellationPoliciesType = {
  policy_refund_mins: '15'
};

const IMAGE_CDN_URL = import.meta.env.VITE_IMAGE_CDN_URL;

export const DEFAULT_AGORA_IMAGE = `${IMAGE_CDN_URL}/defaults/default_cover_image_ue.jpg`;
