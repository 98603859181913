import { SupportedCountriesE } from '@/types/cyclone/models';

export interface SelectProps {
  label: string;
  value: string;
}

export const countryOptions: SelectProps[] = [
  {
    value: SupportedCountriesE.ARGENTINA,
    label: '🇦🇷 Argentina'
  },
  {
    value: SupportedCountriesE.CHILE,
    label: '🇨🇱 Chile'
  },
  {
    value: SupportedCountriesE.URUGUAY,
    label: '🇺🇾 Uruguay'
  }
];

export const timezoneOptions: Record<SupportedCountriesE, SelectProps[]> = {
  [SupportedCountriesE.ARGENTINA]: [
    {
      value: 'America/Argentina/Buenos_Aires',
      label: 'Argentina - Buenos Aires (UTC-3)'
    }
  ],
  [SupportedCountriesE.URUGUAY]: [
    {
      value: 'America/Montevideo',
      label: 'Uruguay - Montevideo (UTC-3)'
    }
  ],
  [SupportedCountriesE.CHILE]: [
    {
      value: 'America/Santiago',
      label: 'Chile - Santiago (UTC-4)'
    },
    {
      value: 'America/Punta_Arenas',
      label: 'Chile - Punta Arenas (UTC-3)'
    }
  ],
  [SupportedCountriesE.MEXICO]: [
    { value: 'America/Mexico_City', label: 'Mexico - Mexico City (UTC-6)' },
    { value: 'America/Tijuana', label: 'Mexico - Tijuana (UTC-8)' },
    { value: 'America/Hermosillo', label: 'Mexico - Hermosillo (UTC-7)' },
    { value: 'America/Chihuahua', label: 'Mexico - Chihuahua (UTC-7)' },
    { value: 'America/Ciudad_Juarez', label: 'Mexico - Ciudad Juárez (UTC-7)' },
    { value: 'America/Ojinaga', label: 'Mexico - Ojinaga (UTC-6)' },
    { value: 'America/Merida', label: 'Mexico - Mérida (UTC-6)' },
    { value: 'America/Cancun', label: 'Mexico - Cancún (UTC-5)' },
    { value: 'America/Monterrey', label: 'Mexico - Monterrey (UTC-6)' },
    { value: 'America/Matamoros', label: 'Mexico - Matamoros (UTC-6)' },
    { value: 'America/Mazatlan', label: 'Mexico - Mazatlán (UTC-7)' },
    { value: 'America/Bahia_Banderas', label: 'Mexico - Bahía de Banderas (UTC-6)' }
  ],
  [SupportedCountriesE.VENEZUELA]: [
    {
      value: 'America/Caracas',
      label: 'Venezuela - Caracas (UTC-4)'
    }
  ]
};

export const statesOptions: Record<SupportedCountriesE, SelectProps[]> = {
  [SupportedCountriesE.ARGENTINA]: [
    { value: 'AR-B', label: 'Buenos Aires' },
    { value: 'AR-C', label: 'Ciudad Autónoma de Buenos Aires' },
    { value: 'AR-K', label: 'Catamarca' },
    { value: 'AR-H', label: 'Chaco' },
    { value: 'AR-U', label: 'Chubut' },
    { value: 'AR-X', label: 'Córdoba' },
    { value: 'AR-W', label: 'Corrientes' },
    { value: 'AR-E', label: 'Entre Ríos' },
    { value: 'AR-P', label: 'Formosa' },
    { value: 'AR-Y', label: 'Jujuy' },
    { value: 'AR-L', label: 'La Pampa' },
    { value: 'AR-F', label: 'La Rioja' },
    { value: 'AR-M', label: 'Mendoza' },
    { value: 'AR-N', label: 'Misiones' },
    { value: 'AR-Q', label: 'Neuquén' },
    { value: 'AR-R', label: 'Río Negro' },
    { value: 'AR-A', label: 'Salta' },
    { value: 'AR-J', label: 'San Juan' },
    { value: 'AR-D', label: 'San Luis' },
    { value: 'AR-Z', label: 'Santa Cruz' },
    { value: 'AR-S', label: 'Santa Fe' },
    { value: 'AR-G', label: 'Santiago del Estero' },
    { value: 'AR-V', label: 'Tierra del Fuego' },
    { value: 'AR-T', label: 'Tucumán' }
  ],
  [SupportedCountriesE.URUGUAY]: [
    { value: 'UY-AR', label: 'Artigas' },
    { value: 'UY-CA', label: 'Canelones' },
    { value: 'UY-CL', label: 'Cerro Largo' },
    { value: 'UY-CO', label: 'Colonia' },
    { value: 'UY-DU', label: 'Durazno' },
    { value: 'UY-FS', label: 'Flores' },
    { value: 'UY-FD', label: 'Florida' },
    { value: 'UY-LA', label: 'Lavalleja' },
    { value: 'UY-MA', label: 'Maldonado' },
    { value: 'UY-MO', label: 'Montevideo' },
    { value: 'UY-PA', label: 'Paysandú' },
    { value: 'UY-RN', label: 'Río Negro' },
    { value: 'UY-RV', label: 'Rivera' },
    { value: 'UY-RO', label: 'Rocha' },
    { value: 'UY-SA', label: 'Salto' },
    { value: 'UY-SJ', label: 'San José' },
    { value: 'UY-SO', label: 'Soriano' },
    { value: 'UY-TA', label: 'Tacuarembó' },
    { value: 'UY-TT', label: 'Treinta y Tres' }
  ],
  [SupportedCountriesE.CHILE]: [
    { value: 'CL-AI', label: 'Aisén' },
    { value: 'CL-AN', label: 'Antofagasta' },
    { value: 'CL-AP', label: 'Arica y Parinacota' },
    { value: 'CL-AT', label: 'Atacama' },
    { value: 'CL-BI', label: 'Biobío' },
    { value: 'CL-CO', label: 'Coquimbo' },
    { value: 'CL-AR', label: 'La Araucanía' },
    { value: 'CL-LR', label: 'Los Ríos' },
    { value: 'CL-LL', label: 'Los Lagos' },
    { value: 'CL-MA', label: 'Magallanes' },
    { value: 'CL-ML', label: 'Maule' },
    { value: 'CL-RM', label: 'Región Metropolitana de Santiago' },
    { value: 'CL-TA', label: 'Tarapacá' },
    { value: 'CL-VS', label: 'Valparaíso' },
    { value: 'CL-NB', label: 'Ñuble' }
  ],
  [SupportedCountriesE.MEXICO]: [
    { value: 'MX-AG', label: 'Aguascalientes' },
    { value: 'MX-BC', label: 'Baja California' },
    { value: 'MX-BS', label: 'Baja California Sur' },
    { value: 'MX-CM', label: 'Campeche' },
    { value: 'MX-CO', label: 'Coahuila' },
    { value: 'MX-CS', label: 'Chiapas' },
    { value: 'MX-CH', label: 'Chihuahua' },
    { value: 'MX-CDMX', label: 'Ciudad de México' },
    { value: 'MX-DU', label: 'Durango' },
    { value: 'MX-GT', label: 'Guanajuato' },
    { value: 'MX-GR', label: 'Guerrero' },
    { value: 'MX-HG', label: 'Hidalgo' },
    { value: 'MX-JA', label: 'Jalisco' },
    { value: 'MX-MX', label: 'Estado de México' },
    { value: 'MX-MI', label: 'Michoacán' },
    { value: 'MX-MO', label: 'Morelos' },
    { value: 'MX-NA', label: 'Nayarit' },
    { value: 'MX-NL', label: 'Nuevo León' },
    { value: 'MX-OA', label: 'Oaxaca' },
    { value: 'MX-PU', label: 'Puebla' },
    { value: 'MX-QR', label: 'Quintana Roo' },
    { value: 'MX-QT', label: 'Querétaro' },
    { value: 'MX-SL', label: 'San Luis Potosí' },
    { value: 'MX-SI', label: 'Sinaloa' },
    { value: 'MX-SO', label: 'Sonora' },
    { value: 'MX-TB', label: 'Tabasco' },
    { value: 'MX-TM', label: 'Tamaulipas' },
    { value: 'MX-TL', label: 'Tlaxcala' },
    { value: 'MX-VE', label: 'Veracruz' },
    { value: 'MX-YU', label: 'Yucatán' },
    { value: 'MX-ZA', label: 'Zacatecas' }
  ],
  [SupportedCountriesE.VENEZUELA]: [
    { value: 'VE-A', label: 'Amazonas' },
    { value: 'VE-B', label: 'Anzoátegui' },
    { value: 'VE-C', label: 'Apure' },
    { value: 'VE-D', label: 'Aragua' },
    { value: 'VE-E', label: 'Barinas' },
    { value: 'VE-F', label: 'Bolívar' },
    { value: 'VE-G', label: 'Carabobo' },
    { value: 'VE-H', label: 'Cojedes' },
    { value: 'VE-I', label: 'Delta Amacuro' },
    { value: 'VE-DC', label: 'Caracas' },
    { value: 'VE-K', label: 'Falcón' },
    { value: 'VE-L', label: 'Guárico' },
    { value: 'VE-M', label: 'Lara' },
    { value: 'VE-N', label: 'Mérida' },
    { value: 'VE-O', label: 'Miranda' },
    { value: 'VE-P', label: 'Monagas' },
    { value: 'VE-R', label: 'Nueva Esparta' },
    { value: 'VE-S', label: 'Portuguesa' },
    { value: 'VE-T', label: 'Sucre' },
    { value: 'VE-U', label: 'Táchira' },
    { value: 'VE-V', label: 'Trujillo' },
    { value: 'VE-W', label: 'Vargas' },
    { value: 'VE-X', label: 'Yaracuy' },
    { value: 'VE-Y', label: 'Zulia' }
  ]
};
