import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

interface TokenInfo {
  accessToken: {
    expiresAt: string;
    hoursRemaining: number;
    token: string;
  };
  idToken: {
    expiresAt: string;
    hoursRemaining: number;
    token: string;
  };
  lastCheck: string;
}

export const useTokenMonitor = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [tokenInfo, setTokenInfo] = useState<TokenInfo | null>(null);

  const checkTokenStatus = async () => {
    try {
      const token = await getAccessTokenSilently({
        detailedResponse: true
      });

      // Parse JWT to get expiration
      const parseJWT = (token: string) => {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map((c) => {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
        );
        return JSON.parse(jsonPayload);
      };

      const accessTokenData = parseJWT(token.access_token);
      const idTokenData = parseJWT(token.id_token);

      const now = new Date();
      const accessExpiresAt = new Date(accessTokenData.exp * 1000);
      const idExpiresAt = new Date(idTokenData.exp * 1000);

      setTokenInfo({
        accessToken: {
          expiresAt: accessExpiresAt.toLocaleString(),
          hoursRemaining: Math.round((accessExpiresAt.getTime() - now.getTime()) / (1000 * 60 * 60)),
          token: token.access_token.substring(0, 20) + '...'
        },
        idToken: {
          expiresAt: idExpiresAt.toLocaleString(),
          hoursRemaining: Math.round((idExpiresAt.getTime() - now.getTime()) / (1000 * 60 * 60)),
          token: token.id_token.substring(0, 20) + '...'
        },
        lastCheck: now.toLocaleString()
      });
    } catch (error) {
      console.error('Token check failed:', error);
      setTokenInfo(null);
    }
  };

  useEffect(() => {
    checkTokenStatus();
    // Check every hour
    const interval = setInterval(checkTokenStatus, 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return { tokenInfo, refreshCheck: checkTokenStatus };
};
