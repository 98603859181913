import React from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../SelectV3';
import classNames from 'classnames';

const generateTimeArray = () => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 5) {
      const period = hour < 12 ? 'AM' : 'PM';
      const displayHour = hour % 12 || 12;
      const formattedHour = String(displayHour).padStart(2, '0');
      const formattedMinute = String(minute).padStart(2, '0');
      times.push({
        value: `${String(hour).padStart(2, '0')}:${formattedMinute}`,
        label: `${formattedHour}:${formattedMinute} ${period}`
      });
    }
  }
  return times;
};

type Props = {
  initialValue?: string;
  onTimeChange?: (time: string) => void;
  disabled?: boolean;
  minTime?: string;
  maxTime?: string;
};

export const InputTimePicker: React.FC<Props> = ({
  initialValue,
  onTimeChange,
  disabled = false,
  minTime,
  maxTime
}) => {
  const [selectedValue, setSelectedValue] = React.useState<string | undefined>(initialValue);

  const timeArray = React.useMemo(() => {
    const allTimes = generateTimeArray();
    return allTimes.filter((timeObj) => {
      if (minTime && timeObj.value < minTime) return false;
      if (maxTime && timeObj.value > maxTime) return false;
      return true;
    });
  }, [minTime, maxTime]);

  const handleValueChange = (value: string) => {
    setSelectedValue(value);
    onTimeChange?.(value);
  };

  React.useEffect(() => {
    if (initialValue) {
      setSelectedValue(initialValue);
    }
  }, [initialValue]);

  const selectedLabel =
    timeArray.find((time) => time.value === selectedValue)?.label || 'Seleccioná un horario';

  return (
    <Select
      disabled={disabled}
      value={selectedValue || '05:50'}
      onValueChange={(value) => handleValueChange(value)}
    >
      <SelectTrigger
        className={classNames(
          'bg-white cursor-pointer transition-colors rounded border-[#212121]/40 hover:border-[#0072FB]',
          { 'text-[#212121]': selectedValue },
          { 'text-gray-500': !selectedValue },
          {
            'text-gray-400 bg-transparent border-gray-400 cursor-default hover:border-gray-400': disabled
          }
        )}
      >
        <SelectValue>{selectedLabel}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        {timeArray.map((timeObj, i) => (
          <SelectItem
            ref={(ref) => {
              if (timeObj.value === selectedValue) {
                ref?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
              }
              if (!selectedValue && timeObj.value === '05:50') {
                ref?.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }
            }}
            className={classNames({
              'bg-[#0072FB] text-white': timeObj.value === selectedValue,
              'hover:bg-gray-100': timeObj.value !== selectedValue
            })}
            key={i}
            value={timeObj.value}
          >
            {timeObj.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
