import React, { useState } from 'react';
import { NavbarItem } from '..';
import { useAuth } from '@/contexts';
import { useNavigate } from 'react-router-dom';
import { trackGenericEvent } from '@/analytics';
import { AgoraPlanNameE } from '@/types/cyclone/models';
import { Row } from '@/components/Row';
import { Column } from '@/components/Column';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faSignOutAlt, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Dialog, DialogContentMenuMobile } from '@/components/Dialog';
import { motion, AnimatePresence } from 'framer-motion'; // Added for animations

type MobileMenuProps = {
  showMobileMenu: boolean;
  topItems: NavbarItem[];
  bottomItems: NavbarItem[];
  handleItemClick: () => void;
};

export const MobileMenu: React.FC<MobileMenuProps> = ({
  showMobileMenu,
  topItems,
  bottomItems,
  handleItemClick
}) => {
  const { session, logOut } = useAuth();
  const navigate = useNavigate();
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);

  const toggleDropdown = (index: number, event: React.MouseEvent) => {
    event.stopPropagation();
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const handleNavigate = (link: string) => {
    if (link.includes('http')) {
      window.open(link, '_blank');
    } else {
      navigate(link);
      handleItemClick();
    }
  };

  const filteredTopItems =
    session?.vendor?.plan_name === AgoraPlanNameE.EVENT
      ? topItems.filter((item) => item.label !== 'Facturación')
      : topItems;

  const buildBottomItems = () => {
    const filteredBottomItems = bottomItems.filter(
      (item) => item.label !== 'Mi perfil' && item.label !== 'Salir'
    );
    filteredBottomItems.push({
      icon: faCircleQuestion,
      label: 'Centro de ayuda',
      onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Help Center' }),
      link: 'https://ayuda.agora.red/es/'
    });
    return filteredBottomItems;
  };

  const handleItemSelection = (item: NavbarItem) => {
    if (item.onClick) item.onClick();
    if (item.link) handleNavigate(item.link);
    if (!item.isDropdown) handleItemClick();
  };

  const dropdownVariants = {
    closed: {
      height: 0,
      opacity: 0,
      transition: { duration: 0.3, ease: 'easeOut' }
    },
    open: {
      height: 'auto',
      opacity: 1,
      transition: { duration: 0.3, ease: 'easeOut' }
    }
  };

  return (
    <Dialog open={showMobileMenu} onOpenChange={handleItemClick}>
      <DialogContentMenuMobile className="bg-white shadow-lg">
        <FontAwesomeIcon
          onClick={handleItemClick}
          icon={faXmark}
          size="2x"
          className="absolute top-5 right-6 cursor-pointer text-gray-600 hover:text-gray-800 transition-colors duration-200"
        />
        <nav className="mt-8">
          <Column gap={10} className="px-4 py-4 border-b border-gray-200">
            {filteredTopItems.map((item, index) => {
              const { label, icon, isDropdown, dropdownContent } = item;
              return (
                <Column
                  className="w-full py-2 hover:bg-gray-50 rounded-lg transition-colors duration-200"
                  key={`top-item-${index}`}
                >
                  {isDropdown ? (
                    <div className="w-full">
                      <Row
                        align="center"
                        justify="space-between"
                        className="w-full px-3 py-2 cursor-pointer"
                        onClick={(e) => toggleDropdown(index, e)}
                      >
                        <Row align="center" gap={12}>
                          {icon && <FontAwesomeIcon icon={icon} className="text-gray-600" fixedWidth />}
                          <span className="text-xl font-medium text-gray-800">{label}</span>
                        </Row>
                        <FontAwesomeIcon
                          icon={openDropdownIndex === index ? faChevronUp : faChevronDown}
                          className="text-gray-500 transition-transform duration-200"
                        />
                      </Row>

                      <AnimatePresence>
                        {openDropdownIndex === index && dropdownContent && (
                          <motion.div
                            variants={dropdownVariants}
                            initial="closed"
                            animate="open"
                            exit="closed"
                            className="overflow-hidden"
                          >
                            <Column className="w-full gap-2 pl-10 mt-2 pb-2 bg-gray-50">
                              {dropdownContent.map((dropdownItem, dropdownIndex) => (
                                <Row
                                  align="center"
                                  justify="space-between"
                                  gap={10}
                                  className="py-2 px-3 group rounded-md cursor-pointer text-gray-600 transition-colors duration-200"
                                  key={`dropdown-item-${dropdownIndex}`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleItemSelection(dropdownItem);
                                  }}
                                >
                                  <div>
                                    {dropdownItem.icon && (
                                      <FontAwesomeIcon
                                        icon={dropdownItem.icon}
                                        fixedWidth
                                        className="group-hover:text-gray-800"
                                      />
                                    )}
                                    <span className="text-lg group-hover:text-gray-800">
                                      {dropdownItem.label}
                                    </span>
                                  </div>
                                  {typeof dropdownItem.length === 'number' && dropdownItem.length > 0 && (
                                    <span className="text-sm !bg-white badge leading-none text-[#626262] group-hover:text-gray-800">
                                      {dropdownItem.length}
                                    </span>
                                  )}
                                </Row>
                              ))}
                            </Column>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  ) : (
                    <Row
                      align="center"
                      justify="space-between"
                      className="w-full px-3 py-2 cursor-pointer"
                      onClick={() => handleItemSelection(item)}
                    >
                      <Row align="center" gap={12}>
                        {icon && <FontAwesomeIcon icon={icon} className="text-gray-600" fixedWidth />}
                        <span className="text-xl font-medium text-gray-800">{label}</span>
                      </Row>
                    </Row>
                  )}
                </Column>
              );
            })}
          </Column>

          <Column gap={10} className="px-6 py-8 border-b border-gray-200">
            {buildBottomItems().map((item, index) => (
              <Row
                align="center"
                justify="space-between"
                className="w-full px-3 py-2 hover:bg-gray-50 rounded-lg cursor-pointer transition-colors duration-200"
                key={`bottom-item-${index}`}
                onClick={() => handleItemSelection(item)}
              >
                <Row align="center" gap={12}>
                  {item.icon && <FontAwesomeIcon icon={item.icon} className="text-gray-600" fixedWidth />}
                  <span className="text-xl font-medium text-gray-800">{item.label}</span>
                </Row>
              </Row>
            ))}
          </Column>

          <Column align="center" className="px-6 py-8">
            <Row
              align="center"
              gap={12}
              className="text-xl text-gray-800 cursor-pointer px-3 py-2 hover:bg-gray-50 rounded-lg w-full justify-center transition-colors duration-200"
              onClick={() => {
                logOut();
                handleItemClick();
              }}
            >
              <FontAwesomeIcon icon={faSignOutAlt} className="text-gray-600" fixedWidth />
              <span>Cerrar sesión</span>
            </Row>
          </Column>
        </nav>
      </DialogContentMenuMobile>
    </Dialog>
  );
};
