import { Row } from '@/components';
import hexToRgba from 'hex-to-rgba';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { DropdownColor } from './components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

interface InputColorPropsI {
  value: string;
  onChange: (value: string) => void;
  title?: string;
  isOpen?: (open: boolean) => void;
}

export const InputColor: FunctionComponent<InputColorPropsI> = ({ value, onChange, title, isOpen }) => {
  const [color, setColor] = useState(value);
  const [isLoad, setIsLoad] = useState<boolean | undefined>(false);
  const [showColorPicker, setShowColorPicker] = useState(false);

  useEffect(() => {
    if (!showColorPicker && isLoad) {
      onChange(color);
    }
    if (!isLoad) setIsLoad(true);
  }, [showColorPicker]);

  useEffect(() => {
    setColor(value);
  }, [value]);

  useEffect(() => {
    if (isOpen) isOpen(showColorPicker);
  }, [showColorPicker]);

  const changeColor = (newColor: string) => {
    setColor(newColor);
  };

  return (
    <DropdownColor onOpenChange={(value) => setShowColorPicker(value)} onChange={changeColor} value={color}>
      <Row
        className="rounded p-2 relative w-fit cursor-pointer gap-2 hover:bg-gray-100 transition-colors"
        align="center"
      >
        <div
          className="border-[#eceef0] border rounded-full w-4 aspect-square"
          style={{ background: hexToRgba(color) }}
        />
        <div className="flex gap-2 items-center text-base text-[#757575]">
          {title && <p className="w-full">{title}</p>}
          <FontAwesomeIcon icon={faCaretDown} size="xs" fixedWidth />
        </div>
      </Row>
    </DropdownColor>
  );
};
