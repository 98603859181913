import { Navigate } from 'react-router-dom';
import { useStoreFront } from '../StoreFrontBuilder/context';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { slide3, slide4, slide5 } from '@/static/images';

export default function StoryPreview() {
  const { iFrameKey } = useStoreFront();

  const vendorUsername = new URLSearchParams(window.location.search).get('vendor');
  const slideId = new URLSearchParams(window.location.search).get('id');

  if (!slideId || !vendorUsername) return <Navigate to={`/posts/feed`} />;

  const slide = getSlideById(parseInt(slideId, 10), vendorUsername, iFrameKey);

  if (!slide) {
    return <div className="h-screen flex items-center justify-center">Slide not found</div>;
  }

  return <>{slide.content}</>;
}

// Function to get slide by ID
function getSlideById(id: number, vendorUsername: string, iFrameKey: number) {
  const NIGHTWING_URL = import.meta.env.VITE_NIGHTWING_URL || 'https://agora.red';
  const slides = [
    {
      id: 1,
      title: 'Página 1',
      content: (
        <div className="bg-white w-[1080px] h-[1920px] flex items-center flex-col gap-16 justify-center">
          <div className="flex text-2xl items-center justify-center mask mask-circle bg-black w-16 h-16 text-white font-bold">
            1
          </div>
          <h1 className="text-7xl font-medium text-center">
            Ingresá a <br /> nuestra página
          </h1>
          <p className="text-3xl">agora.red/{vendorUsername}</p>
          <div className="relative w-[50%] h-[50%] bg-gray-300 rounded-[2.5rem] overflow-hidden border-[10px] shadow-md">
            {/* Pantalla */}
            <div className="relative h-full w-full bg-gray-200 rounded-[2rem] overflow-hidden">
              <iframe
                className="w-full h-full scale-100 origin-top"
                src={`${NIGHTWING_URL}/iframe/${vendorUsername}?isPreview=true`}
                key={iFrameKey}
                style={{ overflow: 'hidden', pointerEvents: 'none' }}
              ></iframe>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 2,
      title: 'Página 2',
      content: (
        <div className="bg-white w-[1080px] h-[1920px] flex items-center flex-col gap-16 justify-center">
          <div className="flex text-2xl items-center justify-center mask mask-circle bg-black w-16 h-16 text-white font-bold">
            2
          </div>
          <h1 className="text-7xl font-medium text-center">
            Seleccioná el servicio <br />
            que quieras realizarte
          </h1>
          <div className="relative w-[50%] h-[50%] bg-gray-300 rounded-[2.5rem] overflow-hidden border-[10px] shadow-md">
            {/* Pantalla */}
            <div className="relative h-full w-full bg-gray-200 rounded-[2rem] overflow-hidden">
              <img src={slide3} alt="slide3" />
            </div>
          </div>
        </div>
      )
    },
    {
      id: 3,
      title: 'Página 3',
      content: (
        <div className="bg-white w-[1080px] h-[1920px] flex items-center flex-col gap-16 justify-center">
          <div className="flex text-2xl items-center justify-center mask mask-circle bg-black w-16 h-16 text-white font-bold">
            3
          </div>
          <h1 className="text-7xl font-medium text-center">
            Seleccioná la fecha y <br /> hora del turno
          </h1>
          <div className="relative w-[50%] h-[50%] bg-gray-300 rounded-[2.5rem] overflow-hidden border-[10px] shadow-md">
            {/* Pantalla */}
            <div className="relative h-full w-full bg-gray-200 rounded-[2rem] overflow-hidden">
              <img src={slide4} alt="slide4" />
            </div>
          </div>
        </div>
      )
    },
    {
      id: 4,
      title: 'Página 4',
      content: (
        <div className="bg-white w-[1080px] h-[1920px] flex items-center flex-col gap-16 justify-center">
          <div className="flex text-2xl items-center justify-center mask mask-circle bg-black w-16 h-16 text-white font-bold">
            4
          </div>
          <h1 className="text-7xl font-medium text-center">
            Completá tus datos <br /> personales
          </h1>
          <div className="relative w-[50%] h-[50%] bg-gray-300 rounded-[2.5rem] overflow-hidden border-[10px] shadow-md">
            {/* Pantalla */}
            <div className="relative h-full w-full bg-gray-200 rounded-[2rem] overflow-hidden">
              <img src={slide5} alt="slide5" />
            </div>
          </div>
        </div>
      )
    },
    {
      id: 5,
      title: 'Página 5',
      content: (
        <div className="bg-white w-[1080px] h-[1920px] flex items-center flex-col gap-16 justify-center">
          <div className="flex text-2xl items-center justify-center mask mask-circle bg-black w-16 h-16 text-white font-bold">
            5
          </div>
          <h1 className="text-7xl font-medium text-center">¡Y listo!</h1>
          <div className="relative w-[50%] h-[50%] bg-gray-300 rounded-[2.5rem] overflow-hidden border-[10px] shadow-md">
            {/* Pantalla */}
            <div className="relative h-full w-full bg-gray-200 rounded-[2rem] overflow-hidden">
              <div className="bg-green-500 flex flex-col gap-8 items-center justify-center w-full h-full">
                <FontAwesomeIcon icon={faCheck} className="text-white" size="7x" />
                <span className="text-white text-3xl font-medium">¡Reserva confirmada!</span>
                <div className="flex items-center justify-center rounded-full py-2 px-4 bg-white text-green-500 text-xl">
                  Ver reservas
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 6,
      title: 'Página 6',
      content: (
        <div className="bg-white w-[1080px] h-[1920px] flex items-center flex-col gap-16 justify-center">
          <div className="flex text-2xl items-center justify-center mask mask-circle bg-black w-16 h-16 text-white font-bold">
            6
          </div>
          <h1 className="text-7xl font-medium text-center">
            Ingresá a <br /> nuestra página
          </h1>
          <p className="text-3xl">agora.red/{vendorUsername}</p>
          <div className="relative w-[50%] h-[50%] bg-gray-300 rounded-[2.5rem] overflow-hidden border-[10px] shadow-md">
            {/* Pantalla */}
            <div className="relative h-full w-full bg-gray-200 rounded-[2rem] overflow-hidden">
              <iframe
                className="w-full h-full scale-100 origin-top"
                src={`${NIGHTWING_URL}/iframe/${vendorUsername}?isPreview=true`}
                key={iFrameKey}
                style={{ overflow: 'hidden', pointerEvents: 'none' }}
              ></iframe>
            </div>
          </div>
        </div>
      )
    }
  ];

  return slides.find((slide) => slide.id === id);
}
