import React, { useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavbarItems, useWindowResize } from '@/hooks';
import { Logo, ResponsiveContainer, Row } from '@/components';
import { useAuth } from '@/contexts';
import { ProfileDropdownMenu } from '../Navbar/components';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { GradientText, StarBorder } from './utils';

const RestrictedHeader = () => {
  const { session } = useAuth();
  const { isMobileSize } = useWindowResize();
  const navigate = useNavigate();
  const { pathname: path } = useLocation();
  const { dropdownItems } = useNavbarItems(path);
  const isVenue = session?.is_venue;

  const STEPS = {
    PAYMENT: {
      id: 'payment',
      title: 'Medios de cobro',
      description: 'Habilita todos los medios de pago que aceptarás en tu negocio.',
      path: '/ajustes/medios-de-pago'
    },
    VENUE: {
      id: 'venue',
      title: 'Gestiona tu equipo',
      description: 'Añade profesionales a tu equipo y gestiona sus servicios',
      path: '/equipo?driver=true'
    },
    SCHEDULE: {
      id: 'schedule',
      title: 'Horarios de trabajo',
      description: isVenue
        ? 'Configura los días y horarios de trabajo de cada integrante'
        : 'Configura tus días y horarios de trabajo',
      path: '/agenda/horarios-de-trabajo?driver=true'
    },
    SERVICE: {
      id: 'service',
      title: 'Crea tu primer servicio',
      description: 'Configura los servicios que ofreces',
      path: '/servicio/crear'
    }
  } as const;

  const getCurrentStep = useCallback(() => {
    const { vendor } = session || {};
    if (!vendor?.vendor_activity_tracking) return null;

    const {
      vendor_activity_tracking: {
        first_payment_method_at: isPaymentConfigured,
        first_availability_at: isScheduleConfigured,
        first_service_at: isServiceCreated,
        first_staff_created_at: isStaffCreated
      }
    } = vendor;

    if (!isPaymentConfigured) return STEPS.PAYMENT;
    if (isVenue && !isStaffCreated) return STEPS.VENUE;
    if (!isScheduleConfigured) return STEPS.SCHEDULE;
    if (!isServiceCreated) return STEPS.SERVICE;
    return null;
  }, [session, isVenue]);

  const getCompletedStepsCount = useCallback(() => {
    const { vendor } = session || {};
    if (!vendor) return 0;

    const steps = [
      vendor.vendor_activity_tracking?.first_payment_method_at,
      ...(isVenue ? [vendor.venue?.vendors && vendor.venue.vendors.length > 1] : []),
      vendor.vendor_activity_tracking?.first_availability_at,
      vendor.vendor_activity_tracking?.first_service_at
    ];

    return steps.filter(Boolean).length;
  }, [session, isVenue]);

  const initializeDriver = useCallback(
    (currentStep: typeof STEPS[keyof typeof STEPS]) => {
      const driverObj = driver({
        popoverClass: 'driverjs-theme',
        stagePadding: 4,
        showButtons: ['next'],
        doneBtnText: 'Ir ahora',
        allowClose: false,
        onNextClick: () => {
          const targetPath =
            currentStep.id === 'schedule' && !session?.is_venue
              ? `${currentStep.path}&type=bulk`
              : currentStep.path;
          navigate(targetPath);
        },
        steps: [
          {
            element: '#driver-currentNavbarStep',
            popover: {
              side: 'bottom',
              title: currentStep.title,
              description: currentStep.description
            }
          }
        ]
      });
      return driverObj;
    },
    [navigate, session?.is_venue]
  );

  const handlePathCheck = useCallback(() => {
    const currentStep = getCurrentStep();
    if (!currentStep) return;

    const isOnDifferentPath = !path.includes(currentStep.path.split('?')[0]);
    if (isOnDifferentPath) {
      const driverObj = initializeDriver(currentStep);
      driverObj.drive();
    }
  }, [path, getCurrentStep, initializeDriver]);

  const handleStepClick = useCallback(() => {
    const currentStep = getCurrentStep();
    if (!currentStep || path.includes(currentStep.path.split('?')[0])) return;

    const driverObj = initializeDriver(currentStep);
    driverObj.drive();
  }, [path, getCurrentStep, initializeDriver]);

  useEffect(() => {
    const currentStep = getCurrentStep();
    if (!currentStep) return;

    handlePathCheck();

    const driverObj = initializeDriver(currentStep);
    return () => driverObj.destroy();
  }, [path, session?.vendor, handlePathCheck, getCurrentStep, initializeDriver]);

  const currentStep = getCurrentStep();
  const completedSteps = getCompletedStepsCount();

  if (!session?.vendor) {
    return <header className="sticky top-0 left-0 h-[48px] right-0 bottom-0 border-b bg-gray-200 z-50" />;
  }

  return (
    <header className="sticky top-0 left-0 h-[48px] right-0 bottom-0 border-b bg-white z-50">
      <ResponsiveContainer>
        <Row align="center" justify="space-between" className="w-full h-[48px]">
          {isMobileSize ? (
            <Logo type="triangle" width="20px" height="48px" />
          ) : (
            <Logo type="horizontal" width="100px" height="48px" />
          )}
          {currentStep && (
            <div id="driver-currentNavbarStep" onClick={handleStepClick} className="h-[30px]">
              <StarBorder
                as="button"
                className="px-4 py-1 flex gap-2 items-center cursor-pointer border rounded-full text-xs sm:text-sm font-medium"
                color="#0072FB"
                speed="8s"
              >
                <span>
                  Paso {completedSteps + 1} de {isVenue ? 4 : 3}:
                </span>
                <GradientText animationSpeed={8} showBorder={false}>
                  <span>{currentStep.title}</span>
                </GradientText>
              </StarBorder>
            </div>
          )}
          <ProfileDropdownMenu avatar={`${session.avatar_url}_250.webp`} dropdownItems={dropdownItems} />
        </Row>
      </ResponsiveContainer>
    </header>
  );
};

export default RestrictedHeader;
