import { VendorWorkingHoursI } from '@/types/cyclone/models';

export const getDaysFromWorkingHours = (
  workingHours: VendorWorkingHoursI[],
  serviceId?: number
): number[] => {
  const workingHoursByServiceId = workingHours.filter(({ service_id }) => service_id === serviceId);
  const generalWorkingHours = workingHours.filter(({ service_id }) => service_id === null);

  if (workingHoursByServiceId.length > 0) return workingHoursByServiceId.map(({ day }) => day);
  return generalWorkingHours.map(({ day }) => day);
};
