import { Column, WeekDays } from '@/components';
import { useAuth } from '@/contexts';
import { VendorI } from '@/types/cyclone/models';
import React, { useEffect, useState } from 'react';
import { SkeletonRow } from '../utils';
import { getDaysFromWorkingHours } from '@/utils/recurrence';
import CheckSuccess from '@/static/icons/CheckSuccess';
import classNames from 'classnames';
import FloatButton from './FloatButton';

interface ListVenuesPropsI {
  setSelectedVendor: (vendor: VendorI) => void;
  venues?: VendorI[];
}

export const ListVenues: React.FC<ListVenuesPropsI> = ({ setSelectedVendor, venues }) => {
  const { session } = useAuth();
  const [staffWithWorkingHours, setStaffWithWorkingHours] = useState<VendorI[]>([]);

  const driverMode = new URLSearchParams(window.location.search).get('driver') === 'true';

  const allStaffAreWithWorkingHours = venues?.every((staff) => {
    const workingHours = staff.vendor_working_hours;
    const days = getDaysFromWorkingHours(workingHours || []);
    return days.length > 0;
  });

  useEffect(() => {
    if (driverMode && venues) {
      const staffWithWorkingHours = venues.filter((staff) => {
        const workingHours = staff.vendor_working_hours;
        const days = getDaysFromWorkingHours(workingHours || []);
        return days.length > 0;
      });
      setStaffWithWorkingHours(staffWithWorkingHours);
    }
  }, [driverMode, venues]);

  if (session?.is_venue && venues?.length === 0) return;

  return (
    <>
      <Column className="w-full max-w-xl border rounded-xl bg-white">
        <h1 className="text-base sm:text-2xl p-4">Selecciona un profesional:</h1>
        {!venues ? (
          <SkeletonRow />
        ) : (
          venues?.map((venue, i) => {
            const staffHasWorkingHours = staffWithWorkingHours.some((staff) => staff.id === venue.id);
            const workingHours = venue.vendor_working_hours;
            const days = getDaysFromWorkingHours(workingHours || []);
            const servicesAssigned = (
              <>
                {venue.number_services_available && venue.number_services_available > 0 ? (
                  <span>
                    <span className="text-black font-medium">{venue.number_services_available}</span>
                    {` servicio${venue.number_services_available > 1 ? 's' : ''} asignado${
                      venue.number_services_available > 1 ? 's' : ''
                    }`}
                  </span>
                ) : (
                  <span className="opacity-50">Sin servicios asignados</span>
                )}
              </>
            );

            return (
              <Column
                key={i}
                gap={4}
                className={classNames(
                  'w-full p-4 last:rounded-b-xl first:border-t border-b last:border-b-0 transition-colors duration-150 cursor-pointer hover:bg-[#E9E9E9] text-black',
                  {
                    'bg-[#09D5A1]/10 hover:bg-[#09D5A1]/10 cursor-default': driverMode && staffHasWorkingHours
                  }
                )}
                onClick={() => {
                  if (!driverMode) {
                    setSelectedVendor(venue);
                  } else {
                    if (!staffHasWorkingHours) {
                      setSelectedVendor(venue);
                    }
                  }
                }}
              >
                <div className="flex items-center gap-4 justify-between w-full h-full">
                  <div className="flex flex-col">
                    <p className="text-left text-xl font-bold w-full leading-tight line-clamp-1">{`${venue.user.first_name} ${venue.user.last_name}`}</p>
                    <p className="text-sm text-[#626262] whitespace-nowrap">{servicesAssigned}</p>
                  </div>
                  <div className="flex items-center gap-1">
                    {driverMode ? (
                      staffHasWorkingHours ? (
                        <CheckSuccess />
                      ) : (
                        <WeekDays raw daysIn={days} />
                      )
                    ) : (
                      <WeekDays raw daysIn={days} />
                    )}
                  </div>
                </div>
              </Column>
            );
          })
        )}
      </Column>
      {driverMode && allStaffAreWithWorkingHours && (
        <>
          <div className="hidden sm:block highlight-next-step">
            <FloatButton isCTA={true} />
          </div>
          <div className="highlight-next-step sm:hidden sticky bottom-0 left-0 right-0 bg-gradient-to-t from-white via-white z-50 to-transparent rounded-xl pb-5 px-4 w-full h-full">
            <FloatButton isCTA={true} />
          </div>
        </>
      )}
    </>
  );
};
