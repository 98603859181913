import { SelectProps } from '@/components';
import { ServiceAddress } from '@/components/Input/InputAddress';
import { REGEX_VALID_URL } from '@/constants/regex';
import { CustomQuestionI } from '@/types';
import { ServiceModalityE } from '@/types/cyclone/models';
import * as yup from 'yup';

export { default as serviceLocal } from './service-local.png';
export { default as serviceVirtual } from './service-virtual.png';
export { default as serviceOnDemand } from './service-ondemand.png';
export { default as serviceGroup } from './service-group.png';
export { default as serviceAddress } from './service-address.png';
export { default as serviceCoverageArea } from './service-coverage-area.png';

export interface PackI {
  limit: number;
  price: number;
  price_usd?: number;
}

export enum TabE {
  MAIN = 'main',
  PRICES = 'prices',
  CONTENT = 'content',
  OPTIONS = 'options'
}

export interface EditServiceFormStateI {
  name: string;
  price: number;
  price_usd?: number;
  duration: number;
  participants: number;
  meetLink?: string;
  address?: ServiceAddress;
  details?: string;
  description?: string;
  policy_refund_mins?: number;
  down_payment_amount?: number;
  questions: CustomQuestionI[];
}

export type Image = {
  id: string;
  url?: string;
  blob?: File;
};

export interface QuestionRequestI {
  id?: number;
  question: string;
  is_required: boolean;
  isSave: boolean;
}

export type TabType = {
  label: string;
  value: TabE;
};

export interface FAQItemI {
  name: string;
  description: string;
  order: number;
}

export const MINUTES_OPTIONS: SelectProps[] = [
  { label: '15 minutos', value: '15' },
  { label: '30 minutos', value: '30' },
  { label: '1 hora', value: '60' },
  { label: '2 horas', value: '120' },
  { label: '4 horas', value: '240' },
  { label: '6 horas', value: '360' },
  { label: '8 horas', value: '480' },
  { label: '12 horas', value: '720' },
  { label: '24 horas', value: '1440' },
  { label: '48 horas', value: '2880' },
  { label: '7 dias', value: '10080' }
];

export type PlaceType = { address: string };

const IMAGE_CDN_URL = import.meta.env.VITE_IMAGE_CDN_URL;
export const DEFAULT_AGORA_IMAGE = `${IMAGE_CDN_URL}/defaults/default_cover_image_service.webp`;

const getMeetsSchema = (modality: ServiceModalityE | null, isCoverageArea: boolean) => {
  if (!modality) return null;
  if (modality === ServiceModalityE.VIRTUAL) {
    return {
      meetLink: yup
        .string()
        .test('validate-url', 'Necesita ser una URL válida', (value) =>
          value ? REGEX_VALID_URL.test(value) : true
        )
        .required()
    };
  } else {
    if (isCoverageArea) return null;

    return {
      meetLink: yup.string().notRequired().nullable(true)
    };
  }
};

export const getSchema = (modality: ServiceModalityE | null, isCoverageArea: boolean) =>
  yup.object().shape({
    name: yup.string().required('Campo requerido').min(2, 'Debe contener un mínimo de 2 caracteres'),
    price: yup.number().integer().required('Campo requerido').typeError('Debe ser un numero positivo'),
    participants: yup
      .number()
      .default(1)
      .min(1)
      .integer()
      .required('Campo requerido')
      .typeError('Debe ser un numero positivo'),
    // price_usd: yup.number().default(0).min(0).integer().typeError('Debe ser un numero positivo'),
    duration: yup
      .number()
      .positive()
      .integer()
      .test('is-5-block', 'La duracion debe ser bloques de 5 minutos', (value) => !!value && value % 5 === 0)
      .required('Campo requerido')
      .typeError('Debe ser un numero positivo'),
    ...getMeetsSchema(modality, isCoverageArea)
  });
