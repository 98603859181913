import React from 'react';
import { Row } from '@/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { useAuth } from '@/contexts';

type StatusProps = {
  color: string;
  text: string;
};

type StatusLegendProps = {
  isVenue?: boolean;
};

const Status: React.FC<StatusProps> = ({ color, text }) => {
  return (
    <Row align="center" justify="center" gap={4}>
      <FontAwesomeIcon icon={faCircle} color={color} className="text-[8px] md:text-[9px]" />
      <p className="text-xs md:text-sm pb-0.5 text-[#868686]">{text}</p>
    </Row>
  );
};

const StatusLegendRaw = ({ isVenue = false }) => {
  const { session } = useAuth();

  const buildStatuses = () => {
    const statuses = [];

    statuses.push({
      color: '#7AA9FF80',
      text: 'Disponible'
    });

    if (session?.has_services) {
      statuses.push({
        color: '#0072FB',
        text: 'Turnos personalizados'
      });
    }

    if (session?.has_events) {
      statuses.push({
        color: '#212121',
        text: 'Evento'
      });
    }

    if (session?.has_services) {
      if (!session.is_beauty_category)
        statuses.push({
          color: '#09D5A1',
          text: 'Grupal'
        });
    }

    statuses.push({
      color: '#FFE3E6',
      text: 'Turno cancelado'
    });

    return statuses;
  };
  return (
    <div
      className={classNames({
        'grid grid-cols-2 justify-items-start': isVenue,
        'flex flex-row items-center justify-center gap-4': !isVenue
      })}
    >
      {buildStatuses().map((status, index) => (
        <Status key={index} color={status.color} text={status.text} />
      ))}
    </div>
  );
};

export const StatusLegend = React.memo<StatusLegendProps>(StatusLegendRaw);
