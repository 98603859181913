import React, { FunctionComponent, useRef, useState, useCallback, useEffect } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button, Column, Spinner } from '@/components';
import { theme } from '@/components/App';
import { toast } from 'react-toastify';
import { DialogFooter } from '@/components/Dialog';
import { TypeImageInput } from '../ImageManagerV2';

type ImageCropperProps = {
  image: File;
  onClose: (file: File) => void;
  aspectRatio?: number;
  validExts?: string[];
  type?: TypeImageInput;
};

export const ImageCropper: FunctionComponent<ImageCropperProps> = ({
  image,
  onClose,
  aspectRatio = 1 / 1,
  validExts = ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG'],
  type
}) => {
  const cropperRef = useRef<HTMLImageElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [currentImage, setCurrentImage] = useState<File>(image);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [imageURL, setImageURL] = useState<string>(URL.createObjectURL(image));

  useEffect(() => {
    if (currentImage) {
      const newURL = URL.createObjectURL(currentImage);
      setImageURL(newURL);

      // Liberar la URL anterior para evitar fugas de memoria
      return () => URL.revokeObjectURL(newURL);
    }
  }, [currentImage]);

  const processImage = useCallback(
    (blob: Blob) => {
      const file = new File([blob], currentImage?.name || 'cropped-image.png', {
        type: blob.type,
        lastModified: new Date().getTime()
      });
      onClose(file);
    },
    [currentImage, onClose]
  );

  const onSave = useCallback(async () => {
    try {
      setIsSaving(true);
      const imageElement: any = cropperRef?.current;
      if (!imageElement?.cropper) {
        throw new Error('Cropper not initialized');
      }

      const cropper = imageElement.cropper;
      const canvas = cropper.getCroppedCanvas();

      if (!canvas) {
        throw new Error('Failed to create canvas');
      }

      const blob = await new Promise<Blob>((resolve, reject) => {
        canvas.toBlob(
          (blob: Blob | null) => {
            if (blob) resolve(blob);
            else reject(new Error('Failed to create blob'));
          },
          'image/png',
          0.9
        );
      });

      processImage(blob);
    } catch (error) {
      console.error('Error during save:', error);
      toast.error('Error al guardar la imagen');
    } finally {
      setIsSaving(false);
    }
  }, [processImage]);

  useEffect(() => {
    const handleEnter = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !isSaving) {
        onSave();
      }
    };

    window.addEventListener('keydown', handleEnter);
    return () => window.removeEventListener('keydown', handleEnter);
  }, [onSave, isSaving]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const extension = file.name.split('.').pop();
    if (extension && validExts.includes(extension)) {
      setCurrentImage(file);
    } else {
      toast.error('La imagen debe ser un archivo en formato .jpg, .jpeg o .png');
    }

    e.target.value = '';
  };

  return (
    <>
      {type === TypeImageInput.AVATAR && (
        <style>
          {`
          .cropper-view-box {
            border-radius: 50%
          }
          `}
        </style>
      )}
      <Column align="center" gap={16} justify="space-between" className="w-full">
        <div className="relative w-full">
          <Cropper
            src={imageURL} // Ahora usa la URL generada dinámicamente
            style={{
              height: '60vh',
              width: '100%',
              margin: 'auto'
            }}
            aspectRatio={aspectRatio}
            autoCropArea={aspectRatio}
            guides={true}
            ref={cropperRef}
            checkCrossOrigin={false}
          />
          {isSaving && (
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <div className="bg-white p-4 rounded-lg flex items-center gap-3">
                <Spinner size={20} background={theme.colors.grey[400]} />
                <span className="text-sm">Procesando imagen...</span>
              </div>
            </div>
          )}
        </div>
        <DialogFooter className="w-full gap-4">
          <Button
            fullWidth
            size="md"
            variant="outlined"
            onClick={() => inputRef.current?.click()}
            disabled={isSaving}
          >
            Usar otra
            <input
              className="hidden"
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
              accept={validExts.map((ext) => `.${ext}`).join(',')}
            />
          </Button>
          <Button fullWidth size="md" onClick={onSave} disabled={isSaving}>
            {isSaving ? 'Guardando...' : 'Guardar'}
          </Button>
        </DialogFooter>
      </Column>
    </>
  );
};
