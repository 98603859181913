import React from 'react';
import { Column, Row, SelectProps } from '@/components';
import { useClient, useWindowResize } from '@/hooks';
import { Switch } from '@headlessui/react';
import { Card, Layout } from '../components';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/SelectV3';
import classNames from 'classnames';
import { useMutation, useQuery } from 'react-query';
import { ErrorI, GetVendorInfoI, PostVendorInfoI } from '@/types/cyclone/requests';
import { toast } from 'react-toastify';
import useLog from '@/hooks/useLog';

export const MINUTES_OPTIONS: SelectProps[] = [
  { label: '15 minutos', value: '15' },
  { label: '30 minutos', value: '30' },
  { label: '1 hora', value: '60' },
  { label: '2 horas', value: '120' },
  { label: '4 horas', value: '240' },
  { label: '6 horas', value: '360' },
  { label: '8 horas', value: '480' },
  { label: '12 horas', value: '720' },
  { label: '24 horas', value: '1440' },
  { label: '48 horas', value: '2880' },
  { label: '7 días', value: '10080' }
];

export const AdjustPolicies: React.FC = () => {
  const { isMobileSize } = useWindowResize();
  const { client } = useClient();
  const { logAndNotify } = useLog();

  const [toggleCancellationPolicy, setToggleCancellationPolicy] = React.useState<boolean>(false);
  const [toggleRefundAvailable, setToggleRefundAvailable] = React.useState<boolean>(false);
  const [selectedCancellation, setSelectedCancellation] = React.useState<number>(0);

  const onSelectionCancellationChange = (value: string) => {
    setSelectedCancellation(+value);
  };

  const {
    data: user,
    refetch,
    isLoading,
    isFetching
  } = useQuery(
    ['me', 'info'],
    async () =>
      await client<GetVendorInfoI>(`me/vendor/info`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const handleOnChangeToggleCancellationPolicy = (value: boolean) => {
    setToggleCancellationPolicy(value);

    if (value) {
      if (selectedCancellation === 0) {
        setSelectedCancellation(15);
      }
    } else {
      setSelectedCancellation(0);
    }
  };

  React.useEffect(() => {
    if (user) {
      //case for null values
      if (user.vendor.is_refund_policies_enabled === null) {
        setToggleCancellationPolicy(false);
      } else {
        setToggleCancellationPolicy(user.vendor.is_refund_policies_enabled);
      }
      //case for null values
      if (user.vendor.is_refund_available === null) {
        setToggleRefundAvailable(false);
      } else {
        setToggleRefundAvailable(user.vendor.is_refund_available);
      }
      setSelectedCancellation(user.vendor.policy_refund_mins || 0);
    }
  }, [user]);

  const mutation = useMutation<Partial<PostVendorInfoI>, ErrorI>(
    (vendor) =>
      client(`me/vendor/info`, 'PUT', {
        isAuthRequired: true,
        data: vendor
      }),
    {
      onSuccess: () => {
        toast.success('Cambios guardados.');
        refetch();
      },
      onError: (error) => {
        logAndNotify(
          `Error al guardar la política de cancelaciones y devoluciones globales, the data is ${JSON.stringify(
            {
              is_refund_policies_enabled: toggleCancellationPolicy,
              is_refund_available: toggleRefundAvailable,
              policy_refund_mins: selectedCancellation
            }
          )} and the error is ${error}`
        );
        toast.error('Algo anda mal. Por favor, contactar a soporte.');
      }
    }
  );

  const handleSave = () => {
    mutation.mutate({
      is_refund_policies_enabled: toggleCancellationPolicy,
      is_refund_available: toggleRefundAvailable,
      policy_refund_mins: selectedCancellation
    } as any);
  };

  return (
    <Layout
      title="Cancelaciones"
      onSave={() => handleSave()}
      isLoading={isLoading || isFetching || mutation.isLoading}
      subtitle="Configurar cancelaciones y devoluciones"
    >
      <div className="flex w-full flex-col mt-4 max-w-[700px]">
        <Row align="center" gap={20} className="mb-2">
          <span className="text-xl font-medium">¿Permitir cancelaciones?</span>
          <Switch
            checked={toggleCancellationPolicy}
            onChange={handleOnChangeToggleCancellationPolicy}
            className={`${
              toggleCancellationPolicy ? 'bg-[#7FB8FF]' : 'bg-[#CACACA]'
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span
              className={`${
                toggleCancellationPolicy ? 'translate-x-6 bg-[#0072FB]' : 'translate-x-1 bg-white'
              } inline-block h-4 w-4 transform rounded-full transition`}
            />
          </Switch>
        </Row>
        <Card disabled={!toggleCancellationPolicy}>
          <Column gap={20} className="w-full">
            <span className="font-normal text-sm text-[#858585]">
              Tus clientes podrán cancelar sus reservas. Si ya abonaron una seña, la misma no será
              reembolsada.
            </span>
            <p className="font-normal text-sm text-[#858585]">Aceptar cancelaciones hasta:</p>
            <Row align="center" gap={16} className="w-full">
              <Select
                onValueChange={(value) => onSelectionCancellationChange(value)}
                defaultValue={selectedCancellation.toString()}
                value={selectedCancellation.toString()}
              >
                <SelectTrigger className="border border-solid border-[#0000005c] bg-white !text-base max-w-[150px]">
                  <SelectValue className="w-full" placeholder="Selecciona una opción" />
                </SelectTrigger>
                <SelectContent className="!max-h-[150px] !text-base">
                  {MINUTES_OPTIONS.map((option, i) => (
                    <SelectItem
                      className={classNames({
                        'bg-[#0072FB] text-white': option.value === selectedCancellation.toString(),
                        'hover:bg-gray-100': option.value !== selectedCancellation.toString()
                      })}
                      key={i}
                      value={option.value as string}
                    >
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <span className="font-normal text-sm text-[#858585]">
                {isMobileSize ? 'antes' : 'antes del turno'}
              </span>
            </Row>
          </Column>
        </Card>

        <Row align="center" gap={20} className="mt-8 mb-2">
          <span className="text-lg font-medium">Devolución por cancelación del negocio</span>
          <Switch
            checked={toggleRefundAvailable}
            onChange={setToggleRefundAvailable}
            className={`${
              toggleRefundAvailable ? 'bg-[#7FB8FF]' : 'bg-[#CACACA]'
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span
              className={`${
                toggleRefundAvailable ? 'translate-x-6 bg-[#0072FB]' : 'translate-x-1 bg-white'
              } inline-block h-4 w-4 transform rounded-full transition`}
            />
          </Switch>
        </Row>
        <Card disabled={!toggleRefundAvailable}>
          <span className="font-normal text-sm text-[#858585] w-full">
            Ante cancelaciones por parte del negocio, se realizará una devolución de su pago al cliente.
          </span>
        </Card>
      </div>
    </Layout>
  );
};

export default AdjustPolicies;
