import { trackGenericEvent } from '@/analytics';
import { authorize, logIn, startPasswordless, verifyPasswordlessCode } from '@/auth';
import { Input } from '@/components';
import { Button } from '@/components/Button/ButtonV2';
import { InputV2 } from '@/components/Input';
import { InputOTP, InputOTPGroup, InputOTPSlot } from '@/components/Input/InputOTP';
import { useClient } from '@/hooks';
import Google from '@/static/icons/google';
import { ErrorI } from '@/types/cyclone/requests';
import { isValidEmailDomain } from '@/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { REGEXP_ONLY_DIGITS } from 'input-otp';
import { useState } from 'react';
import { Controller, EmptyObject, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as yup from 'yup';

const handleKeyDown = (e: React.KeyboardEvent) => {
  if (e.key === 'Enter') {
    e.preventDefault();
  }
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Mail inválido')
    .test('is-valid-suffix', 'El correo electrónico tiene un sufijo inválido', function (value) {
      return isValidEmailDomain(value);
    })
    .required('Campo requerido'),
  password: yup.string().min(8, 'Al menos 8 caracteres').required('Campo requerido')
});

const passwordlessSchema = yup.object().shape({
  email: yup
    .string()
    .email('Mail inválido')
    .test('is-valid-suffix', 'El correo electrónico tiene un sufijo inválido', function (value) {
      return isValidEmailDomain(value);
    })
    .required('Campo requerido')
});

type SignInProps = {
  navigateToForgotPassword: () => void;
};

export const SignIn: React.FC<SignInProps> = ({ navigateToForgotPassword }) => {
  const { client } = useClient();
  const [loading, setLoading] = useState(false);
  const [emailSelected, setEmailSelected] = useState<string | undefined>(undefined);
  const [isPasswordless, setIsPasswordless] = useState(false);
  const [codeSent, setCodeSent] = useState(false);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    getValues
  } = useForm({
    resolver: yupResolver(isPasswordless ? passwordlessSchema : schema),
    mode: 'all'
  });

  const errorHandler = (err: auth0.Auth0Error | null) => {
    setLoading(false);
    if (err && !isPasswordless) {
      toast.error('La contraseña es incorrecta, por favor inténtalo nuevamente.');
    } else if (err && isPasswordless) {
      console.log('err', err);
      toast.error('El código es incorrecto, por favor inténtalo nuevamente.');
    }
  };

  const mutationValidateEmail = useMutation<EmptyObject, ErrorI, { data: { email: string } }>(
    (data) => client(`users/change_password/validate`, 'POST', { data: { email: data.data.email } }),
    {
      onSuccess: () => {
        setEmailSelected(getValues('email'));
      },
      onError: (error) => {
        setLoading(false);
        if (error.code === 'AUTH0-004') {
          toast.error('El email ingresado no se encuentra asociado a ninguna cuenta.');
        } else if (error.code === 'AUTH0-005') {
          toast.error('Este email no se encuentra asociado a una cuenta en Ágora.');
        } else if (error.code === 'AUTH0-006') {
          authorize('google-oauth2');
        } else {
          toast.error('Algo anda mal. Por favor, contactar a soporte.');
        }
      }
    }
  );

  const onSubmit = async (data: any) => {
    setLoading(true);
    if (isPasswordless) {
      if (!codeSent) {
        startPasswordless(
          data.email,
          (err) => {
            setLoading(false);
            if (err) {
              toast.error('Error al enviar el código. Por favor, intente nuevamente.');
            }
          },
          () => {
            setLoading(false);
            setCodeSent(true);
            toast.success('Código enviado! Revisa tu email.');
          }
        );
      } else {
        verifyPasswordlessCode(data.email, data.code, errorHandler);
      }
    } else {
      trackGenericEvent('Button Sign In Clicked');
      logIn(data.email, data.password, errorHandler);
    }
  };

  const handleEmailSelected = (email: string) => {
    mutationValidateEmail.mutate({ data: { email } });
  };

  return (
    <>
      <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col items-center gap-2 text-center">
          <h1 className="text-2xl font-bold">Inicia sesión en Ágora</h1>
          <p className="text-pretty text-sm text-muted-foreground">
            {isPasswordless ? (
              <>
                Introduce el código que te enviamos a<span className="font-medium"> {emailSelected}</span>
              </>
            ) : emailSelected ? (
              <>
                Introduce tu contraseña para iniciar sesión como
                <span className="font-medium"> {emailSelected}. </span>
                <span
                  className="ml-auto underline-offset-4 hover:underline cursor-pointer text-[#0072FF] text-sm"
                  role="button"
                  tabIndex={0}
                  onClick={() => setEmailSelected(undefined)}
                  onKeyDown={handleKeyDown}
                >
                  Cambiar email.
                </span>
              </>
            ) : (
              'Ingresa tu email para acceder a tu cuenta'
            )}
          </p>
        </div>
        <div className="gap-4">
          {emailSelected ? (
            <>
              <div className="grid gap-2">
                {!isPasswordless ? (
                  <>
                    <div className="flex items-center">
                      <label htmlFor="password">Contraseña</label>
                      <span
                        className="ml-auto underline-offset-4 hover:underline cursor-pointer text-[#0072FF] text-sm"
                        role="button"
                        tabIndex={0}
                        onClick={navigateToForgotPassword}
                        onKeyDown={handleKeyDown}
                      >
                        ¿Olvidaste tu contraseña?
                      </span>
                    </div>
                    <Controller
                      name="password"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange } }) => (
                        <Input
                          onChange={onChange}
                          inputId="password"
                          placeholderInput="Contraseña"
                          variant="password"
                          error={errors?.password?.message as string}
                        />
                      )}
                    />
                  </>
                ) : codeSent ? (
                  <>
                    <div className="flex flex-col gap-2">
                      <label htmlFor="code">Código de verificación</label>
                      <Controller
                        name="code"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <InputOTP
                            pattern={REGEXP_ONLY_DIGITS}
                            value={value}
                            onChange={onChange}
                            maxLength={6}
                          >
                            <InputOTPGroup>
                              <InputOTPSlot index={0} />
                              <InputOTPSlot index={1} />
                              <InputOTPSlot index={2} />
                              <InputOTPSlot index={3} />
                              <InputOTPSlot index={4} />
                              <InputOTPSlot index={5} />
                            </InputOTPGroup>
                          </InputOTP>
                        )}
                      />
                    </div>
                  </>
                ) : null}
              </div>
              <Button
                disabled={!isValid || loading}
                isLoading={loading}
                type="submit"
                className="w-full mt-4"
              >
                {loading ? 'Cargando...' : codeSent ? 'Verificar código' : 'Ingresar'}
              </Button>
              <Button
                variant="link"
                onClick={() => {
                  setIsPasswordless(!isPasswordless);
                  setCodeSent(false);
                }}
                className="text-[#0072FF] underline !font-normal w-full"
              >
                {isPasswordless ? 'Usar contraseña' : 'Usar código por email'}
              </Button>
            </>
          ) : (
            <div className="flex flex-col gap-4">
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <InputV2
                    id="email"
                    type="email"
                    value={value || ''}
                    placeholder="Introduce tu email"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const formattedValue = e.target.value.trim().toLowerCase();
                      onChange(formattedValue);
                    }}
                    error={errors?.email?.message as string}
                    {...field}
                  />
                )}
              />
              <Button
                variant="default"
                className="w-full relative flex items-center !border-blue"
                isLoading={mutationValidateEmail.isLoading}
                onClick={() => handleEmailSelected(getValues('email'))}
              >
                <span className="p-3">Continuar</span>
              </Button>
            </div>
          )}
        </div>
      </form>
      {!emailSelected && (
        <>
          <div className="relative flex items-center justify-center py-4">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t" />
            </div>
            <span className="relative rounded-full bg-[#FBFBFB] sm:bg-white px-2 text-[#626262]">o</span>
          </div>
          <Button
            variant="outline"
            className="w-full h-10 relative !border-blue"
            onClick={() => authorize('google-oauth2')}
          >
            <div className="absolute left-3 flex items-center justify-center blue-border">
              <Google className="w-5 h-5" />
            </div>
            <span className="mx-auto">Ingresar con Google</span>
          </Button>{' '}
        </>
      )}
    </>
  );
};
