import { Column, Row, SelectProps } from '@/components';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/SelectV3';
import { VendorI } from '@/types/cyclone/models';
import classNames from 'classnames';
import { BUFFER_OPTIONS, ScheduleI, SkeletonRow, WorkingHourState } from '../utils';
import { Button } from '@/components/Button/ButtonV2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useAuth } from '@/contexts';
import FloatButton from './FloatButton';

interface WorkingHoursPropsI {
  workingHoursState: WorkingHourState[];
  bufferTime: SelectProps;
  setBufferTime: React.Dispatch<React.SetStateAction<SelectProps>>;
  onFrequencyChange: (value: string) => void;
  isLoading: boolean;
  handleOnClick: (day: number, schedules: ScheduleI[]) => void;
  handleNextStep: () => void;
  selectedVendor?: VendorI;
  isVenue?: boolean;
}

export const WorkingHours: React.FC<WorkingHoursPropsI> = ({
  workingHoursState,
  selectedVendor,
  isVenue,
  bufferTime,
  setBufferTime,
  onFrequencyChange,
  isLoading,
  handleOnClick,
  handleNextStep
}) => {
  const { session } = useAuth();

  const isFirstServiceCreated = session?.vendor?.vendor_activity_tracking?.first_service_at;
  const isScheduleConfigured = session?.vendor?.vendor_activity_tracking?.first_availability_at;
  const isCTA: boolean = isScheduleConfigured && !isFirstServiceCreated ? true : false;
  const driverMode = new URLSearchParams(window.location.search).get('driver') === 'true';

  React.useLayoutEffect(() => {
    if (isVenue) {
      if (selectedVendor?.vendor_working_hours_start_frequency) {
        setBufferTime(
          BUFFER_OPTIONS.find(
            (option) => option.value === selectedVendor?.vendor_working_hours_start_frequency?.toString()
          )!
        );
      }
    } else {
      if (session?.vendor!.vendor_working_hours_start_frequency) {
        setBufferTime(
          BUFFER_OPTIONS.find(
            (option) => option.value === session?.vendor!.vendor_working_hours_start_frequency?.toString()
          )!
        );
      }
    }
  }, [selectedVendor, session]);

  return (
    <div className="flex sm:flex-row gap-4 flex-col w-full">
      <Column gap={10} className="w-full sm:w-[60%] border rounded-xl bg-white p-4">
        <p className="font-medium text-base truncate">
          {isVenue
            ? `Horarios de ${selectedVendor?.user.first_name} ${selectedVendor?.user.last_name}`
            : 'Horarios de trabajo'}
        </p>
        <p className="text-[#626262] text-sm">
          {isVenue
            ? `Indica los horarios de ${selectedVendor?.user.first_name}. Los mismos se repetirán semanalmente.`
            : 'Indica los horarios disponibles para tus servicios personalizados. Los mismos se repiten semanalmente.'}
          <button
            className="text-blue underline text-sm ml-1"
            onClick={() => {
              handleNextStep();
            }}
          >
            Agregar horarios
          </button>
        </p>
        <Column gap={4} className="text-sm h-full w-full pt-4">
          {isLoading ? (
            <SkeletonRow />
          ) : (
            workingHoursState.map((day) => (
              <div
                key={day.value}
                className="last:border-none border-b border-[#DADCE0] mb-4 pb-4 last:mb-0 last:pb-0"
              >
                <Row align="baseline" justify="space-between" className="mb-4">
                  <p className="font-medium">{day.label}</p>
                  {day.schedules && day.schedules.length > 0 ? (
                    <Button
                      variant="outline"
                      size="icon"
                      rounded="full"
                      onClick={() => handleOnClick(day.value, day.schedules)}
                    >
                      <FontAwesomeIcon
                        onClick={() => handleOnClick(day.value, day.schedules)}
                        icon={faPencilAlt}
                        color="#0072FB"
                      />
                    </Button>
                  ) : (
                    <Button
                      variant="outline"
                      size="icon"
                      rounded="full"
                      onClick={() => handleOnClick(day.value, day.schedules)}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        color="#0072FB"
                        onClick={() => handleOnClick(day.value, day.schedules)}
                      />
                    </Button>
                  )}
                </Row>
                {day.schedules.length > 0 ? (
                  day.schedules.map((schedule, i) => (
                    <div
                      key={i}
                      className="flex items-center justify-center gap-2 rounded bg-[#0072FB]/20 text-blue p-2 font-medium mb-2 last:mb-0"
                    >
                      <p className="text-sm">{`${schedule.start_time} - ${schedule.end_time}`}</p>
                    </div>
                  ))
                ) : (
                  <div className="flex items-center justify-center gap-2 rounded bg-gray-100 text-gray-300 p-2">
                    <p>No hay horarios para este día</p>
                  </div>
                )}
              </div>
            ))
          )}
        </Column>
      </Column>
      <Column gap={16} className="w-full sm:w-[40%] h-full">
        <Column
          gap={10}
          className={classNames('border rounded-xl bg-white p-4 h-fit', {
            'opacity-20 cursor-default pointer-events-none': driverMode
          })}
        >
          <p className="font-medium text-base">Frecuencia de inicio de turnos</p>
          <p className="text-[#626262] text-sm">
            Por ej., con frecuencia de 45 minutos, pueden reservarse turnos a las 9:00, 9:45, 10:30, etc.
          </p>
          <Select onValueChange={(value) => onFrequencyChange(value)} value={bufferTime.value as string}>
            <SelectTrigger className="w-full border border-[#E9E9E9] max-w-[250px] mt-2">
              <SelectValue placeholder="Seleccioná una opción" />
            </SelectTrigger>
            <SelectContent className="max-h-[200px] h-full">
              {BUFFER_OPTIONS.map((option, i) => (
                <SelectItem
                  className={classNames({
                    'bg-[#0072FB] text-white': option.value === bufferTime.value,
                    'hover:bg-gray-100': option.value !== bufferTime.value
                  })}
                  key={i}
                  value={option.value as string}
                >
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </Column>
        <div className="hidden sm:block">
          <FloatButton isCTA={isCTA} />
        </div>
      </Column>
      <div className="sm:hidden sticky bottom-0 left-0 right-0 bg-gradient-to-t from-white via-white z-50 to-transparent rounded-xl pb-5 px-4 w-full h-full">
        <FloatButton isCTA={isCTA} />
      </div>
    </div>
  );
};
