import { SelectProps } from '@/components';

export interface WorkingHourState {
  label: string;
  value: number;
  schedules: {
    start_time: string;
    end_time: string;
  }[];
}

export const initialWorkingHoursState: WorkingHourState[] = [
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
  'Domingo'
].map((label, index) => ({
  label,
  value: index + 1,
  isSelected: false,
  schedules: []
}));

export const BUFFER_OPTIONS: SelectProps[] = [
  {
    label: '5 minutos',
    value: '5'
  },
  {
    label: '10 minutos',
    value: '10'
  },
  {
    label: '15 minutos',
    value: '15'
  },
  {
    label: '20 minutos',
    value: '20'
  },
  {
    label: '30 minutos',
    value: '30'
  },
  {
    label: '40 minutos',
    value: '40'
  },
  {
    label: '45 minutos',
    value: '45'
  },
  {
    label: '60 minutos',
    value: '60'
  },
  {
    label: '75 minutos',
    value: '75'
  },
  {
    label: '90 minutos',
    value: '90'
  },
  {
    label: '120 minutos',
    value: '120'
  },
  {
    label: '150 minutos',
    value: '150'
  },
  {
    label: '180 minutos',
    value: '180'
  }
];

export interface ScheduleI {
  start_time: string;
  end_time: string;
}

export const SkeletonRow: React.FC = () => (
  <>
    <div className="flex items-center justify-center border-b border-[#DADCE0] p-3">
      <div className="bg-gray-200 h-6 w-full animate-pulse" />
    </div>
    <div className="flex items-center justify-center border-b border-[#DADCE0] p-3">
      <div className="bg-gray-200 h-6 w-full animate-pulse" />
    </div>
    <div className="flex items-center justify-center border-b border-[#DADCE0] p-3">
      <div className="bg-gray-200 h-6 w-full animate-pulse" />
    </div>
  </>
);
