import { Column, ResponsiveContainer, Row, Spinner } from '@/components';
import React, { ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { InputUnit } from '@/components/Input';
import { useAuth, useConfirmation } from '@/contexts';
import { Link, useNavigate } from 'react-router-dom';
import { useClient } from '@/hooks';
import { OfflinePaymentTypeE, WalletTypeE } from '@/types/cyclone/models';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { EmptyObject, ErrorI, PostOfflinePaymentsI } from '@/types/cyclone/requests';
import { useMixpanelTrackPage } from '@/analytics/hooks';
import { trackGenericEvent } from '@/analytics';
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor';
import { Button } from '@/components/Button/ButtonV2';

interface InstructionsProps {
  className?: string;
  children: React.ReactNode;
  title?: string;
}

const InstructionsCard: React.FC<InstructionsProps> = ({ className = '', children, title }) => (
  <article className={`w-full flex gap-4 ${className}`}>
    <Column className="w-full justify-start">
      {title && <h2 className="font-medium sm:border-b sm:p-4 sm:block hidden">{title}</h2>}
      {children}
    </Column>
  </article>
);

export const Cash: React.FC = () => {
  useMixpanelTrackPage('Payment Gateways Cash');
  const { client } = useClient();
  const { confirm } = useConfirmation();
  const navigate = useNavigate();
  const { session, reloadSession } = useAuth();

  const paymentGateways = session?.vendor?.payment_gateways || [];
  const isWalletAssociated = paymentGateways.includes(WalletTypeE.MERCADO_PAGO);

  const isCashEnabled = session?.vendor?.is_payment_cash_enabled;
  const enabledDiscount = session?.vendor?.vendor_activity_tracking?.first_basic_storefront_at !== null;

  const isLastPMMethod = !isWalletAssociated && !session?.vendor?.is_payment_cash_enabled;

  const offlinePayment = session?.vendor?.offline_payments?.find(
    (pmt) => pmt.type === OfflinePaymentTypeE.CASH
  );

  const [description, setDescription] = React.useState(offlinePayment?.description);
  const [errors, setErrors] = React.useState({ description: false, discount: false });
  const [discount, setDiscount] = React.useState(
    offlinePayment?.discount ? (offlinePayment.discount * 100).toString() : '0'
  );

  React.useEffect(() => {
    const timer = setTimeout(() => setErrors({ description: false, discount: false }), 7000);
    return () => clearTimeout(timer);
  }, [errors]);

  React.useEffect(() => {
    const inputEl = document.getElementById('cash-rich-text-input');
    inputEl?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, []);

  const mutationPost = useMutation<EmptyObject, ErrorI, PostOfflinePaymentsI>(
    (data) => client<EmptyObject>('wallet/offline_payments', 'POST', { isAuthRequired: true, data }),
    {
      onSuccess: () => {
        toast.success('Medios de pago actualizados');
        navigate('/ajustes/medios-de-pago');
        reloadSession();
      }
    }
  );

  const validateSubmission = () => {
    const floatDiscount = parseFloat(discount) / 100;
    if (+discount < 0 || +discount > 30) {
      setErrors((prev) => ({ ...prev, discount: true }));
      toast.error('El descuento debe ser un número positivo y menor a 31');
      return false;
    }
    if (!description) {
      setErrors((prev) => ({ ...prev, description: true }));
      toast.error('Debes ingresar tus instrucciones de pago para poder Guardar');
      return false;
    }
    return floatDiscount;
  };

  const handleSubmit = () => {
    const floatDiscount = validateSubmission();
    if (floatDiscount === false) return;

    const prevOfflinePayments = session?.vendor?.offline_payments;
    const request: PostOfflinePaymentsI = {
      offline_payments: [
        ...(prevOfflinePayments?.filter((pmt) => pmt.type !== OfflinePaymentTypeE.CASH) || []),
        {
          description,
          discount: floatDiscount,
          type: OfflinePaymentTypeE.CASH
        }
      ]
    };
    mutationPost.mutate(request);
    trackGenericEvent('Button Payment Method Clicked', {
      type: 'cash',
      enabled: isCashEnabled
    });
  };

  const handleDisable = () => {
    const updateOfflinePayments = session?.vendor?.offline_payments?.filter(
      (pmt) => pmt.type !== OfflinePaymentTypeE.CASH
    );
    if (isLastPMMethod) {
      confirm({
        status: 'danger',
        content: '¿Estás seguro/a que deseas deshabilitar Efectivo?',
        message:
          'Tus servicios no se mostraran en tu pagina de ventas hasta que no hayas vinculado otro método de pago.',
        title: 'Deshabilitar método de pago',
        confirmText: 'Si, deshabilitar',
        onConfirm: () => mutationPost.mutate({ offline_payments: updateOfflinePayments })
      });
    } else {
      mutationPost.mutate({ offline_payments: updateOfflinePayments });
    }
  };

  const renderDiscountSection = () => (
    <article className="w-full flex flex-col gap-4 border-b-2 sm:border-b-0 pb-6 sm:shadow-[0px_1px_4px_0px_#00000040] sm:p-4 rounded">
      <h2 className="font-medium">Descuento para pagos en efectivo</h2>
      <div className="w-1/2">
        <InputUnit
          pattern="^\d*\.?\d*$"
          inputId="discount"
          unit="%"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setDiscount(e.target.value)}
          value={discount}
          error={errors.discount ? ' ' : ''}
        />
      </div>
    </article>
  );

  const renderActionButtons = (className: string = '') => (
    <article className={`h-fit gap-4 flex items-center bg-[#fbfbfb]/90 p-4 ${className}`}>
      <Button
        size="lg"
        isLoading={mutationPost.isLoading}
        onClick={handleSubmit}
        className="w-full !rounded-full !text-base"
      >
        {isCashEnabled ? 'Guardar' : 'Habilitar'}
      </Button>
      {isCashEnabled && (
        <Button
          size="lg"
          onClick={handleDisable}
          variant={className.includes('sm:hidden') ? 'destructive' : 'link'}
          className={`w-full !rounded-full !text-base ${
            !className.includes('sm:hidden') ? 'text-red-500' : ''
          }`}
        >
          Deshabilitar
        </Button>
      )}
    </article>
  );

  if (!session) return <Spinner />;

  return (
    <>
      <header className="sm:hidden block sticky top-0 left-0 h-[48px] right-0 bottom-0 border-b z-50 bg-[#FAFAFA]">
        <ResponsiveContainer>
          <div className="relative flex items-center justify-center w-full h-[48px]">
            <p className="font-medium">Habilitar pagos en efectivo</p>
            <Link to="/ajustes/medios-de-pago" className="absolute right-0">
              <FontAwesomeIcon icon={faXmarkCircle} color="#868686" size="lg" />
            </Link>
          </div>
        </ResponsiveContainer>
      </header>

      <ResponsiveContainer>
        <section className="my-2">
          <Row align="center" justify="space-between" className="hidden sm:flex mb-4 pb-4">
            <Column>
              <Row align="baseline" gap={10}>
                <Link to="/ajustes/medios-de-pago" className="text-xl sm:text-3xl">
                  Medios de pago
                </Link>
                <FontAwesomeIcon icon={faChevronRight} size="xs" />
                <p className="text-xl sm:text-3xl">Efectivo</p>
              </Row>
              <p className="text-sm text-[#828282]">
                Gestioná los medios de pago aceptados en tu negocio.{' '}
                <Link
                  to="https://ayuda.agora.red/es/articles/7920323-como-te-pagan-tus-clientes"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => trackGenericEvent('Button Help Center Clicked')}
                  className="cursor-pointer text-blue"
                >
                  Más info.
                </Link>
              </p>
            </Column>
            {renderActionButtons('hidden h-fit w-full sm:w-[25%] sm:flex')}
          </Row>

          <Column gap={16} className="w-full">
            <div className="flex sm:flex-row flex-col gap-4 w-full">
              <InstructionsCard
                className="sm:w-[70%] sm:shadow-[0px_1px_4px_0px_#00000040] rounded"
                title="Instrucciones para habilitar cobros en efectivo"
              >
                <ol className="text-sm mx-4 sm:p-4 space-y-2 list-decimal list-outside min-h-[calc(240px-57px)]">
                  <li>
                    Explica a tus clientes cómo pagarte completando las <b>instrucciones de pago</b> a
                    continuación
                  </li>
                  <li>
                    Opcionalmente, podés ofrecer un <b>descuento</b> para cobros en efectivo
                  </li>
                  <li>Haz click en el botón {'"Habilitar"'}</li>
                  <li>
                    Tus clientes podrán ingresar reservas sin pagar por anticipado. Podrás confirmar la
                    recepción del pago desde {'"Actividad"'}
                  </li>
                </ol>
              </InstructionsCard>

              <div className="hidden sm:flex flex-col gap-4 sm:w-[30%]">
                {enabledDiscount && renderDiscountSection()}
                <article className="w-full h-1/2 flex flex-col p-4 gap-4 shadow-[0px_1px_4px_0px_#00000040] rounded">
                  <h2 className="font-medium">Comisiones</h2>
                  <ul className="text-sm mx-4 space-y-2 list-disc list-outside">
                    <li>
                      Los cobros en efectivo <b>no tienen comisión</b>
                    </li>
                  </ul>
                </article>
              </div>
            </div>

            <div className="flex sm:flex-row flex-col gap-4 w-full">
              <InstructionsCard className="sm:w-[calc(70%-10px)] sm:shadow-[0px_1px_4px_0px_#00000040] border-y-2 sm:border-y-0 py-6 sm:py-0 rounded">
                <Column gap={16} className="w-full sm:p-4">
                  <h2 className="font-medium">Instrucciones de pago - Efectivo</h2>
                  <p className="text-sm">
                    Tus clientes verán estas instrucciones a la hora de ingresar una reserva
                  </p>
                  <RichTextEditor
                    id="cash-rich-text-input"
                    content={description || ''}
                    onChange={setDescription}
                    placeholder="Agrega instrucciones"
                    limit={1000}
                    heigth="min-h-[150px]"
                    error={errors.description}
                  />
                </Column>
              </InstructionsCard>

              <div className="sm:hidden flex flex-col w-full">
                {enabledDiscount && renderDiscountSection()}
                <article className="w-full h-1/2 flex flex-col gap-4 sm:pt-6 py-6">
                  <h2 className="font-medium">Comisiones</h2>
                  <ul className="text-sm mx-4 space-y-2 list-disc list-outside">
                    <li>
                      Los cobros en efectivo <b>no tienen comisión</b>
                    </li>
                  </ul>
                </article>
              </div>

              {renderActionButtons(
                'z-50 sticky sm:hidden bottom-10 left-0 right-0 shadow-[0px_0px_10px_5px_#fbfbfb]'
              )}
            </div>
          </Column>
        </section>
      </ResponsiveContainer>
    </>
  );
};
