import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from '@/components';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/_tailwind.css';

const root = document.getElementById('root') as HTMLElement;

createRoot(root).render(<App />);
