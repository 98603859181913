import React, { FunctionComponent, useEffect } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { GlobalProviders } from '@/contexts';
import { MetaTagsSEO, Router } from '..';
import TagManager from 'react-gtm-module';
import { GlobalStyles } from '.';
import { ErrorBoundary } from './GlobalErrorBoundary';

// Initialize Google Tag Manager on production environment only
if (import.meta.env.VITE_NODE_ENV == 'production') {
  const tagManagerArgs = {
    gtmId: 'GTM-5W6HFVH'
  };

  TagManager.initialize(tagManagerArgs);
  /*   Sentry.init({
    dsn: 'https://eb2c3b0df0b19a981d9aaea1e1f1f844@o4507544943394816.ingest.us.sentry.io/4507696894443520',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Profiling
    profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  }); */
}

const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('—app-height', `${window.innerHeight}px`);
};
window.addEventListener('resize', appHeight);
appHeight();

// force push
export const App: FunctionComponent = () => {
  // Add this to your main App.js or index.js file
  useEffect(() => {
    const handleChunkLoadError = (event: any) => {
      // Check if the error is related to chunk loading
      if (
        event.message?.includes('Failed to fetch dynamically imported module') ||
        event.message?.includes('Importing a module script failed') ||
        event.message?.includes('ChunkLoadError') ||
        event.message?.includes('Loading chunk')
      ) {
        console.error('Detected chunk load error, refreshing page:', event);
        window.location.reload();
      }
    };

    // Listen for errors
    window.addEventListener('error', handleChunkLoadError);
    window.addEventListener('unhandledrejection', (event) => handleChunkLoadError(event.reason));

    // For Vite 4.4+ you can use the dedicated event
    window.addEventListener('vite:preloadError', (event) => {
      console.error('Vite preload error, refreshing page:', event);
      window.location.reload();
    });

    return () => {
      window.removeEventListener('error', handleChunkLoadError);
      window.removeEventListener('unhandledrejection', (event) => handleChunkLoadError(event.reason));
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      window.removeEventListener('vite:preloadError', (_event: any) => {
        window.location.reload();
      });
    };
  }, []);

  return (
    <GlobalProviders>
      <MetaTagsSEO title="Ágora | Red de profesionales únicos" />
      <ErrorBoundary>
        <Router />
        <GlobalStyles />
        <ReactQueryDevtools initialIsOpen={false} />
      </ErrorBoundary>
    </GlobalProviders>
  );
};
