import { Column, Row } from '@/components';
import { VendorI } from '@/types/cyclone/models';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { initialWorkingHoursState, ScheduleI } from '../utils';
import { useWindowResize } from '@/hooks';
import { InputTimePicker } from '@/components/Input';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import React from 'react';
import { Button } from '@/components/Button/ButtonV2';

interface EditWorkingHoursPropsI {
  selectedVendor?: VendorI;
  isVenue?: boolean;
  isEdit?: boolean;
  days: number[];
  schedulesSelected: ScheduleI[];
  setDays: (days: number[]) => void;
  setSchedulesSelected: (schedules: ScheduleI[]) => void;
}

export const EditWorkingHours: React.FC<EditWorkingHoursPropsI> = ({
  selectedVendor,
  isVenue,
  isEdit,
  days,
  schedulesSelected,
  setDays,
  setSchedulesSelected
}) => {
  const { isMobileSize } = useWindowResize();
  const onClickAllDays = () => {
    setDays([1, 2, 3, 4, 5, 6, 7]);
  };

  const onClickMonToFri = () => {
    setDays([1, 2, 3, 4, 5]);
  };

  // if there are no schedules and it's edit mode, add empty schedule
  React.useEffect(() => {
    if (schedulesSelected.length === 0 && days.length > 0) {
      addNewSchedule();
    }
  }, [days]);

  const handleSelectChange = (value: string, index: number, key: keyof ScheduleI) => {
    const updatedSchedules = [...schedulesSelected];
    updatedSchedules[index][key] = value;
    setSchedulesSelected(updatedSchedules);
  };
  const deleteSchedule = (index: number) => {
    const updatedSchedules = schedulesSelected.filter((_schedule, i) => i !== index);
    setSchedulesSelected(updatedSchedules);
  };
  const addNewSchedule = () => {
    const defaultTimes = { start_time: '09:00', end_time: '18:00' };

    if (isEdit && schedulesSelected.length > 0) {
      const { end_time: lastEndTime } = schedulesSelected[schedulesSelected.length - 1];

      const newSchedule = {
        start_time: lastEndTime === '00:00' ? defaultTimes.start_time : lastEndTime,
        end_time: ''
      };

      setSchedulesSelected([...schedulesSelected, newSchedule]);
      return;
    }

    const newSchedule = schedulesSelected.length === 0 ? defaultTimes : { start_time: '', end_time: '' };
    setSchedulesSelected([...schedulesSelected, newSchedule]);
  };

  const isAddScheduleDisabled = schedulesSelected.some(
    (schedule) => schedule.start_time === '' || schedule.end_time === ''
  );

  return (
    <Column className="w-full max-w-xl border rounded-xl bg-white p-4">
      <Column>
        {!isEdit ? (
          <p className="pb-8 text-sm text-[#626262]">
            {isVenue
              ? `Selecciona una combinación de días y horarios para agregar a la disponibilidad de ${selectedVendor?.user.first_name} ${selectedVendor?.user.last_name}.`
              : 'Selecciona cuándo estarás disponible para recibir reservas.'}
          </p>
        ) : (
          <p className="pb-8 text-sm text-[#626262]">
            {isVenue
              ? `Estas modificando los horarios disponibles de ${selectedVendor?.user.first_name} ${
                  selectedVendor?.user.last_name
                } los días ${dayjs().day(days[0]).format('dddd')}.`
              : `Estas modificando tus horarios disponibles para los días ${dayjs()
                  .day(days[0])
                  .format('dddd')}.`}
          </p>
        )}
        {!isEdit && (
          <Row align="center" justify="center" gap={20} className="pb-4 mb-4">
            <Button rounded="full" variant="outline" onClick={onClickAllDays}>
              Todos los días
            </Button>
            <Button rounded="full" variant="outline" onClick={onClickMonToFri}>
              Lunes a Viernes
            </Button>
          </Row>
        )}
        <Row
          align="center"
          justify="space-between"
          gap={isMobileSize ? 10 : 20}
          className="pb-4 mb-4 border-b"
        >
          {initialWorkingHoursState.map((day, i) => {
            const isSelected = days.includes(day.value);
            return (
              <span
                className={classNames(
                  'flex items-center justify-center w-full aspect-square border rounded-full',
                  {
                    'cursor-pointer': !isEdit,
                    'cursor-default': isEdit && !isSelected,
                    'text-blue border-blue font-medium': isSelected,
                    'text-[#626262]': !isSelected
                  }
                )}
                onClick={
                  !isEdit
                    ? () => {
                        // a click could be add or remove a day from the array
                        if (days.includes(day.value)) {
                          setDays(days.filter((_day) => _day !== day.value));
                        } else {
                          setDays([...days, day.value]);
                        }
                      }
                    : undefined
                }
                key={i}
              >
                {day.label.charAt(0).toUpperCase()}
              </span>
            );
          })}
        </Row>
        <div>
          {schedulesSelected.map((schedule, i) => {
            const previousSchedule = schedulesSelected[i - 1];
            const nextSchedule = schedulesSelected[i + 1];

            const minTime = previousSchedule ? previousSchedule.end_time : undefined;
            const maxTime = nextSchedule ? nextSchedule.start_time : undefined;

            return (
              <Row align="center" justify="center" gap={20} className="py-2" key={i}>
                <InputTimePicker
                  initialValue={schedule.start_time}
                  onTimeChange={(time) => handleSelectChange(time, i, 'start_time')}
                  minTime={minTime}
                  maxTime={schedule.end_time}
                />
                <InputTimePicker
                  initialValue={schedule.end_time}
                  onTimeChange={(time) => handleSelectChange(time, i, 'end_time')}
                  minTime={schedule.start_time}
                  maxTime={maxTime}
                />
                <FontAwesomeIcon
                  onClick={() => deleteSchedule(i)}
                  className="cursor-pointer"
                  size="lg"
                  icon={faTrashAlt}
                  color="#868686"
                />
              </Row>
            );
          })}
        </div>
        <Row align="center" justify="center" className="my-4">
          <span
            onClick={() => {
              if (!isAddScheduleDisabled) {
                addNewSchedule();
              }
            }}
            className={classNames({
              'text-[#0072FB] font-medium cursor-pointer': true,
              'opacity-50 cursor-default': isAddScheduleDisabled
            })}
          >
            + Agregar nuevo rango
          </span>
        </Row>
      </Column>
    </Column>
  );
};
