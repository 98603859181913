import { faDownload, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { TabType } from './Posts';

const FLASH_URL = import.meta.env.VITE_FLASH_URL;
const API_URL = import.meta.env.VITE_CYCLONE_API_URL || 'http://localhost:4000';

// Utility function to convert base64 to Blob
function base64ToBlob(base64: string, mimeType: string = 'image/jpeg'): Blob {
  // Remove the data URI prefix if it exists
  const base64Clean = base64.replace(/^data:image\/\w+;base64,/, '');

  // Decode base64 string to binary
  const byteCharacters = atob(base64Clean);

  // Create byte arrays
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  // Create and return Blob
  return new Blob([byteArray], { type: mimeType });
}

// Utility function to handle file download
function downloadFile(blob: Blob, filename: string) {
  // Create a link and trigger download
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.style.display = 'none';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // Cleanup
  URL.revokeObjectURL(link.href);
}

export const useDownloadSlide = (
  setDownloadingSlides: React.Dispatch<React.SetStateAction<number[]>>,
  vendorUsername: string,
  type: TabType
) => {
  return useMutation({
    mutationFn: async (slideId: number | number[]) => {
      const slideIds = Array.isArray(slideId) ? slideId : [slideId];
      setDownloadingSlides((prev) => [...prev, ...slideIds]);

      try {
        const slideUrls = slideIds.map((id) =>
          encodeURIComponent(`${FLASH_URL}/posts/${type}?id=${id}&vendor=${vendorUsername}`)
        );

        if (slideIds.length === 1) {
          // Single screenshot download
          const screenshotUrl = `${API_URL}/screenshot?format=ig-${type}&url=${slideUrls[0]}`;

          // Create a blob from the screenshot URL
          const response = await fetch(screenshotUrl);
          const blob = await response.blob();

          downloadFile(blob, `${type}-${vendorUsername}-${slideIds[0]}-${Date.now()}.jpg`);
        } else {
          // Batch screenshot download
          const response = await fetch(`${API_URL}/screenshot/batch`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ urls: slideUrls, format: `ig-${type}` })
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const { results } = await response.json();

          const failedDownloads = results.filter((result: any) => !result.success);
          if (failedDownloads.length > 0) {
            console.warn('Some slides failed to download:', failedDownloads);
          }

          await Promise.all(
            results.map(async (result: any, index: number) => {
              if (result.success) {
                // Convert base64 to Blob directly
                const blob = base64ToBlob(result.data);

                downloadFile(blob, `${type}-${vendorUsername}-${slideIds[index]}-${Date.now()}.jpg`);
              }
            })
          );
        }

        return slideId;
      } catch (error) {
        console.error('Detailed download error:', error);
        throw error;
      }
    },
    onSuccess: (downloadedSlides) => {
      const count = Array.isArray(downloadedSlides) ? downloadedSlides.length : 1;
      toast.success(
        <span>
          <FontAwesomeIcon icon={faDownload} /> {count} slide{count !== 1 ? 's' : ''} descargado
          {count !== 1 ? 's' : ''}
        </span>,
        { position: 'top-right' }
      );
    },
    onError: () => {
      toast.error(
        <span>
          <FontAwesomeIcon icon={faExclamationTriangle} /> Error al descargar el slide
        </span>,
        { position: 'top-right' }
      );
    },
    onSettled: (_, __, slideId) => {
      const slideIds = Array.isArray(slideId) ? slideId : [slideId];
      setDownloadingSlides((prev) => prev.filter((id) => !slideIds.includes(id)));
    }
  });
};
