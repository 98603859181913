import { Navigate } from 'react-router-dom';
import { useStoreFront } from '../StoreFrontBuilder/context';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { slide3, slide4, slide5 } from '@/static/images';

export default function FeedPreview() {
  const { iFrameKey } = useStoreFront();

  const vendorUsername = new URLSearchParams(window.location.search).get('vendor');
  const slideId = new URLSearchParams(window.location.search).get('id');

  if (!slideId || !vendorUsername) return <Navigate to={`/posts/feed`} />;

  const slide = getSlideById(parseInt(slideId, 10), vendorUsername, iFrameKey);

  if (!slide) {
    return <div className="h-screen flex items-center justify-center">Slide not found</div>;
  }

  return <>{slide.content}</>;
}

// Function to get slide by ID
function getSlideById(id: number, vendorUsername: string, iFrameKey: number) {
  const NIGHTWING_URL = import.meta.env.VITE_NIGHTWING_URL || 'https://agora.red';
  const slides = [
    {
      id: 1,
      title: 'Página 1',
      content: (
        <div className="bg-white p-4 w-[1080px] h-[1350px] flex flex-col gap-8 justify-center">
          <p className="max-w-[20ch] text-7xl text-[#212121] mx-auto mb-8 text-center font-medium">
            ¡Reserva ahora a través de nuestra página!
          </p>
          <div className="mockup-browser border w-[80%] mx-auto h-[60%] shadow-md">
            <div className="mockup-browser-toolbar">
              <div className="border text-xl rounded-full bg-[#f2f2f2] px-4 text-[#212121]">
                https://agora.red/{vendorUsername}
              </div>
            </div>
            <div className="border-t bg-gray-300 w-full h-full relative overflow-hidden">
              <iframe
                className="w-full h-full scale-100 origin-top"
                src={`${NIGHTWING_URL}/iframe/${vendorUsername}?isPreview=true`}
                key={iFrameKey}
                style={{ overflow: 'hidden', pointerEvents: 'none' }}
              ></iframe>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 2,
      title: 'Página 2',
      content: (
        <div className="bg-white py-4 flex items-center w-[1080px] h-[1350px]">
          <div className="flex items-center w-1/2 h-full justify-end pl-4">
            <div className="relative w-[90%] h-[70%] bg-gray-300 rounded-[2.5rem] overflow-hidden border-[10px] shadow-md">
              {/* Pantalla */}
              <div className="relative h-full w-full bg-gray-200 rounded-[2rem] overflow-hidden">
                <iframe
                  className="w-full h-full scale-100 origin-top"
                  src={`${NIGHTWING_URL}/iframe/${vendorUsername}?isPreview=true`}
                  key={iFrameKey}
                  style={{ overflow: 'hidden', pointerEvents: 'none' }}
                ></iframe>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center w-1/2 h-full pl-4 gap-4">
            <h1 className="text-7xl font-medium text-left pr-4">
              Ingresá a <br /> nuestra página
            </h1>
            <p className="text-3xl">agora.red/{vendorUsername}</p>
            <div className="flex items-center">
              <div className="flex text-2xl items-center justify-center mask mask-circle bg-black w-16 h-16 text-white font-bold">
                1
              </div>
              <div className="w-full h-2 bg-black" />
            </div>
          </div>
        </div>
      )
    },
    {
      id: 3,
      title: 'Página 3',
      content: (
        <div className="bg-white py-4 flex items-center w-[1080px] h-[1350px] relative">
          <div className="flex items-center w-1/2 h-full justify-end z-10 pl-4">
            <div className="relative w-[90%] h-[70%] bg-gray-300 rounded-[2.5rem] overflow-hidden border-[10px] shadow-md">
              {/* Pantalla */}
              <div className="relative h-full w-full bg-gray-200 rounded-[2rem] overflow-hidden">
                <img src={slide3} alt="slide3" />
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center w-1/2 h-full pl-8 gap-2">
            <h1 className="text-6xl font-medium text-left pr-4">
              Seleccioná el servicio que te quieras realizar
            </h1>
            <div className="flex items-center">
              <div className="absolute right-0 left-0 top-[40%] w-full h-2 bg-black flex items-center justify-center">
                <div className="flex text-2xl ml-32 items-center justify-center mask mask-circle bg-black w-16 h-16 text-white font-bold">
                  2
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 4,
      title: 'Página 4',
      content: (
        <div className="bg-white py-4 flex items-center w-[1080px] h-[1350px] relative">
          <div className="flex items-center w-1/2 h-full justify-start z-10 order-2 pl-4">
            <div className="relative w-[90%] h-[70%] bg-gray-300 rounded-[2.5rem] overflow-hidden border-[10px]">
              {/* Pantalla */}
              <div className="relative h-full w-full bg-gray-200 rounded-[2rem] overflow-hidden">
                <img src={slide4} alt="slide4" />
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center w-1/2 h-full pl-4 gap-2">
            <h1 className="text-6xl font-medium text-right pr-4">Seleccioná la fecha y hora de tu turno</h1>
            <div className="flex items-center">
              <div className="absolute right-0 left-0 top-[40%] w-full h-2 bg-black flex items-center justify-center">
                <div className="flex text-2xl mr-16 items-center justify-center mask mask-circle bg-black w-16 h-16 text-white font-bold">
                  3
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 5,
      title: 'Página 5',
      content: (
        <div className="bg-white py-4 flex items-center w-[1080px] h-[1350px] relative">
          <div className="flex items-center w-1/2 h-full justify-end z-10 pl-4">
            <div className="relative w-[90%] h-[70%] bg-gray-300 rounded-[2.5rem] overflow-hidden border-[10px] shadow-md">
              {/* Pantalla */}
              <div className="relative h-full w-full bg-gray-200 rounded-[2rem] overflow-hidden">
                <img src={slide5} alt="slide5" />
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center w-1/2 h-full pl-8 gap-2">
            <h1 className="text-6xl font-medium text-left pr-4">Completá tus datos personales</h1>
            <div className="flex items-center">
              <div className="absolute right-0 left-0 top-[40%] w-full h-2 bg-black flex items-center justify-center">
                <div className="flex text-2xl ml-32 items-center justify-center mask mask-circle bg-black w-16 h-16 text-white font-bold">
                  4
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 6,
      title: 'Página 6',
      content: (
        <div className="bg-white py-4 flex items-center w-[1080px] h-[1350px]">
          <div className="flex items-center w-1/2 h-full justify-start z-10 order-2 pl-4">
            <div className="relative w-[90%] h-[70%] bg-gray-300 rounded-[2.5rem] overflow-hidden border-[10px] shadow-md">
              {/* Pantalla */}
              <div className="relative h-full w-full bg-gray-200 rounded-[2rem] overflow-hidden">
                <div className="bg-green-500 flex flex-col gap-8 items-center justify-center w-full h-full">
                  <FontAwesomeIcon icon={faCheck} className="text-white" size="7x" />
                  <span className="text-white text-3xl font-medium">¡Reserva confirmada!</span>
                  <div className="flex items-center justify-center rounded-full py-2 px-4 bg-white text-green-500 text-xl">
                    Ver reservas
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center w-1/2 h-full pr-4 gap-2">
            <h1 className="text-7xl font-medium text-right pl-4">¡Y Listo!</h1>
            <p className="text-3xl pl-4 text-right">
              Te damos las instrucciones para abonar la seña y así confirmar tu turno
            </p>
            <div className="flex items-center">
              <div className="w-full h-2 bg-black" />
              <div className="flex text-xl items-center justify-center mask mask-circle bg-black w-16 h-16 text-white font-bold">
                5
              </div>
            </div>
          </div>
        </div>
      )
    }
  ];

  return slides.find((slide) => slide.id === id);
}
