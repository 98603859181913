import { ConfirmationModal } from '@/components';
import React, { createContext, FunctionComponent, useContext, useState } from 'react';

type ConfirmationStatus = 'info' | 'warning' | 'danger';

interface ModalConfig {
  status?: ConfirmationStatus;
  title?: string;
  content?: string;
  message?: string;
  confirmText?: string;
  cancelText?: string;
  onClose?: () => void;
  onConfirm?: () => void;
}

type ConfirmationContextType = {
  confirm: (modalConfig: ModalConfig) => void;
};

const defaultConfirmOptions: ModalConfig = {
  status: 'info',
  title: 'Confirmar Cambios',
  content: '¿Quieres confirmar la acción?',
  message: '',
  confirmText: 'Si, guardar',
  cancelText: 'No, volver'
};

const ConfirmationContext = createContext<ConfirmationContextType>({
  confirm: (modalConfig: ModalConfig) => modalConfig
});

interface ConfirmationProviderPropsI {
  children?: React.ReactNode;
}

export const ConfirmationProvider: FunctionComponent<ConfirmationProviderPropsI> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmOptions, setConfirmOptions] = useState<ModalConfig>(defaultConfirmOptions);

  const handleModalClose = (isOpen: boolean) => {
    setIsModalOpen(isOpen);

    if (!isOpen) {
      setConfirmOptions(defaultConfirmOptions);
    }
  };

  return (
    <ConfirmationContext.Provider
      value={{
        confirm: (modalConfig: ModalConfig) => {
          setConfirmOptions({ ...defaultConfirmOptions, ...modalConfig });
          setIsModalOpen(true);
        }
      }}
    >
      {children}
      <ConfirmationModal {...confirmOptions} isOpen={isModalOpen} setIsOpen={handleModalClose} />
    </ConfirmationContext.Provider>
  );
};

export const useConfirmation = (): ConfirmationContextType => useContext(ConfirmationContext);
