// TestErrorComponent.tsx
import React, { useEffect } from 'react';

export const TestErrorComponent: React.FC = () => {
  useEffect(() => {
    throw new Error('This is a test error');
  }, []);

  return <div>This won&apos;t be shown due to the error</div>;
};
