import { trackGenericEvent } from '@/analytics';
import { useMixpanelTrackPage } from '@/analytics/hooks';
import { Column, ResponsiveContainer, Row } from '@/components';
import { useAuth } from '@/contexts';
import { SupportedCountriesE, WalletTypeE } from '@/types/cyclone/models';
import { faMoneyBill1 } from '@fortawesome/free-regular-svg-icons';
import { faBank, faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import FloatButton from './FloatButton';
import classNames from 'classnames';
import { faMercadoPago } from '@/static/icons/faMercadoPago';

interface PaymentMethodProps {
  title: string;
  icon: string | IconDefinition;
  iconColor?: string;
  isEnabled: boolean;
  features: string[];
  navigateTo: string;
  isImage?: boolean;
}

const ActionButton = ({ isEnabled, onClick }: { isEnabled: boolean; onClick: () => void }) => (
  <div className="relative w-full cursor-pointer flex justify-end" onClick={onClick}>
    <span className="hidden sm:inline text-blue font-bold text-sm pr-4">
      {isEnabled ? 'Administrar' : 'Habilitar'}
    </span>
    <span className="sm:hidden w-8 h-8 rounded-full bg-blue text-white flex items-center justify-center">
      <FontAwesomeIcon icon={isEnabled ? faPencil : faPlus} />
    </span>
  </div>
);

const PaymentMethodCard: React.FC<PaymentMethodProps> = ({
  title,
  icon,
  isEnabled,
  features,
  navigateTo
}) => {
  const navigate = useNavigate();

  return (
    <article
      onClick={() => navigate(navigateTo)}
      className="relative flex justify-between border hover:brightness-90 p-4 rounded w-full max-w-3xl cursor-pointer bg-white mx-auto trasition duration-300"
    >
      <div
        className={classNames('absolute -top-[1px] -left-[1px] h-[calc(100%+2px)] w-[5px] z-10 rounded-l', {
          'bg-black': !isEnabled,
          'bg-[#0072FB]': isEnabled
        })}
      />
      <div className="text-xl md:text-2xl mr-4">
        <FontAwesomeIcon
          icon={icon as IconDefinition}
          fixedWidth
          size={icon === faMercadoPago ? 'lg' : '1x'}
          color={'#626262'}
        />
      </div>
      <Column gap={8} className="w-full">
        <h2 className="font-medium">{title}</h2>
        {features.map((feature, index) => (
          <Row key={index} gap={8} className="text-sm text-[#626262]">
            <span>•</span>
            <span>{feature}</span>
          </Row>
        ))}
      </Column>
      <div className="pl-4 flex flex-col justify-center">
        <ActionButton isEnabled={isEnabled} onClick={() => navigate(navigateTo)} />
      </div>
    </article>
  );
};

export const PaymentGateways: React.FC = () => {
  useMixpanelTrackPage('Payment Gateways');
  const { session } = useAuth();
  const country = session?.vendor?.country || '';

  const paymentGateways = session?.vendor?.payment_gateways || [];
  const isMercadoPagoAvailable = country !== SupportedCountriesE.CHILE;
  const isWalletAssociated = paymentGateways.includes(WalletTypeE.MERCADO_PAGO);
  const isCashEnabled = session?.vendor?.is_payment_cash_enabled;
  const isBankTransferEnabled = session?.vendor?.is_payment_bank_transfer_enabled;
  const isStaffCreated = session?.vendor?.vendor_activity_tracking?.first_staff_created_at !== null;

  const paymentMethods = [
    isMercadoPagoAvailable && {
      title: 'Mercado Pago',
      icon: faMercadoPago,
      isEnabled: isWalletAssociated,
      features: [
        'Pagos con tarjetas o saldo en Mercado Pago, anticipados en tu billetera',
        'Comisiones por procesamiento'
      ],
      navigateTo: '/ajustes/medios-de-pago/mercadopago'
    },
    {
      title: 'Pagos con transferencia',
      icon: faBank,
      isEnabled: isBankTransferEnabled || false,
      features: ['Pagos manuales con transferencia bancaria o desde billeteras virtuales', 'Sin comisión'],
      navigateTo: '/ajustes/medios-de-pago/transferencia'
    },
    {
      title: 'Pagos en efectivo',
      icon: faMoneyBill1,
      isEnabled: isCashEnabled || false,
      features: ['Pagos manuales en efectivo', 'Sin comisión'],
      navigateTo: '/ajustes/medios-de-pago/efectivo'
    }
  ].filter(Boolean) as PaymentMethodProps[];

  const { vendor } = session || {};
  if (!vendor) return null;

  const { vendor_activity_tracking } = vendor;

  const isCTA: boolean =
    vendor_activity_tracking?.first_payment_method_at &&
    (!vendor_activity_tracking?.first_availability_at || (session?.is_venue && !isStaffCreated))
      ? true
      : false;

  return (
    <ResponsiveContainer>
      <section className="my-2">
        <Row align="center" justify="space-between" className="mb-4 pb-4">
          <Column>
            <p className="text-xl sm:text-3xl">Medios de pago</p>
            <p className="text-sm text-[#828282]">
              Gestioná los medios de pago aceptados en tu negocio.{' '}
              <Link
                to="https://ayuda.agora.red/es/articles/7920323-como-te-pagan-tus-clientes"
                target="_blank"
                rel="noreferrer"
                onClick={() => trackGenericEvent('Button Help Center Clicked')}
                className="cursor-pointer text-blue"
              >
                Más info.
              </Link>
            </p>
          </Column>
        </Row>
        <Column align="center" justify="center" gap={16} className="w-full">
          {paymentMethods.map((method, index) => (
            <PaymentMethodCard key={index} {...method} />
          ))}
        </Column>
      </section>
      <FloatButton isCTA={isCTA} />
    </ResponsiveContainer>
  );
};
