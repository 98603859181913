import classNames from 'classnames';
import { useAuth } from '@/contexts';
import { Button } from '@/components/Button/ButtonV2';
import { Card, CardContent } from '@/components/Card.tsx/Card';
import { useDownloadSlide } from '@/containers/Posts/useDownloadSlide';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export enum TabType {
  FEED = 'feed',
  STORY = 'story'
}

export default function Posts() {
  const { session } = useAuth();
  const [activeTab, setActiveTab] = useState<TabType>(TabType.FEED);
  const vendorUsername = session?.vendor?.username as string;
  const FLASH_URL = import.meta.env.VITE_FLASH_URL || 'https://pro.agora.red';
  const [downloadingSlides, setDownloadingSlides] = useState<number[]>([]);
  const { isLoading, mutate: downloadSlide } = useDownloadSlide(
    setDownloadingSlides,
    vendorUsername,
    activeTab
  );

  const slides = Array.from({ length: 6 }, (_, index) => ({
    id: index + 1,
    title: `Página ${index + 1}`,
    url: `${FLASH_URL}/posts/${activeTab}?id=${index + 1}&vendor=${vendorUsername}`
  }));

  const handleTabClick = (tab: TabType) => {
    setActiveTab(tab);
  };

  return (
    <main className="min-h-screen bg-gray-100 py-10 px-4">
      <div className="max-w-md mx-auto">
        <div
          role="tablist"
          className="border grid grid-cols-2 gap-0.5 text-gray-400 cursor-pointer rounded-full mb-4 shadow-md"
        >
          <div
            role="tab"
            className={classNames(
              'py-2 px-4 rounded-l-full flex items-center justify-center font-bold',
              activeTab === TabType.FEED && 'bg-blue text-white'
            )}
            onClick={() => handleTabClick(TabType.FEED)}
          >
            Feed
          </div>
          <div
            role="tab"
            className={classNames(
              'py-2 px-4 rounded-r-full flex items-center justify-center font-bold',
              activeTab === TabType.STORY && 'bg-blue text-white'
            )}
            onClick={() => handleTabClick(TabType.STORY)}
          >
            Stories
          </div>
        </div>
        <div className="space-y-8 p-4 max-w-2xl mx-auto">
          <Button
            isLoading={isLoading}
            onClick={() => downloadSlide(slides.map((slide) => slide.id))}
            variant="outline"
            className="w-full bg-white/80 hover:bg-white text-gray-700 border-gray-300 hover:border-gray-400 transition-all duration-200"
          >
            Descargar todo
          </Button>
          {slides.map((slide) => (
            <motion.div
              key={slide.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Card className="overflow-hidden border-none duration-300">
                <CardContent className="!p-0">
                  <div className="bg-gray-100 p-4 flex items-center justify-between border">
                    <h3 className="text-base font-semibold text-gray-800">{slide.title}</h3>
                    <div className="flex gap-2">
                      <Button
                        isLoading={isLoading || downloadingSlides.includes(slide.id)}
                        disabled={isLoading}
                        variant="outline"
                        size="sm"
                        className="w-fit bg-white/80 hover:bg-white text-gray-700 border-gray-300 hover:border-gray-400 transition-all duration-200"
                        onClick={() => downloadSlide(slide.id)}
                      >
                        Descargar
                      </Button>
                      <Link to={slide.url} target="_blank">
                        <Button
                          variant="outline"
                          size="sm"
                          className="w-fit bg-white/80 hover:bg-white text-gray-700 border-gray-300 hover:border-gray-400 transition-all duration-200"
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </Button>
                      </Link>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>
      </div>
    </main>
  );
}
