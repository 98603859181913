import React, { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Column, PricingPlans, Spinner } from '@/components';
import { useMutation, useQuery } from 'react-query';
import { EmptyObject, ErrorI, GetPlansI, PostPlanI } from '@/types/cyclone/requests';
import { useClient } from '@/hooks';
import { useMixpanelTrackPage } from '@/analytics/hooks';
import { sendToGTM, trackGenericEvent } from '@/analytics';
import { useAuth } from '@/contexts';
import { useOnboarding } from '../contexts';
import { SupportedCountriesE } from '@/types/cyclone/models';
import usePageTracking from '@/hooks/usePageTracking';

export const PlanSelection: FunctionComponent = () => {
  useMixpanelTrackPage('Onboarding', { step: 'Plan Selection' });
  const navigate = useNavigate();
  const { client } = useClient();
  const { trackCustomEvent } = usePageTracking();
  const { country, fatherCategory, setLoadingState } = useOnboarding();
  const { reloadSession } = useAuth();
  const [planSelected, setPlanSelected] = useState<number | null>(null);

  const mutation = useMutation<EmptyObject, ErrorI, PostPlanI>(
    (data) =>
      client<EmptyObject>(`me/vendor/plans`, 'POST', {
        isAuthRequired: true,
        data
      }),
    {
      onSuccess: () => {
        mutationFinishOnboarding.mutate();
        trackGenericEvent('Button Selfonboard Finish Clicked');
        sendToGTM('Onboarding completed');
      }
    }
  );

  const { data: plansData } = useQuery(
    ['plans'],
    async () =>
      await client<GetPlansI>(`plans`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const mutationFinishOnboarding = useMutation<EmptyObject, ErrorI>(
    (connectId) =>
      client<EmptyObject>(`me/vendor/finish_onboarding`, 'POST', {
        isAuthRequired: true,
        data: { connect_id: connectId }
      }),
    {
      onSuccess: () => {
        reloadSession();
        const planName = planSelected === 1 ? 'profesional' : planSelected === 2 ? 'business' : 'profesional';
        // lowercase and replace spaces with -, also remove accents
        const normalizedCategory = fatherCategory?.name
          .toLowerCase()
          .replace(/\s+/g, '-')
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');

        if (fatherCategory?.name === 'Belleza y Estética') {
          trackCustomEvent('Lead');
        } else {
          trackCustomEvent(`Lead ${fatherCategory?.name}`);
        }

        navigate(`/bienvenido?categoria=${normalizedCategory}&plan=${planName}`);
      }
    }
  );

  const handleSubmit = (planId: number, staffCount?: number) => {
    setLoadingState(true);
    const isFreePlan = planId === 1 || planId === 2;
    const data: PostPlanI = {
      plan_id: planId,
      recurrence: 'monthly',
      is_free_trial: !isFreePlan,
      ...(staffCount && { staff_count: staffCount })
    };

    const event = planId === 1 ? 'Profesional plan' : planId === 2 ? 'Business plan' : 'Profesional plan';
    setPlanSelected(planId);
    sendToGTM(event);
    mutation.mutate(data);
    trackGenericEvent('Button Select Plan Clicked', { plan: event });
  };

  if (!plansData)
    return (
      <div className="mt-8">
        <Spinner />
      </div>
    );

  return (
    <Column align="center" gap={24} className="w-full py-4 pb-4">
      <h2 className="font-medium text-2xl mt-8 text-center text-balance">
        ¿Cuántos profesionales ofrecen servicios en tu negocio?
      </h2>
      <PricingPlans
        isLoading={mutationFinishOnboarding.isLoading}
        onSubmit={handleSubmit}
        country={country as SupportedCountriesE}
        isOnboarding
      />
    </Column>
  );
};
