import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogClose,
  DialogFooter,
  DialogTrigger
} from '../Dialog';
import { ImageCropper } from './components/ImageCropperV2';
import { toast } from 'react-toastify';
import { Column } from '../Column';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../Button';
import { theme } from '../App';
import { DEFAULT_AGORA_IMAGE } from '@/containers/ActionServiceV2/utils';
import classNames from 'classnames';
import useLog from '@/hooks/useLog';
import { Spinner } from '../Spinner';

export enum TypeImageInput {
  BANNER = 'banner',
  AVATAR = 'avatar',
  EVENT = 'event',
  SERVICE = 'service',
  DIGITAL_CONTENT = 'digitalContent',
  SUBSCRIPTION = 'subscription'
}

interface ImageManagerProps {
  img?: string;
  onImageChange: (img: string) => void;
  children?: React.ReactNode;
  type?: TypeImageInput;
}

const IMAGE_CONFIG = {
  validExtensions: ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG'],
  maxSize: 10 * 1024 * 1024,
  aspectRatios: {
    [TypeImageInput.AVATAR]: 1 / 1,
    [TypeImageInput.BANNER]: 3 / 1,
    [TypeImageInput.DIGITAL_CONTENT]: 1 / 1,
    [TypeImageInput.EVENT]: 1 / 1,
    [TypeImageInput.SERVICE]: 1 / 1,
    [TypeImageInput.SUBSCRIPTION]: 1 / 1,
    default: 1 / 1
  }
};

export const ImageManagerV2: React.FC<ImageManagerProps> = ({ children, img, onImageChange, type }) => {
  const { logAndNotify } = useLog();
  const [state, setState] = React.useState({
    file: undefined as File | undefined,
    croppedFile: undefined as File | undefined,
    showCropper: false,
    showModal: false,
    isUploading: false
  });

  const isDefaultImage = img === DEFAULT_AGORA_IMAGE;

  const validateFile = (file: File) => {
    if (file.size > IMAGE_CONFIG.maxSize) {
      throw new Error('La imagen no debe pesar más de 10 MB');
    }

    const extension = file.name.split('.').pop();
    if (!extension || !IMAGE_CONFIG.validExtensions.includes(extension)) {
      throw new Error('La imagen debe ser un archivo en formato .jpg, .jpeg o .png');
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;

    const file = e.target.files[0];

    try {
      setState((prev) => ({ ...prev, isUploading: true }));

      validateFile(file);

      e.target.value = '';

      setState((prev) => ({
        ...prev,
        file,
        croppedFile: undefined,
        showCropper: true
      }));
    } catch (error) {
      toast.error(error instanceof Error ? error.message : 'Ocurrió un error al procesar el archivo');
    } finally {
      setState((prev) => ({ ...prev, isUploading: false }));
    }
  };

  const handleCropComplete = React.useCallback(
    (file: File) => {
      const newImageURL = URL.createObjectURL(file);
      onImageChange(newImageURL);

      setState((prev) => ({
        ...prev,
        file,
        croppedFile: file,
        showCropper: false,
        showModal: false
      }));
    },
    [onImageChange]
  );

  const handleEditExisting = async () => {
    if (!img) return;

    try {
      setState((prev) => ({ ...prev, file: undefined, croppedFile: undefined, showCropper: true }));

      const response = await fetch(img);
      if (!response.ok) throw new Error(`Error al obtener la imagen: ${response.statusText}`);

      const blob = await response.blob();
      const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });

      setState((prev) => ({ ...prev, file, croppedFile: undefined }));
    } catch (error) {
      logAndNotify(`Error al procesar la imagen - ${error}`);
      toast.error('Error al procesar la imagen');
    }
  };

  const handleDelete = () => {
    onImageChange(DEFAULT_AGORA_IMAGE);
    setState((prev) => ({ ...prev, file: undefined, croppedFile: undefined }));
  };

  const renderPreview = () => (
    <Column align="center" gap={16} justify="space-between" className="w-full">
      <div className="w-full h-[60vh] bg-gray-300 flex items-center justify-center">
        <img
          src={img}
          alt="Imagen"
          className={classNames({
            'rounded-full aspect-auto object-contain w-[70%]': type === TypeImageInput.AVATAR,
            'w-full h-full object-contain': type !== TypeImageInput.AVATAR
          })}
        />
      </div>
      <DialogFooter className="w-full gap-4">
        <Button fullWidth size="md" variant="outlined" onClick={handleEditExisting}>
          Editar
        </Button>
        <Button fullWidth hoverColor={theme.colors.red[200]} size="md" onClick={handleDelete}>
          Borrar
        </Button>
      </DialogFooter>
    </Column>
  );

  const renderUploader = () => (
    <>
      <input className="hidden" id="input_img" type="file" onChange={handleFileChange} />
      <label htmlFor="input_img" className="relative w-full h-[400px] cursor-pointer">
        <Column
          justify="center"
          align="center"
          gap={16}
          className="w-full h-[400px] border border-dashed border-gray-700"
        >
          {state.isUploading ? (
            <div className="flex w-full h-[40vh] m-auto items-center justify-center">
              <Spinner size={23} background={theme.colors.grey[400]} />
            </div>
          ) : (
            <>
              <FontAwesomeIcon icon={faFileUpload} size="xl" />
              Seleccioná la imagen a subir
            </>
          )}
        </Column>
      </label>
    </>
  );

  const renderContent = () => {
    if (state.showCropper && state.file) {
      return (
        <ImageCropper
          type={type}
          image={state.file}
          onClose={(file) => handleCropComplete(file)}
          aspectRatio={IMAGE_CONFIG.aspectRatios[type || 'default']}
        />
      );
    }

    return isDefaultImage ? renderUploader() : renderPreview();
  };

  return (
    <Dialog
      open={state.showModal}
      onOpenChange={(show) => setState((prev) => ({ ...prev, showModal: show }))}
    >
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{isDefaultImage ? 'Subir imagen' : 'Vista previa'}</DialogTitle>
          <DialogClose />
        </DialogHeader>
        <div className="pt-4 min-h-[400px]">{renderContent()}</div>
      </DialogContent>
    </Dialog>
  );
};
