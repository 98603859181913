import { Button, Column, InputDatepicker, Row } from '@/components';
import { DialogFooter } from '@/components/Dialog';
import { InputTimePicker } from '@/components/Input';
import { buildSelectOptions, Select } from '@/components/Select';
import { useClient } from '@/hooks';
import { LabelValue } from '@/types';
import { OfflinePaymentTypeE, ServiceI } from '@/types/cyclone/models';
import {
  GetVirtualSchedulesI,
  PostAssignBookingEventInstanceI,
  VirtualSchedulesAvailabilityI
} from '@/types/cyclone/requests';
import { getDatesInterval } from '@/utils';
import { addMinutesToHour, convertToSpecifiedTimezone, getDayWeekName, getTime } from '@/utils/schedule';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { preInfoType } from '../ActionReservation';
import { useAuth } from '@/contexts';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

interface ListVenuesPropsI {
  service: ServiceI;
  preInfo?: preInfoType;
  venueId?: string;
  onSubmit: (data: Partial<PostAssignBookingEventInstanceI>) => void;
  isLoading: boolean;
}

export const DateAndPayment: React.FC<ListVenuesPropsI> = ({
  service,
  preInfo,
  venueId,
  onSubmit,
  isLoading
}) => {
  const { client } = useClient();
  const { session } = useAuth();

  const [date, setDate] = useState<string | undefined>(undefined);
  const [hour, setHour] = useState<string | undefined>(undefined);
  const [selectedHour, setSelectedHour] = useState<LabelValue | undefined>(undefined);
  const [selectedDate, setSelectedDate] = useState<string | undefined>(undefined);
  const [isUnpaid, setIsUnpaid] = useState(true);
  const [isTotalValue, setIsTotalValue] = useState(false);
  const [isDownPayment, setIsDownPayment] = useState(false);
  const [offPaymentType, setOffPaymentType] = useState<OfflinePaymentTypeE | undefined>();

  const isCashEnabled = isDownPayment
    ? session?.vendor?.is_payment_cash_enabled && service.down_payment?.is_cash_available
    : session?.vendor?.is_payment_cash_enabled;
  const isBankTransferEnabled = isDownPayment
    ? session?.vendor?.is_payment_bank_transfer_enabled && service.down_payment?.is_bank_transfer_available
    : session?.vendor?.is_payment_bank_transfer_enabled;
  const isNotOfflinePaymentAvailable = !isCashEnabled && !isBankTransferEnabled;

  React.useEffect(() => {
    if (isCashEnabled && isBankTransferEnabled) return;
    if (isCashEnabled && !isBankTransferEnabled) setOffPaymentType(OfflinePaymentTypeE.CASH);
    if (isBankTransferEnabled && !isCashEnabled) setOffPaymentType(OfflinePaymentTypeE.BANK_TRANSFER);
    if (isTotalValue) setOffPaymentType(undefined);
  }, [isUnpaid, isDownPayment, isTotalValue]);

  React.useEffect(() => {
    if (preInfo) {
      setSelectedDate(dayjs(preInfo.date).format('YYYY-MM-DD'));
      setDate(dayjs(preInfo.date).format('YYYY-MM-DD'));
      if (service.is_on_demand && preInfo.time) {
        setHour(preInfo.time);
        setSelectedHour({
          label: preInfo.time,
          value: preInfo.time
        });
      }
    }
  }, [preInfo]);

  const [startDate, endDate] = getDatesInterval({ interval: 60 });

  const { data: _schedules } = useQuery(
    ['services', service.id, 'schedules', startDate, endDate],
    async () =>
      await client<GetVirtualSchedulesI[]>(`services/${service.id}/schedules/${startDate}/${endDate}`),
    {
      retry: false,
      refetchOnWindowFocus: false,
      // FIXME: handle error case
      onError: () => null,
      enabled: service.is_on_demand
    }
  );
  const { data: availabilities, isFetching: isAvailabilitiesFetching } = useQuery(
    ['services', service.id, 'availability', startDate, endDate],
    async () =>
      await client<VirtualSchedulesAvailabilityI[]>(
        `services/${service.id}/schedules/${startDate}/${endDate}/availability`
      ),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !service.is_on_demand,
      // FIXME: handle error case
      onError: () => null,
      select: (data) => convertToSpecifiedTimezone(data, session?.vendor?.timezone)
    }
  );
  const schedules = service.is_on_demand ? _schedules : availabilities;

  const getOptionsDates = () => {
    if (!schedules || schedules.length === 0)
      return [
        {
          label: `No hay disponibilidad`,
          value: 'not_available'
        }
      ];
    return schedules
      .filter((schedule, index, self) => self.findIndex((t) => t.start_at === schedule.start_at) === index)
      .sort((a, b) => (dayjs(a.start_at).isAfter(dayjs(b.start_at)) ? 1 : -1))
      .map((schedule) => {
        const dayWeek = getDayWeekName(schedule.start_at);
        const dateFormat = dayjs(schedule.start_at).format('DD/MM');
        return {
          label: `${dayWeek} ${dateFormat}`,
          value: schedule.start_at
        };
      });
  };

  const getOptionsHours = (date?: string) => {
    const dateToFilter = date || selectedDate;
    console.log('dateToFilter', dateToFilter);

    if (!availabilities || availabilities.length === 0)
      return [
        {
          label: `No hay disponibilidad`,
          value: 'not_available'
        }
      ];

    return availabilities
      .filter((availability) => availability.start_at === dateToFilter)
      .sort((a, b) => {
        const getHour = (availability: VirtualSchedulesAvailabilityI) =>
          parseInt(availability.start_time.split(':')[0]);
        const getMinutes = (availability: VirtualSchedulesAvailabilityI) =>
          parseInt(availability.start_time.split(':')[1]);

        return dayjs(a.start_at)
          .set('hours', getHour(a))
          .set('minutes', getMinutes(a))
          .isAfter(dayjs(b.start_at).set('hours', getHour(b)).set('minutes', getMinutes(b)))
          ? 1
          : -1;
      })
      .map((availability) => {
        const time = getTime(availability.start_time);
        return {
          label: `${time} hs`,
          value: availability.start_time
        };
      });
  };

  const handleSubmit = () => {
    if (!selectedHour && !hour) return toast.error('Debes seleccionar una hora');
    if (!service.is_on_demand) {
      const data = {
        service_id: service.id,
        start_at: selectedDate,
        end_at: selectedDate,
        start_time: selectedHour!.value,
        end_time: addMinutesToHour(selectedHour!.value, service.duration),
        vendor_id: venueId ? venueId : undefined,
        payment_type: isUnpaid ? 'no_payment' : isDownPayment ? 'down_payment' : 'total_value',
        payment_method: isUnpaid || isDownPayment ? offPaymentType : undefined
      };
      // @ts-ignore
      onSubmit(data);
    } else {
      const data = {
        service_id: service.id,
        start_at: date!,
        end_at: date!,
        start_time: hour!,
        end_time: addMinutesToHour(hour!, service.duration),
        vendor_id: venueId ? venueId : undefined,
        payment_type: isUnpaid ? 'no_payment' : isDownPayment ? 'down_payment' : 'total_value',
        payment_method: isUnpaid || isDownPayment ? offPaymentType : undefined
      };
      // @ts-ignore
      onSubmit(data);
    }
  };

  const selectOptionsHours = buildSelectOptions(getOptionsHours(), selectedHour, setSelectedHour);

  const isButtonDisabled = (): boolean => {
    if (!service.is_on_demand) {
      return !selectedDate || selectedHour?.value === 'not_available' || !selectedHour;
    } else if (isUnpaid) {
      return isNotOfflinePaymentAvailable ? true : !!!offPaymentType;
    } else {
      return !!!date || !!!hour;
    }
  };

  const getStaffName = () => {
    const staff = session?.vendor?.venue?.vendors?.filter((v) => v.id.toString() === venueId);
    if (!staff || staff.length === 0) return '';
    return `${staff[0].user.first_name} ${staff[0].user.last_name}`;
  };

  const getDatesComponent = () => {
    if (!service.is_on_demand)
      return (
        <Row align="center" gap={12} className="w-full mb-4">
          <div className="w-1/2">
            {!isAvailabilitiesFetching ? (
              <InputDatepicker
                date={date}
                setDate={(date) => {
                  console.log('date', date);
                  setDate(dayjs(date).format('YYYY-MM-DD'));
                  setSelectedDate(dayjs(date).format('YYYY-MM-DD'));
                }}
                datepickerProps={{
                  includeDates: getOptionsDates().map((option) => dayjs(option.value).toDate())
                }}
              />
            ) : (
              <div className="w-full h-12 bg-gray-200 animate-pulse rounded-md" />
            )}
          </div>
          <div className="w-1/2">
            {!isAvailabilitiesFetching ? (
              <Select
                placeholder="Hora"
                value={selectedHour?.label}
                options={selectOptionsHours}
                iconName="time"
                fullWidth
              />
            ) : (
              <div className="w-full h-12 bg-gray-200 animate-pulse rounded-md" />
            )}
          </div>
        </Row>
      );
    else
      return (
        <Row align="center" gap={12} className="w-full mb-4">
          <div className="w-1/2">
            <InputDatepicker date={date} setDate={(date) => setDate(dayjs(date).format('YYYY-MM-DD'))} />
          </div>
          <div className="w-1/2">
            <InputTimePicker initialValue={hour} onTimeChange={(time) => setHour(time)} />
          </div>
        </Row>
      );
  };

  const handleOffPaymentsCheckbox = (type: OfflinePaymentTypeE) => {
    setOffPaymentType(type);
  };

  const handleIsUnpaid = () => {
    setIsUnpaid(!isUnpaid);
    setIsTotalValue(false);
    setIsDownPayment(false);
    if (!isUnpaid) setOffPaymentType(undefined);
    else {
      if (isBankTransferEnabled) setOffPaymentType(OfflinePaymentTypeE.BANK_TRANSFER);
      else if (isCashEnabled) setOffPaymentType(OfflinePaymentTypeE.CASH);
      else setOffPaymentType(undefined);
    }
  };

  const handleIsDownPayment = () => {
    setIsDownPayment(!isDownPayment);
    setIsUnpaid(false);
    setIsTotalValue(false);
    if (!isDownPayment) setOffPaymentType(undefined);
    else {
      if (isBankTransferEnabled) setOffPaymentType(OfflinePaymentTypeE.BANK_TRANSFER);
      else if (isCashEnabled) setOffPaymentType(OfflinePaymentTypeE.CASH);
      else setOffPaymentType(undefined);
    }
  };

  const handleIsTotalValue = () => {
    setIsTotalValue(!isTotalValue);
    setIsUnpaid(false);
    setIsDownPayment(false);
    setOffPaymentType(undefined);
  };

  return (
    <Column className="pt-4">
      <span className="text-[#868686] text-sm sm:text-base truncate w-[90%]">
        Servicio: <span className="text-black">{service.name}</span>
      </span>
      {venueId && (
        <span className="text-[#868686] text-sm sm:text-base">
          con <span className="text-black">{getStaffName()}</span>
        </span>
      )}
      <Column gap={4} className="w-full pt-4 pb-2 mb-4 border-b-2">
        {getDatesComponent()}
        <div className="divider text-[#868686]">Detalles del pago</div>
        <Column>
          <Row gap={16} className="my-1">
            <span>Pago recibido</span>
          </Row>
          <Row gap={32} className="pt-4">
            <div className="form-control">
              <label className="label gap-2 !justify-normal cursor-pointer">
                <input
                  checked={isUnpaid}
                  type="checkbox"
                  className="checkbox checkbox-primary"
                  onClick={handleIsUnpaid}
                />
                <span>No</span>
              </label>
            </div>
            {service.down_payment && (
              <div className="form-control">
                <label className="label gap-2 !justify-normal cursor-pointer">
                  <input
                    checked={isDownPayment}
                    type="checkbox"
                    className="checkbox checkbox-primary"
                    onClick={handleIsDownPayment}
                  />
                  <span>Solo seña</span>
                </label>
              </div>
            )}
            <div className="form-control">
              <label className="label gap-2 !justify-normal cursor-pointer">
                <input
                  checked={isTotalValue}
                  type="checkbox"
                  className="checkbox checkbox-primary"
                  onClick={handleIsTotalValue}
                />
                <span>Valor Total</span>
              </label>
            </div>
          </Row>
        </Column>

        <div className="pt-6">
          <span className={classNames('my-1', { 'text-[#CACACA]': !isUnpaid && !isDownPayment })}>
            Enviar instrucciones de pago:
          </span>
          <Row gap={32} className="pt-4">
            <div
              className={classNames('form-control mb-1', {
                'text-[#CACACA]': (!isUnpaid && !isDownPayment) || !isBankTransferEnabled
              })}
            >
              <label className="label gap-2 !justify-normal cursor-pointer">
                <input
                  type="checkbox"
                  checked={offPaymentType === OfflinePaymentTypeE.BANK_TRANSFER}
                  onClick={() => handleOffPaymentsCheckbox(OfflinePaymentTypeE.BANK_TRANSFER)}
                  className="checkbox checkbox-primary"
                  disabled={(!isUnpaid && !isDownPayment) || !isBankTransferEnabled}
                />
                <span>Transferencia</span>
              </label>
            </div>
            <div
              className={classNames('form-control', {
                'text-[#CACACA]': (!isUnpaid && !isDownPayment) || !isCashEnabled
              })}
            >
              <label className="label gap-2 !justify-normal cursor-pointer">
                <input
                  type="checkbox"
                  checked={offPaymentType === OfflinePaymentTypeE.CASH}
                  onClick={() => handleOffPaymentsCheckbox(OfflinePaymentTypeE.CASH)}
                  className="checkbox checkbox-primary"
                  disabled={(!isUnpaid && !isDownPayment) || !isCashEnabled}
                />
                <span>Efectivo</span>
              </label>
            </div>
          </Row>
          <p className={classNames('pt-2 text-blue', { invisible: !isDownPayment })}>
            Enviaremos las instrucciones para pagar el saldo pendiente.
          </p>
          <p
            className={classNames('pt-2 text-black', {
              invisible: !isNotOfflinePaymentAvailable || !isUnpaid
            })}
          >
            Aún no tienes instrucciones de pago.{' '}
            <Link to={'/ajustes/medios-de-pago'} className="text-blue underline underline-offset-1">
              Configurar ahora
            </Link>
            .
          </p>
        </div>
      </Column>
      <DialogFooter className="mb-4">
        <Button rounded fullWidth onClick={handleSubmit} disabled={isButtonDisabled()} loading={isLoading}>
          Confirmar reserva
        </Button>
      </DialogFooter>
    </Column>
  );
};
