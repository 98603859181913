import React, { FunctionComponent, useState, Dispatch, useEffect } from 'react';
import { Input } from '@/components/Input';
import { FAQItemI } from '../../types';
import { Column } from '@/components';
import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTitle } from '../Dialog';

interface EditFaq {
  show: boolean;
  onClose: () => void;
  editFaq: (question: string, answer: string, position: number) => void;
  addFaq: (question: string, answer: string) => void;
  faq: FAQItemI | null;
  position: number;
  setPosition: Dispatch<React.SetStateAction<number>>;
}

export const EditFaq: FunctionComponent<EditFaq> = ({
  onClose,
  addFaq,
  faq,
  position,
  editFaq,
  setPosition,
  show
}) => {
  const [question, setQuestion] = useState<string>('');
  const [answer, setAnswer] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (show) {
      if (faq) {
        setQuestion(faq.name);
        setAnswer(faq.description);
      } else {
        setQuestion('');
        setAnswer('');
      }
    }
  }, [show, faq]);

  const handleSubmit = () => {
    if (!question || !answer) {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
      return;
    }

    if (position < 0) {
      addFaq(question, answer);
    } else {
      editFaq(question, answer, position);
      setPosition(-1);
    }

    setQuestion('');
    setAnswer('');
    onClose();
  };

  const handleClose = () => {
    setQuestion('');
    setAnswer('');
    setError(false);
    onClose();
  };

  return (
    <Dialog open={show} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader className="pb-4 mb-4 border-b border-[#DACCE0]">
          <div className="grid grid-cols-5 place-items-center w-full">
            <DialogClose className="place-self-start" />
            <DialogTitle className="col-span-3 place-self-center">
              {faq ? 'Editar pregunta frecuente' : 'Agregar pregunta frecuente'}
            </DialogTitle>
            <button
              className="place-self-end self-center text-sm font-medium text-[#0072FB]"
              onClick={handleSubmit}
            >
              Guardar
            </button>
          </div>
        </DialogHeader>
        <Column gap={20} className="py-2">
          <Input
            placeholder="Pregunta"
            value={question}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuestion(e.target.value)}
            showCount
            inputId="question"
            error={error && !question ? 'Completá el campo' : undefined}
          />
          <Input
            variant="textarea"
            placeholder="Respuesta"
            value={answer}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAnswer(e.target.value)}
            maxLength={250}
            showCount
            inputId="answer"
            capitalize={false}
            error={error && !answer ? 'Completá el campo' : undefined}
          />
        </Column>
      </DialogContent>
    </Dialog>
  );
};
