'use client';

import { ChoiceCards } from './ChoiceCards';

interface PolicySelectorProps {
  value: string;
  onChange: (value: string) => void;
}

export function PolicySelector({ value, onChange }: PolicySelectorProps) {
  return (
    <ChoiceCards
      options={[
        {
          value: 'same',
          label: 'Misma política para todos',
          description: 'Aplica la misma configuración de seña a todos tus servicios'
        },
        {
          value: 'different',
          label: 'Política distinta por servicio',
          description: 'Configura diferentes montos de seña para cada servicio'
        }
      ]}
      defaultValue={value}
      onChange={onChange}
    />
  );
}
