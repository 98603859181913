import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

interface CardProps {
  title?: string;
  description?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
}

export const Card: React.FC<CardProps> = ({ title, description, onClick, children, disabled }) => {
  return (
    <div
      className={classNames(
        'relative flex items-center p-4 bg-white rounded-lg shadow-sm border border-gray-200 w-full max-w-[700px] h-full',
        {
          'max-h-[80px] hover:shadow-md cursor-pointer transition-shadow duration-150': !children,
          'opacity-50 pointer-events-none': disabled
        }
      )}
      onClick={onClick}
    >
      <div
        className={classNames('absolute -top-[1px] -left-[1px] h-[calc(100%+2px)] w-[5px] z-10 rounded-l', {
          'bg-black': !children,
          'bg-[#0072FB]': children
        })}
      />

      {children ? (
        children
      ) : (
        <>
          <div className="flex-1 pl-4">
            <h2 className="text-lg font-medium text-[#212121]">{title}</h2>
            <p className="text-base text-[#626262]">{description}</p>
          </div>

          <FontAwesomeIcon icon={faChevronRight} color="#212121" fixedWidth />
        </>
      )}
    </div>
  );
};
