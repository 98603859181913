import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Control, Controller, DeepMap, FieldError, FieldValues, UseFormSetValue } from 'react-hook-form';
import { Input, Select, SelectOptionProps, Tooltip } from '@/components';
import { LabelValue, PlaceType } from '@/types';
import { ScheduleStatusE, ServiceModalityE, VirtualEventInstancesI } from '@/types/cyclone/models';

interface EventDetailsFormProps {
  setValue: UseFormSetValue<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
  control: Control<FieldValues>;
  modality: LabelValue | undefined;
  meetType: LabelValue | undefined;
  selectOptionsModality: SelectOptionProps[];
  selectOptionsMeetType: SelectOptionProps[];
  selectedPlace?: PlaceType;
  setSelectedPlace: (place?: PlaceType) => void;
  eventInstance: VirtualEventInstancesI;
}

const tooltipText = 'Podrás editar algunos campos, solo si no tenés reservas activas.';

export const EventDetailsForm: FunctionComponent<EventDetailsFormProps> = ({
  setValue,
  control,
  errors,
  modality,
  meetType,
  selectOptionsModality,
  selectOptionsMeetType,
  eventInstance
}) => {
  const {
    start_at,
    start_time,
    end_time,
    virtual_slots,
    local_slots,
    participants,
    price,
    virtual_meet,
    status,
    address_details,
    event: { duration, service }
  } = eventInstance;

  const _slots = modality?.value === ServiceModalityE.VIRTUAL ? virtual_slots : local_slots;
  const slots = _slots + participants;
  const isOnDemand = service.is_on_demand;

  const getMeetLink = () => (virtual_meet ? virtual_meet.meet_link || '' : service.meet_link || '');

  return (
    <Container>
      <Title>
        Detalles del evento <Tooltip content={tooltipText} marginLeft="6px" />
      </Title>
      <FormContainer>
        <Row>
          <Controller
            control={control}
            name="date"
            defaultValue={dayjs(start_at).format('DD/MM/YYYY')}
            render={({ field: { onChange, value } }) => (
              <InputContainer className="withMargin">
                <Input
                  inputId="date"
                  disabled
                  value={value}
                  placeholder="Fecha"
                  helpText="Fecha del evento"
                  error={errors?.date?.message}
                  onChange={onChange}
                />
              </InputContainer>
            )}
          />
          <Controller
            control={control}
            name="time"
            defaultValue={`${start_time} a ${end_time}`}
            render={({ field: { onChange, value } }) => (
              <InputContainer className="withMargin">
                <Input
                  inputId="time"
                  disabled
                  value={value}
                  placeholder="Hora"
                  helpText="Horario del evento"
                  error={errors?.time?.message}
                  onChange={onChange}
                />
              </InputContainer>
            )}
          />
          <Controller
            control={control}
            name="duration"
            defaultValue={duration}
            render={({ field: { onChange, value } }) => (
              <InputContainer className="withMargin">
                <Input
                  inputId="duration"
                  disabled
                  value={value}
                  placeholder="Duración"
                  helpText="Duración del evento"
                  error={errors?.duration?.message}
                  onChange={onChange}
                />
              </InputContainer>
            )}
          />
          <Controller
            control={control}
            name="price"
            defaultValue={price}
            render={({ field: { onChange, value } }) => (
              <InputContainer className="withMargin">
                <Input
                  inputId="price"
                  placeholder="Precio"
                  disabled={
                    status === ScheduleStatusE.FINISHED || status === ScheduleStatusE.CANCELED || isOnDemand
                  }
                  value={value}
                  helpText="Precio por participante."
                  error={errors?.price?.message}
                  onChange={onChange}
                />
              </InputContainer>
            )}
          />
        </Row>
        <Row>
          <InputsRow>
            <InputContainer className="withMargin">
              <Select
                options={selectOptionsModality}
                placeholder="Servicio"
                value={modality?.label}
                disabled
                helpText="¿Tu servicio es online o presencial?"
              />
            </InputContainer>
            <Controller
              control={control}
              name="addressDetails"
              defaultValue={address_details}
              render={({ field: { onChange, value } }) => (
                <InputContainer className="withMargin">
                  <Input
                    inputId="address-details"
                    placeholder="Piso/Timbre"
                    helpText="Indicá tu piso o timbre"
                    error={errors?.addressDetails?.message}
                    disabled={
                      modality?.value === ServiceModalityE.VIRTUAL ||
                      status === ScheduleStatusE.FINISHED ||
                      status === ScheduleStatusE.CANCELED ||
                      isOnDemand
                    }
                    value={value}
                    reset={() => onChange('')}
                    onChange={onChange}
                  />
                </InputContainer>
              )}
            />
          </InputsRow>
        </Row>
        <Row>
          {(modality?.value === ServiceModalityE.LOCAL || modality?.value === ServiceModalityE.OPEN) && (
            <>
              <Controller
                control={control}
                name="localSlots"
                defaultValue={slots}
                render={({ field: { onChange, value } }) => (
                  <InputContainer className="withMargin">
                    <Input
                      inputId="local-slots"
                      value={value}
                      helpText="Cantidad de participantes máxima presencial"
                      error={errors?.localSlots?.message}
                      onChange={onChange}
                      placeholder="Capacidad presencial"
                    />
                  </InputContainer>
                )}
              />
              {modality?.value === ServiceModalityE.LOCAL && <Fill />}
            </>
          )}
          {(modality?.value === ServiceModalityE.VIRTUAL || modality?.value === ServiceModalityE.OPEN) && (
            <>
              <Controller
                control={control}
                name="virtualSlots"
                defaultValue={slots}
                render={({ field: { onChange, value } }) => (
                  <InputContainer className="withMargin">
                    <Input
                      inputId="virtual-slots"
                      placeholder="Capacidad virtual"
                      value={value}
                      helpText="Cantidad de participantes máxima virtual"
                      error={errors?.virtualSlots?.message}
                      onChange={onChange}
                    />
                  </InputContainer>
                )}
              />
              <InputContainer className="withMargin">
                <Select
                  options={selectOptionsMeetType}
                  placeholder="Servicio de transmisión"
                  value={meetType?.label}
                  helpText="Selecciona el servicio que más te guste"
                  callback={() => setValue('meetLink', '')}
                  disabled={status === ScheduleStatusE.FINISHED || status === ScheduleStatusE.CANCELED}
                />
              </InputContainer>
              <Controller
                control={control}
                name="meetLink"
                defaultValue={getMeetLink()}
                render={({ field: { onChange, value } }) => (
                  <InputContainer className="withMargin">
                    <Input
                      inputId="virtual-slots"
                      placeholder="Link de transmisión"
                      value={value}
                      helpText="Insertá acá el link de tu proveedor de transmisión (Zoom o Google Meet)"
                      error={errors?.virtualSlots?.message}
                      onChange={onChange}
                      disabled={status === ScheduleStatusE.FINISHED || status === ScheduleStatusE.CANCELED}
                      reset={() => onChange('')}
                    />
                  </InputContainer>
                )}
              />
            </>
          )}
        </Row>
      </FormContainer>
    </Container>
  );
};

const InputsRow = styled.div`
  display: flex;
  flex: 2;
  ${({ theme }) => theme.breakpoint('md')} {
    flex-direction: column;
    flex: 1;
  }
`;

const Fill = styled.div`
  display: flex;
  flex: 2;
  margin-right: 40px;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 24px;
  ${({ theme }) => theme.breakpoint('md')} {
    flex-direction: column;
    padding-bottom: 0px;
  }
`;

const InputContainer = styled.div<{ flex?: string }>`
  display: flex;
  flex: ${({ flex }) => flex ?? '1'};
  flex-direction: column;
  min-height: 80px;
  ${({ theme }) => theme.breakpoint('md')} {
    min-height: 85px;
  }
`;

const FormContainer = styled.div`
  padding-top: 33px;
  display: flex;
  flex: 4;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  .withMargin {
    margin-right: 20px;
    ${({ theme }) => theme.breakpoint('md')} {
      margin-right: 0px;
    }
  }
`;

const Title = styled.span`
  font-weight: ${({ theme }) => theme.font.weight.book};
  font-size: 25px;
  line-height: 31px;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ theme }) => theme.breakpoint('md')} {
    font-size: 18px;
    line-height: 23px;
  }
`;

const Container = styled.div`
  padding: 43px 0px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[100]};
  ${({ theme }) => theme.breakpoint('md')} {
    padding: 35px 0px 20px;
  }
`;
