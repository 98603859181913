import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { useClient, useWindowResize } from '@/hooks';
import { ActionsMenu, Column, Row } from '@/components';
import {
  AgoraPlanNameE,
  ServiceModalityE,
  SupportedCountriesE,
  UniqueEventI,
  UniqueEventStatusE,
  WalletTypeE
} from '@/types/cyclone/models';
import { Link, useNavigate } from 'react-router-dom';
import { ErrorI } from '@/types/cyclone/requests';
import { useAuth } from '@/contexts';
import dayjs, { localeData } from 'dayjs';
import 'dayjs/locale/es';
import { formatCurrency } from '@/utils/money';
import { toast } from 'react-toastify';
import { getUpcomingEventDate } from '@/utils/schedule';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpRightFromSquare,
  faEllipsisH,
  faEllipsisVertical,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons';
import {
  faCopy,
  faEdit,
  faEye,
  faEyeSlash as faEyeSlashRegular,
  faPlayCircle,
  faTrashAlt
} from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';
import ImageComponent, { ImageDimensions } from '@/components/Image';
import useLog from '@/hooks/useLog';
import { ModalDuplicate } from '../ModalDuplicate';
import { ModalCancel } from '../ModalCancel';

dayjs.locale('es');
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(timezone);

type CardProps = {
  event: UniqueEventI;
  refetch: () => void;
  href: string;
};
type EmptyObject = {
  [K in string]: never;
};

export const Card: FunctionComponent<CardProps> = ({ event, refetch }) => {
  const { client } = useClient();
  const { session } = useAuth();
  const navigate = useNavigate();
  const { logAndNotify } = useLog();
  const { isMobileSize } = useWindowResize();

  const isEventPlan = session?.vendor?.plan_name === AgoraPlanNameE.EVENT;
  const isMPConnected = session?.vendor?.payment_gateways?.includes(WalletTypeE.MERCADO_PAGO);

  const {
    id,
    name,
    status,
    neighborhood,
    modality,
    cover_url,
    unique_events_schedules,
    sold_tickets,
    unique_events_tickets
  } = event;

  const country = session ? session.vendor!.country : SupportedCountriesE.ARGENTINA;

  const shouldShowBadge = sold_tickets < 0; // TODO

  // ! HARDCODED schedule, we assume it's not multidate
  const schedule = getUpcomingEventDate(unique_events_schedules);

  const buildModality = modality === ServiceModalityE.VIRTUAL ? 'Online' : 'Presencial';

  const buildDate = dayjs(schedule.start_at).format('D [de] MMMM').toLowerCase();
  const formattedDate = buildDate.charAt(0).toUpperCase() + buildDate.slice(1);
  const start_time = dayjs(schedule.start_at).format('HH:mm');

  // check if none of the tickets have high > price 0 if it does, and there's at least one ticket with price usd > 0, then it's a usd event
  const isUsdEvent =
    unique_events_tickets.every((ticket) => ticket.price == 0 || ticket.price === null) &&
    unique_events_tickets.some((ticket) => ticket.price_usd && ticket.price_usd > 0);
  const lowerPrice = Math.min(
    ...unique_events_tickets.map((ticket) => (isUsdEvent ? ticket.price_usd! : ticket.price))
  );

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const onCloseMenu = useCallback(() => {
    if (showMenu) setShowMenu(false);
  }, [showMenu]);

  const handleOnError = () => {
    toast.error('Ocurrió un error, por favor contactate con soporte.');
    setShowDuplicateModal(false);
  };

  const mutationEdit = useMutation<EmptyObject, ErrorI, UniqueEventStatusE>(
    (status) =>
      client(`unique_events/${id}/status`, 'PUT', {
        isAuthRequired: true,
        data: { status }
      }),
    {
      onSuccess: () => {
        refetch();
      },
      onError: (e) => {
        logAndNotify(`Error al editar un evento - ${JSON.stringify(e)}`);
        handleOnError();
      }
    }
  );

  const mutationHidden = useMutation<EmptyObject, ErrorI, { is_hidden: boolean }>(
    (data) =>
      client(`unique_events/${id}/hide`, 'PUT', {
        isAuthRequired: true,
        data
      }),
    {
      onSuccess: () => {
        refetch();
        setShowMenu(false);
        toast.success(
          `${isEventPlan ? 'Evento' : 'Capacitación'} ${
            event.is_hidden ? 'publicado' : 'ocultado'
          } exitosamente`
        );
      },
      onError: (e) => {
        logAndNotify(`Error al ocultar un evento - ${JSON.stringify(e)}`);
        handleOnError();
      }
    }
  );

  const handleStatusChange = () => {
    const newStatus =
      status === UniqueEventStatusE.ACTIVE ? UniqueEventStatusE.PAUSED : UniqueEventStatusE.ACTIVE;
    mutationEdit.mutate(newStatus);
  };

  const handleHiddenChange = () => {
    mutationHidden.mutate({ is_hidden: !event.is_hidden });
  };

  const getActions = () => {
    const actions = [];
    if (status === UniqueEventStatusE.ACTIVE) {
      actions.push(
        {
          label: 'Editar',
          onClick: () => navigate(`/eventos/${id}/editar/principal`),
          icon: faEdit
        },
        {
          label: event.is_hidden ? 'Publicar' : 'Ocultar',
          onClick: handleHiddenChange,
          icon: event.is_hidden ? faEye : faEyeSlashRegular
        }
      );
    }
    if (status === UniqueEventStatusE.PAUSED) {
      actions.push({
        label: 'Activar',
        onClick: handleStatusChange,
        icon: faPlayCircle
      });
    }
    if (status === UniqueEventStatusE.FINISHED) {
      actions.push({
        label: 'Ver',
        onClick: () => navigate(`/eventos/${id}/info/principal`),
        icon: faEye
      });
    }
    actions.push(
      {
        label: 'Duplicar',
        onClick: () => setShowDuplicateModal(true),
        icon: faCopy
      },
      {
        label: status === UniqueEventStatusE.ACTIVE ? 'Cancelar' : 'Eliminar',
        onClick: () => setShowDeleteModal(true),
        icon: faTrashAlt
      }
    );
    return actions;
  };

  const renderNotMPAlert = () => {
    if (isMPConnected) return;
    return (
      <>
        <span className="text-base text-[#CACACA]">{'•'}</span>
        <Row
          align="center"
          justify="center"
          gap={6}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            navigate('/ajustes/medios-de-pago/mercadopago');
          }}
          className="hover:underline underline-offset-2 text-[#FF4658] font-medium"
        >
          <span>Configurar método de pago</span>
          {!isMobileSize && <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" fixedWidth />}
        </Row>
      </>
    );
  };

  return (
    <>
      <Link
        onClick={(e) => {
          if (!isMobileSize) return;
          setShowMenu(!showMenu);
          e.stopPropagation;
        }}
        to={
          isMobileSize
            ? ''
            : `/eventos/${id}/${status === UniqueEventStatusE.FINISHED ? 'info' : 'editar'}/principal`
        }
      >
        <article className="flex cursor-pointer gap-4 sm:gap-0 max-h-[180px] h-full pb-4 mt-4 border-b sm:py-0 sm:mt-0 sm:border-none sm:rounded-lg relative sm:bg-white sm:hover:shadow-[0px_4px_23px_#00000050] sm:shadow-[0px_4px_23px_#00000011] transition-shadow">
          {!isMobileSize && (
            <div className="absolute top-3 right-3 cursor-pointer">
              <ActionsMenu show={showMenu} onClose={onCloseMenu} actions={getActions()}>
                <Row align="center" justify="center" className="w-8 h-8 rounded-full shadow-sm bg-[#fff]">
                  <FontAwesomeIcon icon={faEllipsisVertical} fixedWidth size="lg" className="text-blue" />
                </Row>
              </ActionsMenu>
            </div>
          )}
          <div className="max-w-[180px] w-[20%] sm:w-[35%] aspect-square relative">
            {event.is_hidden && (
              <div className="sm:rounded-l-lg rounded-lg sm:rounded-none w-full h-full bg-white opacity-80 z-10 absolute flex items-center justify-center">
                <FontAwesomeIcon icon={faEyeSlash} size={isMobileSize ? '2x' : '3x'} className="text-black" />
              </div>
            )}
            {shouldShowBadge && (
              <div className="sm:rounded-tl-lg rounded-t-lg sm:rounded-none absolute flex h-6 items-center justify-center w-full bg-[#FF4658]">
                <span className="text-white text-xs sm:text-sm uppercase py-1">Agotadas</span>
              </div>
            )}
            <ImageComponent
              src={cover_url}
              dimensions={ImageDimensions.SMALL}
              className="bg-center object-cover w-full h-full rounded-lg sm:rounded-none sm:rounded-l-lg"
            />
          </div>
          <div className="max-h-[180px] justify-between flex-1 h-full flex flex-col w-[80%] sm:w-[65%] sm:p-4 sm:rounded-tr-lg sm:rounded-br-lg">
            <header className="flex items-center justify-between w-full">
              <Row gap={8}>
                <span
                  className={classNames('text-sm font-medium', {
                    'text-[#0ad6a1]': modality === ServiceModalityE.VIRTUAL,
                    'text-blue': modality === ServiceModalityE.LOCAL
                  })}
                >
                  {buildModality}
                </span>
                <span className="text-sm font-light text-[#868686]">{neighborhood}</span>
              </Row>
            </header>
            <Row gap={16}>
              <Column className="w-full">
                <Column align="flex-start" className="min-h-[35px] h-full">
                  <span
                    className="h-full font-medium sm:text-xl text-ellipsis overflow-hidden leading-[1.2rem] text-left"
                    style={{
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      display: '-webkit-box',
                      height: 'auto'
                    }}
                  >
                    {name}
                  </span>
                </Column>
              </Column>
              {isMobileSize && (
                <ActionsMenu show={showMenu} onClose={onCloseMenu} actions={getActions()}>
                  <Row align="center" justify="center" className="w-8 h-8 rounded-full shadow-sm bg-[#fff]">
                    <FontAwesomeIcon icon={faEllipsisH} fixedWidth size="lg" className="text-blue" />
                  </Row>
                </ActionsMenu>
              )}
            </Row>
            <footer>
              {!isMobileSize && (
                <div className="flex justify-end w-full">
                  {unique_events_tickets.length > 1 && <span className="text-sm text-[#868686]">desde</span>}
                </div>
              )}
              <Row align="flex-end" justify="space-between" className="w-full">
                <Row align="center" gap={4} className="text-xs text-[#868686]">
                  <span>{formattedDate}</span>
                  <span className="text-base text-[#CACACA]">{'•'}</span>
                  <span className="text-[#CACACA]">{start_time} hs</span>
                  {renderNotMPAlert()}
                </Row>
                {!isMobileSize && (
                  <Row align="center">
                    <span className="font-bold text-black">
                      {lowerPrice === 0 && unique_events_tickets.length === 1 ? (
                        <span>¡Sin costo!</span>
                      ) : (
                        formatCurrency(lowerPrice, country, isUsdEvent)
                      )}
                    </span>
                  </Row>
                )}
              </Row>
            </footer>
          </div>
        </article>
      </Link>

      {showDuplicateModal && session?.vendor && (
        <ModalDuplicate
          id={id}
          vendor={session?.vendor}
          modalOpen={showDuplicateModal}
          setModalOpen={setShowDuplicateModal}
          onClose={refetch}
        />
      )}
      {showDeleteModal && session?.vendor && (
        <ModalCancel
          id={id}
          modalOpen={showDeleteModal}
          setModalOpen={setShowDeleteModal}
          type={status === UniqueEventStatusE.ACTIVE ? 'Cancelar' : 'Eliminar'}
          onClose={refetch}
        />
      )}
    </>
  );
};
