import { useEditor, EditorContent, BubbleMenu, Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faBold,
  faHighlighter,
  faItalic,
  faLink,
  faListOl,
  faListUl,
  faUnderline
} from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useMemo, useState, memo } from 'react';
import LinkMenu from './components/LinkMenu';
import CharacterCount from '@tiptap/extension-character-count';
import classNames from 'classnames';
import { InputColor } from '@/containers/StoreFrontBuilder/components/EditorSiteBuilder/components/CustomTemplateTab/components';

interface EditorProps {
  id?: string;
  content?: string;
  onChange?: (html: string) => void;
  placeholder?: string;
  limit?: number;
  readOnly?: boolean;
  error?: boolean;
  heigth?: string;
}

// Memoized ToolbarButton component
const ToolbarButton = memo(
  ({
    onClick,
    isActive = false,
    children,
    className
  }: {
    onClick?: () => void;
    isActive?: boolean;
    children: React.ReactNode;
    className?: string;
  }) => (
    <button
      onClick={onClick}
      className={classNames('p-2 rounded hover:bg-gray-100 transition-colors', className, {
        'text-blue bg-blue/20': isActive,
        'text-[#757575]': !isActive
      })}
    >
      {children}
    </button>
  )
);
ToolbarButton.displayName = 'ToolbarButton';

// Memoized Toolbar component
const Toolbar = memo(({ editor, linkMenuProps }: { editor: Editor; linkMenuProps: any }) => (
  <div className="border-b border-gray-200 p-2 flex flex-wrap gap-1 button-group items-center">
    <ToolbarButton className="px-0 py-1">
      <InputColor
        onChange={(value) =>
          editor
            .chain()
            .focus()
            .setColor(`${value.includes('#') ? value : `#${value}`}`)
            .run()
        }
        value={editor.getAttributes('textStyle').color || '#000000'}
        data-testid="setColor"
      />
    </ToolbarButton>
    <ToolbarButton
      onClick={() => editor.chain().focus().toggleBold().run()}
      isActive={editor.isActive('bold')}
    >
      <FontAwesomeIcon icon={faBold} size="sm" />
    </ToolbarButton>
    <ToolbarButton
      onClick={() => editor.chain().focus().toggleItalic().run()}
      isActive={editor.isActive('italic')}
    >
      <FontAwesomeIcon icon={faItalic} size="sm" />
    </ToolbarButton>
    <ToolbarButton
      onClick={() => editor.chain().focus().toggleUnderline().run()}
      isActive={editor.isActive('underline')}
    >
      <FontAwesomeIcon icon={faUnderline} size="sm" />
    </ToolbarButton>
    <LinkMenu {...linkMenuProps}>
      <ToolbarButton
        onClick={() => linkMenuProps.onOpenChange(!linkMenuProps.isOpen)}
        isActive={editor.isActive('link')}
      >
        <FontAwesomeIcon icon={faLink} size="sm" />
      </ToolbarButton>
    </LinkMenu>
    <div className="w-px h-6 bg-gray-200 mx-1" />
    <ToolbarButton
      onClick={() => editor.chain().focus().toggleBulletList().run()}
      isActive={editor.isActive('bulletList')}
    >
      <FontAwesomeIcon icon={faListUl} size="sm" />
    </ToolbarButton>
    <ToolbarButton
      onClick={() => editor.chain().focus().toggleOrderedList().run()}
      isActive={editor.isActive('orderedList')}
    >
      <FontAwesomeIcon icon={faListOl} size="sm" />
    </ToolbarButton>
    <ToolbarButton
      onClick={() => editor.chain().focus().setTextAlign('left').run()}
      isActive={editor.isActive({ textAlign: 'left' })}
    >
      <FontAwesomeIcon icon={faAlignLeft} size="sm" />
    </ToolbarButton>
    <ToolbarButton
      onClick={() => editor.chain().focus().setTextAlign('center').run()}
      isActive={editor.isActive({ textAlign: 'center' })}
    >
      <FontAwesomeIcon icon={faAlignCenter} size="sm" />
    </ToolbarButton>
    <ToolbarButton
      onClick={() => editor.chain().focus().setTextAlign('right').run()}
      isActive={editor.isActive({ textAlign: 'right' })}
    >
      <FontAwesomeIcon icon={faAlignRight} size="sm" />
    </ToolbarButton>
  </div>
));
Toolbar.displayName = 'Toolbar';

export default function RichTextEditor({
  id,
  content,
  onChange,
  placeholder,
  limit = 5000,
  readOnly = false,
  error,
  heigth = 'min-h-[300px]'
}: EditorProps) {
  const [linkMenuOpen, setLinkMenuOpen] = useState(false);
  const [colorMenuOpen, setColorMenuOpen] = useState(false);

  // Memoize extensions configuration
  const extensions = useMemo(
    () => [
      TextStyle,
      Color,
      StarterKit.configure({
        history: {
          depth: 100,
          newGroupDelay: 300
        }
      }),
      Underline,
      Link.configure({
        openOnClick: true,
        HTMLAttributes: {
          class: 'text-blue underline cursor-pointer'
        }
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph']
      }),
      Highlight.configure({
        multicolor: true
      }),
      Placeholder.configure({
        placeholder: placeholder || 'Escribe aqui...'
      }),
      CharacterCount.configure({
        limit
      })
    ],
    [placeholder, limit]
  );

  const handleUpdate = useCallback(
    ({ editor }: { editor: Editor }) => {
      onChange?.(editor.getHTML());
    },
    [onChange]
  );

  const editor = useEditor({
    extensions,
    content,
    onUpdate: handleUpdate,
    editorProps: {
      attributes: {
        class: `prose prose-override ${heigth} focus:outline-none`,
        spellcheck: 'false'
      },
      handleDrop: () => true,
      handlePaste: () => false
    },
    enableInputRules: true,
    enablePasteRules: true,
    parseOptions: {
      preserveWhitespace: 'full'
    }
  });

  const handleLinkSubmit = useCallback(
    (url: string) => {
      if (!editor) return;

      if (url === '') {
        editor.chain().focus().unsetLink().run();
        return;
      }
      editor.chain().focus().setLink({ href: url }).run();
    },
    [editor]
  );

  useEffect(() => {
    if (editor && content) {
      const currentContent = editor.getHTML();
      if (currentContent !== content) {
        editor.commands.setContent(content);
      }
    }
  }, [editor, content]);

  if (!editor) return null;

  const linkMenuProps = {
    isOpen: linkMenuOpen,
    onOpenChange: setLinkMenuOpen,
    initialUrl: editor.getAttributes('link').href,
    onSubmit: handleLinkSubmit
  };

  return (
    <div className="w-full max-w-3xl">
      <div
        className={classNames('flex flex-col border rounded-lg shadow-sm bg-white overflow-hidden', {
          'border-red-500': error,
          'border-gray-200': !error
        })}
      >
        <Toolbar editor={editor} linkMenuProps={linkMenuProps} />

        {editor && (
          <BubbleMenu
            editor={editor}
            tippyOptions={{ duration: 100 }}
            className="bg-white shadow-lg border border-gray-200 rounded-lg p-1 flex gap-1"
            shouldShow={({ state }) => {
              if (colorMenuOpen) return true;
              return !state.selection.empty;
            }}
          >
            <InputColor
              isOpen={(open) => setColorMenuOpen(open)}
              onChange={(value) =>
                editor
                  .chain()
                  .focus()
                  .setColor(`${value.includes('#') ? value : `#${value}`}`)
                  .run()
              }
              value={editor.getAttributes('textStyle').color || '#000000'}
              data-testid="setColor"
            />
            <ToolbarButton
              onClick={() => editor.chain().focus().toggleBold().run()}
              isActive={editor.isActive('bold')}
            >
              <FontAwesomeIcon icon={faBold} size="sm" />
            </ToolbarButton>
            <ToolbarButton
              onClick={() => editor.chain().focus().toggleItalic().run()}
              isActive={editor.isActive('italic')}
            >
              <FontAwesomeIcon icon={faItalic} size="sm" />
            </ToolbarButton>
            <ToolbarButton
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              isActive={editor.isActive('underline')}
            >
              <FontAwesomeIcon icon={faUnderline} size="sm" />
            </ToolbarButton>
            <ToolbarButton
              onClick={() => editor.chain().focus().toggleHighlight().run()}
              isActive={editor.isActive('highlight')}
            >
              <FontAwesomeIcon icon={faHighlighter} size="sm" />
            </ToolbarButton>
          </BubbleMenu>
        )}

        <div className="w-full relative">
          <div className="max-h-[400px] overflow-y-auto">
            <div className="w-full max-w-full break-words p-4">
              <EditorContent id={id} editor={editor} readOnly={readOnly} />
            </div>
          </div>
        </div>

        <div
          className={`text-xs p-2 border-t character-count ${
            editor.storage.characterCount.characters() === limit ? 'character-count--warning' : ''
          }`}
        >
          {editor.storage.characterCount.characters()} / {limit}
        </div>
      </div>
    </div>
  );
}
