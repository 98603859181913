import { Grid, ResponsiveContainer, Spinner } from '@/components';
import { AgoraLogo } from '@/components/Navbar/components';
import React from 'react';
import { useOnboarding } from '../../contexts';
import { ProgressBar } from '../ProgressBar';
import classNames from 'classnames';
import { useWindowResize } from '@/hooks';
import { Button } from '@/components/Button/ButtonV2';

export const NextStepButton: React.FC<{ fullWidth?: boolean; disabled?: boolean }> = ({
  fullWidth = false,
  disabled = false
}) => {
  const { step, onSubmitChildCategory, onSubmitLocation, onSubmitPhone, fullSubmitSiteData, loadingState } =
    useOnboarding();

  const handleButtonOnClick = () => {
    switch (step) {
      case 2:
        onSubmitChildCategory();
        break;
      case 3:
        onSubmitLocation();
        break;
      case 4:
        onSubmitPhone();
        break;
      case 5:
        fullSubmitSiteData();
        break;
      default:
        break;
    }
  };

  const handleButtonText = () => {
    const stepTextMap: { [key: number]: string } = {
      1: '',
      2: 'Siguiente',
      3: 'Guardar',
      4: 'Guardar',
      5: 'Guardar',
      6: ''
    };

    return stepTextMap[step] || '';
  };

  return (
    <div className="flex justify-end items-center w-auto h-[48px] ">
      <Button
        size={fullWidth ? 'lg' : 'default'}
        onClick={handleButtonOnClick}
        className={classNames('flex justify-center items-center font-medium !text-base', {
          invisible: step === 1 || step === 6,
          'w-full': fullWidth,
          'w-[120px]': !fullWidth
        })}
        disabled={loadingState || disabled}
      >
        {loadingState ? <Spinner color="#fff" size={20} /> : handleButtonText()}
      </Button>
    </div>
  );
};

const BackButton: React.FC = () => {
  const { step, setStep, fatherCategory, childCategories, loadingState } = useOnboarding();

  const handleButtonOnClick = () => {
    if (step === 3 && !fatherCategory) setStep(1);
    else if (step === 3 && fatherCategory?.name !== 'Belleza y Estética') setStep(1);
    else if (step === 3 && !childCategories) setStep(1);
    else setStep(step - 1);
  };

  return (
    <div className="flex justify-start items-center w-auto h-[48px]">
      <button
        onClick={handleButtonOnClick}
        disabled={loadingState}
        className={classNames('text-blue font-medium px-4 py-2 text-lg', {
          invisible: step === 1
        })}
      >
        Volver
      </button>
    </div>
  );
};

export const Navbar: React.FC = () => {
  const { step, privacyPolicies } = useOnboarding();
  const { isMobileSize } = useWindowResize();

  const progress = (step / 6) * 100;
  return (
    <header className="sticky top-0 left-0 h-[48px] right-0 bottom-0 bg-white z-50">
      <ResponsiveContainer>
        <Grid as="div" columns={isMobileSize ? (step === 1 ? 3 : 2) : 3} className="w-full h-[48px]">
          <BackButton />
          {!isMobileSize && (
            <div className="flex justify-center items-center w-auto h-[48px]">
              <AgoraLogo href={''} />
            </div>
          )}
          {isMobileSize && step === 1 && (
            <div className="flex justify-center items-center w-auto h-[48px]">
              <AgoraLogo href={''} />
            </div>
          )}

          <NextStepButton disabled={step === 5 && !privacyPolicies} />
        </Grid>
      </ResponsiveContainer>
      <ProgressBar value={progress} />
    </header>
  );
};
