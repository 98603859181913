import React from 'react';
import { motion } from 'framer-motion';

const CheckSuccess = () => {
  // Circle animation variants
  const circleVariants = {
    hidden: { scale: 0, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: [0.34, 1.56, 0.64, 1]
      }
    }
  };

  // Check mark animation variants
  const checkVariants = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: {
      pathLength: 1,
      opacity: 1,
      transition: {
        delay: 0.2,
        duration: 0.6,
        ease: 'easeOut'
      }
    }
  };

  // Particle variants
  const particleVariants = {
    hidden: { scale: 0, opacity: 0 },
    visible: (i: any) => ({
      scale: 1,
      opacity: [1, 0],
      x: Math.cos(i) * 20,
      y: Math.sin(i) * 20,
      transition: {
        duration: 0.8,
        delay: i * 0.05,
        ease: 'easeOut'
      }
    })
  };

  // Sparkle variants
  const sparkleVariants = {
    hidden: { scale: 0, opacity: 0 },
    visible: (i: any) => ({
      scale: [0, 1.5, 0],
      opacity: [0, 1, 0],
      x: Math.random() * 20 - 10,
      y: Math.random() * 20 - 10,
      transition: {
        duration: 0.6,
        delay: i * 0.1 + 0.3,
        repeat: 1,
        ease: 'easeInOut'
      }
    })
  };

  const particles = Array.from({ length: 8 });
  const sparkles = Array.from({ length: 4 });

  return (
    <motion.div
      className="relative flex items-center justify-center w-8 h-8"
      initial="hidden"
      animate="visible"
    >
      {/* Background circle with subtle pulse */}
      <motion.div
        className="absolute w-full h-full rounded-full bg-[#09D5A1]"
        variants={circleVariants}
        animate={{ scale: [1, 1.05, 1] }}
        transition={{ duration: 1.5, repeat: Infinity, ease: 'easeInOut' }}
      />

      {/* Particles */}
      {particles.map((_, i) => (
        <motion.div
          key={`particle-${i}`}
          className="absolute w-2 h-2 rounded-full bg-white"
          custom={(i * Math.PI) / 4}
          variants={particleVariants}
        />
      ))}

      {/* Sparkles */}
      {sparkles.map((_, i) => (
        <motion.div
          key={`sparkle-${i}`}
          className="absolute w-1 h-1 bg-white rounded-full"
          custom={i}
          variants={sparkleVariants}
        />
      ))}

      {/* Check mark SVG */}
      <motion.svg
        width="24"
        height="24"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="relative z-10"
      >
        <motion.path
          d="M8 16L14 22L24 10"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          variants={checkVariants}
        />
      </motion.svg>
    </motion.div>
  );
};

export default CheckSuccess;
