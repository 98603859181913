export interface ServiceData {
  id: string;
  name: string;
  totalValue: number;
  depositAmount: number;
  depositAmountUsd: number;
  depositPercentage: number;
  isEditing?: boolean;
  hasChanges?: boolean;
}

export const enum PolicyType {
  SAME = 'same',
  DIFFERENT = 'different'
}

export const enum DepositType {
  FIXED = 'fixed',
  PERCENTAGE = 'percentage'
}
