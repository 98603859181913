import React, { FunctionComponent } from 'react';
import { RgbaStringColorPicker } from 'react-colorful';
import hexToRgba from 'hex-to-rgba';
import rgbHex from 'rgb-hex';
import { Row } from '@/components';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/DropdownMenu';

interface DropdownColorPropsI {
  value: string;
  onOpenChange: (value: boolean) => void;
  children: React.ReactNode;
  onChange: (value: string) => void;
  title?: string;
}

export const DropdownColor: FunctionComponent<DropdownColorPropsI> = ({
  children,
  onOpenChange,
  value,
  onChange,
  title = ''
}) => {
  return (
    <DropdownMenu onOpenChange={(open) => onOpenChange(open)}>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>

      <DropdownMenuContent
        side="bottom"
        align="start"
        avoidCollisions
        className="w-64 p-4 flex flex-col gap-4"
      >
        <p className="text-sm font-medium">{title}</p>

        <RgbaStringColorPicker
          color={hexToRgba(value)}
          onChange={(rgba) => {
            try {
              const hex = `#${rgbHex(rgba)}`;
              onChange(hex);
            } catch (error) {
              console.error('Error al convertir color:', error);
            }
          }}
        />

        <Row justify="space-between" align="center" className="mt-2">
          <p className="text-xs font-medium">Hex</p>
          <input
            className="border border-gray-300 rounded-md w-[100px] py-1 px-2 text-center text-sm"
            type="text"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            maxLength={9}
          />
        </Row>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
