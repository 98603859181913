import { Column, Row } from '@/components';
import { useWindowResize } from '@/hooks';
import { SubCategoryI } from '@/types/cyclone/models';
import classNames from 'classnames';
import React from 'react';
import { useOnboarding } from '../contexts';
import { NextStepButton } from '../components/Navbar/Navbar';

export const SubCategory: React.FC = () => {
  const { isMobileSize } = useWindowResize();
  const { fatherCategory, handleChildCategory, childCategories, setStep, step } = useOnboarding();

  const isSelected = (subCategory: SubCategoryI) => {
    return childCategories.some((s) => s.name === subCategory.name);
  };

  const orderedSubCategories = fatherCategory?.childrens.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  React.useEffect(() => {
    if (step === 2 && !fatherCategory) {
      setStep(1);
    }
  }, [fatherCategory, step]);

  return (
    <Column
      align="center"
      className={classNames({
        'w-full p-8 min-h-[calc(100vh-48px)] max-h-[calc(100vh-48px)] overflow-y-auto border-r-[#868686] scrollbar-thin scrollbar-thumb-[#CECECE] scrollbar-track-[#f5f5f5] scrollbar-thumb-rounded-full':
          !isMobileSize,
        'w-full py-3': isMobileSize
      })}
    >
      <h2 className="font-medium text-2xl mt-16">Datos de tu negocio</h2>
      <p className="text-sm sm:text-base text-[#626262]">
        Selecciona los tipos de servicio que ofreces en tu negocio:
      </p>
      <Row className="gap-4 flex-wrap my-8 sm:max-w-[600px] w-full">
        {orderedSubCategories?.map((subCategory, i) => (
          <Column
            key={i}
            align="center"
            justify="center"
            className={classNames(
              'bg-white shadow-[0_0_10px_0_#62626233] border transition-colors rounded cursor-pointer py-2 px-4',
              {
                'shadow-[0_0_10px_0_#0072FB33] border-blue': isSelected(subCategory),
                'hover:shadow-[0_0_10px_0_#0072FB33] hover:border-blue/20': !isSelected(subCategory)
              }
            )}
            onClick={() => handleChildCategory(subCategory)}
          >
            <span className="text-base sm:text-lg">{subCategory.name}</span>
          </Column>
        ))}
      </Row>
      <NextStepButton fullWidth />
    </Column>
  );
};
