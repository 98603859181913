import React, { useEffect, useState } from 'react';
import { ServiceI, VendorI, VendorStatusE } from '@/types/cyclone/models';

import { Column, ResponsiveContainer, Row, SelectProps } from '@/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useMixpanelTrackPage } from '@/analytics/hooks';
import { useNavigate } from 'react-router';
import { useAuth } from '@/contexts';
import { ListVenues } from './components/ListVenues';
import { BUFFER_OPTIONS, initialWorkingHoursState, ScheduleI, WorkingHourState } from './utils';
import { WorkingHours } from './components/WorkingHours';
import {
  EmptyObject,
  ErrorI,
  PostWorkingHoursI,
  VendorWorkingHourI,
  VendorWorkingHoursI
} from '@/types/cyclone/requests';
import { useMutation, useQuery } from 'react-query';
import { useClient, useWindowResize } from '@/hooks';
import { EditWorkingHours } from './components/EditWorkingHours';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import useLog from '@/hooks/useLog';
import { Button } from '@/components/Button/ButtonV2';
import { trackGenericEvent } from '@/analytics';
import { getDaysFromWorkingHours } from '@/utils/recurrence';

export type preInfoType = {
  services: 'all' | 'onDemand';
  date: Date | null;
  time?: string;
  service?: ServiceI | null;
};

export const ActionWorkingHours: React.FC = () => {
  useMixpanelTrackPage('Enter Booking');
  const navigate = useNavigate();
  const { session, reloadSession } = useAuth();
  const { isMobileSize } = useWindowResize();
  const { client } = useClient();
  const { logAndNotify } = useLog();
  const isVenue = session?.is_venue;
  const searchParams = new URLSearchParams(location.search);
  const initialStep = searchParams.get('type') === 'bulk' && !isVenue ? 2 : isVenue ? 0 : 1;

  const [daySelected, setDaySelected] = useState<number | null>(null);
  const [days, setDays] = useState<number[]>([]);
  const [schedulesSelected, setSchedulesSelected] = useState<ScheduleI[]>([]);
  const [selectedVendor, setSelectedVendor] = React.useState<VendorI | undefined>(undefined);
  const [workingHoursState, setWorkingHoursState] = useState<WorkingHourState[]>(initialWorkingHoursState);
  const [bufferTime, setBufferTime] = React.useState<SelectProps>({ label: '', value: '' });

  const [step, setStep] = useState(initialStep);

  const isEdit = daySelected !== null;
  const driverMode = new URLSearchParams(window.location.search).get('driver') === 'true';

  const {
    data: workingHours,
    refetch,
    isLoading
  } = useQuery(
    ['working_hours', selectedVendor?.id],
    async () =>
      await client<VendorWorkingHoursI>(
        `me/vendor/working_hours${
          session?.is_venue && selectedVendor?.id !== null ? `?vendor_id=${selectedVendor?.id}` : ''
        }`,
        'GET',
        {
          isAuthRequired: true
        }
      ),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: session && session.is_venue ? selectedVendor?.id !== null : undefined
    }
  );

  const { data: _venues, refetch: refetchVenues } = useQuery(
    ['venues', 'staffs'],
    async () =>
      await client<VendorI[]>(`venues/staff`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isVenue
    }
  );

  const venues = _venues?.filter((venue) => venue.status === VendorStatusE.ACTIVE);

  useEffect(() => {
    if (workingHours) {
      const data = workingHours.map((day) => {
        return {
          day: day.day === 0 ? 7 : day.day,
          schedules: day.schedules
        };
      });
      const updatedWorkingHours = initialWorkingHoursState.map((day) => {
        const matchingDay = data.find((_data) => _data.day === day.value);
        if (matchingDay) {
          return {
            ...day,
            isSelected: true,
            schedules: matchingDay.schedules.map((schedule) => ({
              start_time: schedule.start_time,
              end_time: schedule.end_time
            }))
          };
        } else {
          return day;
        }
      });
      setWorkingHoursState(updatedWorkingHours);
    }
  }, [workingHours]);

  const cleanUp = () => {
    setDaySelected(null);
    setDays([]);
    setSchedulesSelected([]);
  };

  const checkAllStaffHaveWorkingHours = isVenue
    ? venues?.every((staff) => {
        const workingHours = staff.vendor_working_hours;
        const days = getDaysFromWorkingHours(workingHours || []);
        return days.length > 0;
      })
    : session?.vendor?.vendor_activity_tracking?.first_availability_at !== null;

  useEffect(() => {
    if (driverMode && checkAllStaffHaveWorkingHours) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('complete', 'true');

      const newUrl = `${location.pathname}?${searchParams.toString()}`;

      navigate(newUrl, { replace: true });
    }
  }, [checkAllStaffHaveWorkingHours]);

  const handleMutationComplete = () => {
    cleanUp();
    toast.success('Horarios guardados exitosamente.');
    refetch();
    reloadSession();
    setSelectedVendor(undefined);
    if (isVenue) refetchVenues();
    if (driverMode) {
      setStep(isVenue ? 0 : 1);
    } else {
      setStep(1);
      navigate(location.pathname, { replace: true });
    }
  };

  const mutation = useMutation<EmptyObject, ErrorI, PostWorkingHoursI>(
    (data) =>
      client(`me/vendor/working_hours`, 'POST', {
        isAuthRequired: true,
        data
      }),
    {
      onSuccess: () => handleMutationComplete(),
      onError: (e) => {
        logAndNotify(`Error al guardar los horarios laborales - ${JSON.stringify(e)}`);
        toast.error('Algo anda mal. Por favor, contactar a soporte.');
      }
    }
  );

  const isSaveDisabled = days.length === 0;

  const onSave = () => {
    // change day 7 to 0 from array days
    trackGenericEvent('Button Save Working Hours Clicked');

    const daysUpdated = [...new Set([...days])].map((day) => (day === 7 ? 0 : day));

    const savedWorkingHours = daysUpdated
      .map((day) => ({
        day: isEdit ? daysUpdated[0] : day,
        schedules: schedulesSelected.map((schedule) => ({
          start_time: schedule.start_time,
          end_time: schedule.end_time
        }))
      }))
      // change day 7 to 0, sunday is 0 in dayjs
      .map((item) => {
        if (item.day === 7) {
          return {
            day: 0,
            schedules: item.schedules
          };
        } else {
          return item;
        }
      });

    //need to copy the workingHours to avoid mutating the original
    const internalWorkingHours: VendorWorkingHoursI = JSON.parse(JSON.stringify(workingHours));

    // if edit mode, merge prevWorkingHours with savedWorkingHours without the day being edited
    // otherwise just merge prevWorkingHours with savedWorkingHours
    const _mergedWorkingHours = isEdit
      ? internalWorkingHours?.filter((item) => item.day !== daysUpdated[0]).concat(savedWorkingHours)
      : internalWorkingHours?.concat(savedWorkingHours);

    // If there are duplicated days, need to merge schedules and preserve the day
    const mergedWorkingHours = (_mergedWorkingHours || []).reduce((acc, curr) => {
      const existingDay = acc.find((item) => item.day === curr.day);
      if (existingDay) {
        existingDay.schedules = [...existingDay.schedules, ...curr.schedules];
        return acc;
      } else {
        return [...acc, curr];
      }
    }, [] as VendorWorkingHourI[]);

    // clean up, remove duplicated schedules from the same day
    const cleanedWorkingHours = mergedWorkingHours.map((item) => {
      const schedulesSet = new Set();
      const schedules = item.schedules.filter((schedule) => {
        const scheduleStr = `${schedule.start_time}-${schedule.end_time}`;
        if (schedulesSet.has(scheduleStr)) {
          return false;
        }
        schedulesSet.add(scheduleStr);
        return true;
      });
      return {
        day: item.day,
        schedules
      };
    });

    // change day 7 to 0, sunday is 0 in dayjs
    let updatedWorkingHours = cleanedWorkingHours.map((item) => {
      if (item.day === 7) {
        return {
          day: 0,
          schedules: item.schedules
        };
      } else {
        return item;
      }
    });

    // remove schedules which start_time is empty string AND end_time is empty string
    updatedWorkingHours = updatedWorkingHours.map((item) => ({
      day: item.day,
      schedules: item.schedules.filter((schedule) => schedule.start_time !== '' || schedule.end_time !== '')
    }));

    // remove schedules which schedules is empty array
    updatedWorkingHours = updatedWorkingHours.filter((item) => item.schedules.length > 0);

    // Function to convert time to 24-hour format
    const to24HourFormat = (time: string) => {
      const [hour, minute] = time.split(':');
      return dayjs().hour(Number(hour)).minute(Number(minute));
    };

    // Detect if there are duplicated days, if so, make sure schedules don't overlap
    const hasOverlappingSchedules = updatedWorkingHours.some((item) => {
      // Convert to dayjs objects and sort by start_time
      const sortedSchedules = item.schedules
        .map((schedule) => ({
          start_time: to24HourFormat(schedule.start_time),
          end_time: to24HourFormat(schedule.end_time)
        }))
        .sort((a, b) => a.start_time.diff(b.start_time));

      // Check for overlapping or repeated schedules
      const scheduleSet = new Set();
      for (let i = 0; i < sortedSchedules.length; i++) {
        const curr = sortedSchedules[i];
        const next = sortedSchedules[i + 1];

        // Check for repeated schedules
        const scheduleStr = `${curr.start_time.format('HH:mm')}-${curr.end_time.format('HH:mm')}`;
        if (scheduleSet.has(scheduleStr)) {
          return true; // Repeated schedule found
        }
        scheduleSet.add(scheduleStr);

        // Check for overlapping schedules
        if (next && curr.end_time.isAfter(next.start_time)) {
          return true; // Overlapping schedule found
        }
      }
      return false;
    });
    if (hasOverlappingSchedules) {
      return toast.error('Los horarios no pueden superponerse.');
    }

    // If end time is before or start time, return error
    const hasInvalidSchedules = updatedWorkingHours.some((item) => {
      return item.schedules.some((schedule) => {
        return to24HourFormat(schedule.start_time).isAfter(to24HourFormat(schedule.end_time));
      });
    });
    if (hasInvalidSchedules) {
      return toast.error('El horario de inicio no puede ser mayor que el de fin.');
    }

    // If there are same start_time and end_time, return error
    const hasSameStartAndEndTimes = updatedWorkingHours.some((item) => {
      return item.schedules.some((schedule) => {
        return schedule.start_time === schedule.end_time;
      });
    });
    if (hasSameStartAndEndTimes) {
      return toast.error('Los horarios no pueden ser iguales.');
    }

    // If there's an empty start_time or end_time when start_time and end_time are not both empty, return error
    const hasEmptyTimes = updatedWorkingHours.some((item) => {
      return item.schedules.some((schedule) => {
        return schedule.start_time === '' || schedule.end_time === '';
      });
    });
    if (hasEmptyTimes) {
      return toast.error('Los horarios no pueden estar vacíos.');
    }

    const data: PostWorkingHoursI = {
      working_hours: updatedWorkingHours,
      vendor_id: isVenue ? selectedVendor?.id.toString() : session?.vendor!.id.toString(),
      frequency: parseInt(bufferTime.value as string)
    };
    mutation.mutate(data);
  };

  const handleOnClick = (day: number, schedules: ScheduleI[]) => {
    setDays([day]);
    setDaySelected(day);
    setSchedulesSelected(schedules);
    setStep(2);
  };

  const onFrequencyChange = async (value: string) => {
    if (!value) return;
    trackGenericEvent('Button Frequency Change Clicked');

    const selectedOption = BUFFER_OPTIONS.find((option) => option.value === value);
    if (!selectedOption) return;

    mutation.mutate(
      {
        vendor_id: isVenue ? selectedVendor?.id.toString() : session?.vendor!.id.toString(),
        frequency: parseInt(value)
      },
      {
        onSuccess: () => {
          setBufferTime(selectedOption);
        }
      }
    );
  };

  const handleNextStepFromVenue = () => {
    if (selectedVendor) {
      const hasNoSchedules = workingHoursState.every((wh) => wh.schedules.length === 0);

      if (hasNoSchedules) {
        setStep(2);
      } else {
        setStep(1);
      }
    }
  };

  useEffect(() => {
    handleNextStepFromVenue();
  }, [workingHoursState]);

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          isVenue && <ListVenues venues={venues} setSelectedVendor={(vendor) => setSelectedVendor(vendor)} />
        );
      case 1:
        return (
          <WorkingHours
            workingHoursState={workingHoursState}
            isVenue={isVenue}
            selectedVendor={selectedVendor}
            bufferTime={bufferTime}
            setBufferTime={setBufferTime}
            onFrequencyChange={onFrequencyChange}
            isLoading={isLoading}
            handleOnClick={handleOnClick}
            handleNextStep={() => {
              cleanUp();
              setStep(2);
            }}
          />
        );
      case 2:
        return (
          <EditWorkingHours
            selectedVendor={selectedVendor}
            isVenue={isVenue}
            isEdit={isEdit}
            days={days}
            schedulesSelected={schedulesSelected}
            setDays={setDays}
            setSchedulesSelected={setSchedulesSelected}
          />
        );
      default:
        return null;
    }
  };

  const handleBackClick = () => {
    if (step === 1) {
      navigate(location.pathname, { replace: true });
      setSelectedVendor(undefined);
    }
    if (step === 2 && driverMode && isVenue) {
      setStep(0);
      setSelectedVendor(undefined);
      cleanUp();
      return;
    }
    setStep(1);
    cleanUp();
  };

  const renderRightButton = () => {
    if (step === 0 || step === 1) {
      return (
        <FontAwesomeIcon
          className="cursor-pointer"
          icon={faXmark}
          onClick={() => (driverMode ? null : navigate('/agenda'))}
          size="lg"
        />
      );
    } else if (step === 2) {
      return (
        <Row align="center" justify="space-between" gap={16} className="w-full">
          <Button variant="ghost" onClick={handleBackClick}>
            <FontAwesomeIcon icon={faArrowLeftLong} size="lg" />
          </Button>
          <Button size="lg" className="!rounded-full" onClick={onSave} disabled={isSaveDisabled}>
            Guardar
          </Button>
        </Row>
      );
    } else {
      return <div />;
    }
  };

  return (
    <section>
      <div className="w-full">
        <ResponsiveContainer>
          <Row justify="space-between" className="w-full gap-4 border-b py-4">
            {step !== 2 && (
              <Column className="w-[92%]">
                <Row
                  align="baseline"
                  className="gap-2 sm:gap-4 text-base sm:text-3xl sm:leading-none leading-none w-full"
                >
                  <p className="cursor-pointer" onClick={() => (driverMode ? null : navigate('/agenda'))}>
                    {isMobileSize && isVenue && step !== 0 ? '...' : 'Agenda'}
                  </p>
                  <FontAwesomeIcon icon={faChevronRight} fixedWidth size="xs" />
                  <p
                    className="cursor-pointer truncate"
                    onClick={() => {
                      if (isVenue && step !== 0) {
                        setStep(0);
                        setSelectedVendor(undefined);
                      }
                    }}
                  >
                    Horarios de trabajo
                  </p>
                  {isVenue && step !== 0 && (
                    <>
                      <FontAwesomeIcon icon={faChevronRight} fixedWidth size="xs" />
                      <p className="whitespace-nowrap">
                        {selectedVendor?.user.first_name + ' ' + selectedVendor?.user.last_name}
                      </p>
                    </>
                  )}
                </Row>
                <p className="text-sm sm:text-base text-[#828282]">
                  {isVenue
                    ? 'Gestionar los horarios disponibiles de tu equipo'
                    : 'Gestionar tus horarios disponibles'}
                </p>
              </Column>
            )}

            {renderRightButton()}
          </Row>
        </ResponsiveContainer>
      </div>
      <ResponsiveContainer className="py-4">
        <Column justify="center" align="center" className="w-full">
          {renderStep()}
        </Column>
      </ResponsiveContainer>
    </section>
  );
};
