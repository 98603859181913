import { trackGenericEvent } from '@/analytics';
import { authorize, register } from '@/auth';
import { InputV2 } from '@/components/Input';
import { Button } from '@/components/Button/ButtonV2';
import { useSessionStorage } from '@/hooks';
import usePageTracking from '@/hooks/usePageTracking';
import { isValidEmailDomain } from '@/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useEffect } from 'react';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Google from '@/static/icons/google';
import { SupportedCountriesE, VendorI } from '@/types/cyclone/models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Mail inválido')
    .test('is-valid-suffix', 'El correo electrónico tiene un sufijo inválido', function (value) {
      return isValidEmailDomain(value);
    })
    .required('Campo requerido'),
  first_name: yup.string().required('Campo requerido'),
  last_name: yup.string().required('Campo requerido'),
  password: yup.string().min(8, 'Al menos 8 caracteres').required('Campo requerido')
});

const API_URL = import.meta.env.VITE_CYCLONE_API_URL || 'INSERT_CYCLONE_API_URL';

export const SignUp = () => {
  usePageTracking();
  const { setValue } = useSessionStorage('invitedBy', {});
  const [vendor, setVendor] = useState<VendorI | null>(null);
  const [loading, setLoading] = useState(false);

  const usernameParam = new URLSearchParams(location.search).get('username') as string;

  useEffect(() => {
    if (usernameParam) {
      const getVendorByUsername = async () => {
        try {
          const response = await fetch(`${API_URL}/vendors/${usernameParam}`);
          if (response.ok) {
            setVendor(await response.json());
            setValue(usernameParam);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getVendorByUsername();
    }
  }, [usernameParam, setValue]);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control
  } = useForm({ resolver: yupResolver(schema), mode: 'all' });

  const errorHandler = (err: auth0.Auth0Error | null) => {
    setLoading(false);
    if (err) {
      toast.error('El email o la contraseña que ingresaste son incorrectos, por favor intentalo nuevamente.');
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    trackGenericEvent('Button Sign Up Clicked');
    register(data.first_name, data.last_name, data.email, data.password, errorHandler);
    setLoading(true);
  };

  const isReferral = vendor !== null;

  const getReferralDiscount = () => {
    if (vendor) {
      const country = vendor.country;
      if (country === SupportedCountriesE.CHILE) {
        return 2000;
      } else if (country === SupportedCountriesE.URUGUAY) {
        return 100;
      } else if (country === SupportedCountriesE.ARGENTINA) {
        return 1000;
      }
    }
    return 0;
  };

  return (
    <>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col items-center gap-2 text-center">
          <h1 className="text-2xl font-bold">Crea tu negocio en Ágora</h1>
          {usernameParam ? (
            isReferral ? (
              <div className="bg-gray-100 border rounded text-sm leading-tight w-full p-2 flex justify-center items-baseline">
                <FontAwesomeIcon icon={faInfoCircle} className="mt-0.5" />
                <span className="text-center">
                  Beneficio para referidos de <b>@{vendor?.username}</b>: descuento de $
                  {getReferralDiscount()} en tu Plan Mensual.
                </span>
              </div>
            ) : (
              <div className="w-full h-[5ch] rounded bg-gray-100 border animate-pulse" />
            )
          ) : (
            <p className="text-pretty text-sm text-muted-foreground">
              Digitaliza tu negocio, optimiza tu tiempo y escala tus ingresos.
            </p>
          )}
        </div>

        {/* Botón de Google FUERA del <form> */}
        <Button
          variant="outline"
          type="button"
          className="flex items-center relative gap-2 w-full !border-blue"
          onClick={() => authorize('google-oauth2')}
        >
          <div className="absolute top-1/2 left-2 -translate-y-1/2 p-2 rounded-full">
            <Google className="w-4 h-4" />
          </div>
          <span>Continuar con Google</span>
        </Button>

        <div className="divider text-[#626262]">O completa tus datos</div>

        {/* Formulario */}
        <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-4">
            <Controller
              name="first_name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ...field } }) => (
                <InputV2
                  id="first_name"
                  type="text"
                  value={value || ''}
                  placeholder="Nombre"
                  onChange={onChange}
                  error={errors?.first_name?.message as string}
                  {...field}
                />
              )}
            />
            <Controller
              name="last_name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ...field } }) => (
                <InputV2
                  id="last_name"
                  type="text"
                  value={value || ''}
                  placeholder="Apellido"
                  onChange={onChange}
                  error={errors?.last_name?.message as string}
                  {...field}
                />
              )}
            />
          </div>
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, ...field } }) => (
              <InputV2
                id="email"
                type="email"
                value={value || ''}
                placeholder="Email"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const formattedValue = e.target.value.trim().toLowerCase();
                  onChange(formattedValue);
                }}
                error={errors?.email?.message as string}
                {...field}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, ...field } }) => (
              <InputV2
                id="password"
                type="password"
                value={value || ''}
                placeholder="Contraseña"
                onChange={onChange}
                error={errors?.password?.message as string}
                {...field}
              />
            )}
          />
          <p className="text-sm leading-tight text-[#868686]">
            Te enviaremos notificaciones por e-mail, que podrás desactivar cuando quieras.
          </p>

          {/* Botón de submit solo reacciona al enter dentro del form */}
          <Button
            variant="default"
            className="w-full"
            type="submit"
            disabled={!isValid || loading}
            isLoading={loading}
          >
            {loading ? 'Cargando...' : 'Registrarme'}
          </Button>
        </form>
      </div>
    </>
  );
};
