import { useTokenMonitor } from '@/hooks';

export const TokenMonitor = () => {
  const { tokenInfo, refreshCheck } = useTokenMonitor();

  if (!tokenInfo) return <div>Loading token information...</div>;

  return (
    <div className="p-4 border rounded-lg shadow">
      <h2 className="text-xl font-bold mb-4">Token Monitor</h2>

      <div className="mb-4">
        <h3 className="font-bold">Access Token</h3>
        <p>Expires: {tokenInfo.accessToken.expiresAt}</p>
        <p>Hours Remaining: {tokenInfo.accessToken.hoursRemaining}</p>
        <p>Token: {tokenInfo.accessToken.token}</p>
      </div>

      <div className="mb-4">
        <h3 className="font-bold">ID Token</h3>
        <p>Expires: {tokenInfo.idToken.expiresAt}</p>
        <p>Hours Remaining: {tokenInfo.idToken.hoursRemaining}</p>
        <p>Token: {tokenInfo.idToken.token}</p>
      </div>

      <div className="mt-4">
        <p className="text-sm text-gray-600">Last checked: {tokenInfo.lastCheck}</p>
        <button
          onClick={refreshCheck}
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Check Now
        </button>
      </div>
    </div>
  );
};
