import classNames from 'classnames';
import React from 'react';
import { ImageManagerV2, TypeImageInput } from '../ImageManagerV2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { addImage } from '@/static/images';

type InputImageProps = {
  img?: string;
  handleImg: (img: string) => void;
  type: TypeImageInput;
  width?: string;
  height?: string;
};

export const InputImage: React.FC<InputImageProps> = ({ img, type, width, height, handleImg }) => {
  const emptyImage = () => {
    switch (type) {
      case TypeImageInput.AVATAR:
        return (
          <FontAwesomeIcon
            className="cursor-pointer"
            icon={faCircleUser}
            color="#DADCE0"
            style={{ width, height }}
          />
        );
      case TypeImageInput.BANNER:
        return <div className={classNames('bg-[#DADCE0]')} style={{ width, height }} />;
      default:
        break;
    }
  };

  return (
    <ImageManagerV2 type={type} img={img} onImageChange={handleImg}>
      {img ? (
        <div
          className={classNames(
            'bg-cover border-2 flex items-center group justify-center relative border-dashed border-gray-300 hover:border-blue transition-all duration-200 shadow-md hover:shadow-lg bg-no-repeat bg-center bg-[#FFF] cursor-pointer',
            {
              'rounded-[50%]': type === TypeImageInput.AVATAR,
              'rounded-[6px]': type === TypeImageInput.BANNER,
              'rounded-[10px] ': type === TypeImageInput.SERVICE
            }
          )}
          style={{ backgroundImage: `url(${img})`, width, height }}
        >
          <div
            className={classNames(
              'opacity-60 w-full h-full bg-white/60 group-hover:opacity-100 flex flex-col items-center justify-center space-y-2 transition-all duration-200',
              {
                'rounded-[50%]': type === TypeImageInput.AVATAR,
                'rounded-[6px]': type === TypeImageInput.BANNER,
                'rounded-[10px] ': type === TypeImageInput.SERVICE
              }
            )}
          >
            <img src={addImage} className="w-8 h-8" />
            <p className="text-sm font-medium text-blue">Cambiar imagen</p>
          </div>
        </div>
      ) : (
        emptyImage()
      )}
    </ImageManagerV2>
  );
};
